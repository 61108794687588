import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from 'react'
import networks from '../../networks.json'
import {
  MAX_AUTH_TOKEN_LIFETIME,
  EIP712_NAME,
  EIP712_TYPE,
  EIP712_VERSION,
  EIP712_CHAIN_ID,
  SUPPORTED_CHAINS,
} from '../../constants'
import { Button } from '../Button'
import { getUser, login } from '../../api'
import { shortenAddress } from '../../utils/shortenAddress'
import { UserContext } from '../../contexts/UserContext'

import {
  StatusCircle,
  ConnectButton,
  ConnectAddress,
  ConnectContainer,
  ConnectBalance,
  ConnectBalanceSymbol,
  ConnectButtonName,
  ButtonWrapper,
  MetamaskAuthContainer,
} from './MetamaskAuth.styled'
import NetworkIndicator from '../NetworkIndicator'
import NetworkMenu from '../NetworkMenu'

export default function MetaMaskAuth({
  handleOpenAccount,
  handleOpenInstallMetamask,
}) {
  const [clickConnect, setClickConnect] = useState(false)
  const {
    userAddress,
    username,
    userBalance,
    accessToken,
    chainId,
    connectWallet,
    connectToBlockchain,
    email,
    addAccessToken,
    getUserInfo,
  } = useContext(UserContext)

  const [showChains, setShowChains] = useState(false)
  const chainList = useRef(null)

  function handleChains() {
    setShowChains(!showChains)
  }

  function handleChainClick(connectchainId) {
    setClickConnect(true)
    connectToBlockchain(connectchainId)
    handleChains()
  }

  function handleCheckUser() {
    getUser(userAddress)
      .then((user) => {
        if (user.email) {
          getToken()
        } else {
          handleOpenAccount()
        }
      })
      .catch(() => {
        handleOpenAccount()
      })
  }
  async function getToken() {
    const timestamp = Math.floor(Date.now() / 1000 + MAX_AUTH_TOKEN_LIFETIME)
    const typedData = {
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        AuthToken: [{ name: 'timestamp', type: 'uint256' }],
      },
      primaryType: EIP712_TYPE,
      domain: {
        name: EIP712_NAME,
        version: EIP712_VERSION,
        chainId: chainId,
        verifyingContract:
          networks[EIP712_CHAIN_ID.toString()].metarunExchangeContract.address,
      },
      message: {
        timestamp: timestamp,
      },
    }

    const metamaskSignature = await window.ethereum.request({
      method: 'eth_signTypedData_v4',
      params: [userAddress, JSON.stringify(typedData)],
    })

    login(metamaskSignature, timestamp, userAddress, chainId)
      .then((res) => {
        addAccessToken(res)
        getUserInfo()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function connect() {
    setClickConnect(true)
    connectWallet()
  }

  useEffect(() => {
    if (clickConnect && userAddress && SUPPORTED_CHAINS.includes(chainId)) {
      handleCheckUser()
    }
  }, [userAddress, chainId])

  const handleClickOutside = useCallback((event) => {
    if (
      chainList.current &&
      !Array.from(chainList.current.getElementsByTagName('*')).includes(
        event.target,
      )
    ) {
      setShowChains(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <MetamaskAuthContainer ref={chainList}>
      <NetworkIndicator network={chainId} onClick={handleChains} />

      {chainId &&
      accessToken &&
      email &&
      SUPPORTED_CHAINS.includes(chainId) &&
      username ? (
        <>
          <ConnectContainer balance>
            <ConnectBalance>
              {Number(userBalance).toFixed(2)}{' '}
              <ConnectBalanceSymbol>
                {networks[chainId].params.nativeCurrency.symbol}
              </ConnectBalanceSymbol>
            </ConnectBalance>
            <ConnectAddress>
              <ConnectButtonName>{username}</ConnectButtonName>
              <StatusCircle color="green" />
            </ConnectAddress>
          </ConnectContainer>
        </>
      ) : chainId &&
        accessToken &&
        email &&
        SUPPORTED_CHAINS.includes(chainId) ? (
        <ConnectContainer balance>
          <ConnectBalance>
            {Number(userBalance).toFixed(2)}{' '}
            <ConnectBalanceSymbol>
              {networks[chainId].params.nativeCurrency.symbol}
            </ConnectBalanceSymbol>
          </ConnectBalance>
          <ConnectAddress>
            {shortenAddress(userAddress)}
            <StatusCircle color="green" />
          </ConnectAddress>
        </ConnectContainer>
      ) : chainId && SUPPORTED_CHAINS.includes(chainId) ? (
        <ButtonWrapper>
          <Button size="rounded" variant="contained" onClick={handleCheckUser}>
            Connect Wallet
          </Button>
        </ButtonWrapper>
      ) : chainId && !SUPPORTED_CHAINS.includes(chainId) ? (
        <ConnectContainer margin="0px">
          <ConnectButton onClick={handleChains}>
            Wrong network
            <StatusCircle color="red" />
          </ConnectButton>
        </ConnectContainer>
      ) : window.ethereum ? (
        <ButtonWrapper>
          <Button size="rounded" variant="contained" onClick={connect}>
            Connect Wallet
          </Button>
        </ButtonWrapper>
      ) : (
        <Button
          size="rounded"
          variant="contained"
          onClick={handleOpenInstallMetamask}
        >
          Connect Wallet
        </Button>
      )}
      <NetworkMenu
        showChains={showChains}
        handleChainClick={handleChainClick}
      />
    </MetamaskAuthContainer>
  )
}
