import React, { useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'
import {
  SummaryContainer,
  SummaryItem,
  SummaryItemTitle,
  SummaryItemText,
  SummaryItemRuns,
  SummaryItemRun,
  SpinnerSummary,
} from './Summary.styled'
import Spinner from '../Spinner'

export default function Summary({ summary, summaryIsLoading }) {
  const { opalBalance } = useContext(UserContext)
  return summaryIsLoading ? (
    <SpinnerSummary>
      <Spinner />
    </SpinnerSummary>
  ) : summary ? (
    <SummaryContainer>
      <SummaryItem>
        <SummaryItemTitle>Total Runs </SummaryItemTitle>
        <SummaryItemText>
          {summary.totalRemainingRuns || 0}
          <SummaryItemRuns>
            <SummaryItemRun>/{summary.runsTotalLimit || 0}</SummaryItemRun>
          </SummaryItemRuns>
        </SummaryItemText>
      </SummaryItem>
      <SummaryItem className="item-2">
        <SummaryItemTitle>Today Runs </SummaryItemTitle>
        <SummaryItemText>
          {summary.todayRemainingRuns || 0}
          <SummaryItemRuns>
            <SummaryItemRun>/{summary.runsPerDayLimit || 0}</SummaryItemRun>
          </SummaryItemRuns>
        </SummaryItemText>
      </SummaryItem>
      <SummaryItem>
        <SummaryItemTitle>Total Reward </SummaryItemTitle>
        <SummaryItemText>{summary.totalReward || 0} opal</SummaryItemText>
      </SummaryItem>
      <SummaryItem className="item-4">
        <SummaryItemTitle>Today Reward </SummaryItemTitle>
        <SummaryItemText>{summary.todayReward || 0} opal</SummaryItemText>
      </SummaryItem>
      <SummaryItem className="item-5">
        <SummaryItemTitle>Current Balance</SummaryItemTitle>
        <SummaryItemText>{opalBalance} opal</SummaryItemText>
      </SummaryItem>
    </SummaryContainer>
  ) : (
    <></>
  )
}
