import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledCard = styled.div`
  display: ${({ show }) => {
    if (show) {
      return 'flex'
    }
    return 'none'
  }};
  height: 100%;
  flex-direction: column;
  max-width: 324px;
  min-width: 162px;
  background: #120f7a;
  background: linear-gradient(
    184.67deg,
    rgba(72, 157, 254, 0.63) 1.89%,
    rgba(34, 7, 90, 0.42) 96.23%
  );
  border: none;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  padding: 12px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 10px 0 6px;
    border-radius: 12px;
  }
`
export const CardDetails = styled.div`
  position: absolute;
  border-radius: 20px;
  bottom: 0;
  z-index: 2;
  width: 100%;

  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  height: 0;

  backdrop-filter: blur(20px);
  filter: drop-shadow(-13px 14px 0px rgba(0, 0, 0, 0.15));
  background: linear-gradient(
    180deg,
    rgba(33, 34, 80, 0.3) 0%,
    rgba(70, 34, 130, 0.3) 100%
  );
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;

  ${StyledCard}:hover & {
    height: 100%;
  }

  table {
    width: 100%;
    text-align: left;
    margin-top: 120px;
  }
  table tr td {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129%;
    letter-spacing: -0.04em;
    color: white;
    padding: 10px 20px;
  }
  table tr td:first-child {
    opacity: 0.5;
  }
  table tr td:last-child {
    text-align: right;
  }

  @media (max-width: 1052px) {
    table {
      margin-top: 80px;
    }
  }

  @media (max-width: 768px) {
    table {
      margin-top: 60px;
    }
    table tr td {
      font-size: 12px;
      padding: 5px 13px;
      line-height: 105%;
    }
  }
`

export const CardImage = styled.img`
  min-height: 350px;
  max-height: 363px;
  min-width: 321px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 1052px) {
    min-height: 250px;
    min-width: 230px;
    max-height: 250px;
    max-width: 230px;
  }
  @media (max-width: 768px) {
    min-height: 165px;
    min-width: 120px;
    max-height: 182px;
    width: 100%;
    max-width: 167px;
    position: relative;
    top: 5px;
  }
`
export const CardImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

export const CardContent = styled.div`
  margin-top: 3px;
  @media (max-width: 768px) {
    margin-top: 1px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  padding: 0 16px;
  margin: 0 0 12px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin: 0 0 6px;
    padding: 0 8px;
  }
`

export const InfoHeadContainer = styled.div`
  min-height: 52px;
  margin-bottom: 8px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1052px) {
    padding: 0 12px;
    min-height: 30px;
    margin-bottom: 13px;
  }
  @media (max-width: 768px) {
    padding: 0 8px;
    min-height: 0;
    margin-bottom: 4px;
  }
`

export const PriceContainer = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #7a97df;

  @media (max-width: 768px) {
    font-size: 10px;
    max-width: 65px;
    line-height: 15px;
    letter-spacing: 0px;
  }
`
export const PriceWrapper = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-style: italic;
  line-height: 38px;
  letter-spacing: 0.5px;
  color: #ffffff;
  @media (max-width: 1052px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 22px;
  }
`

export const ButtonContainer = styled.div`
  z-index: 3;
  position: relative;
  padding: 20px 0 10px;
  display: flex;
  margin: 0 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #a4bad7;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 10px;
    padding-top: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.5px;
    margin: 0 8px;
  }
`
export const Border = styled.div`
  height: 1px;
  margin: 0 16px;
  background: rgba(73, 158, 255, 0.35);
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );
  @media (max-width: 768px) {
    margin: 0 8px;
  }
`

export const CharacterKinds = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    gap: 2px;
  }
`
export const CharacterKindContainer = styled.li`
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  border-radius: 6px;
  transform: skew(-13deg, 0);
  padding: 1px;
`

export const CharacterKind = styled.div`
  padding: 3px;
  border-radius: 6px;
  min-width: 104px;
  box-sizing: border-box;
  background: #182f52;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1052px) {
    min-width: 70px;
    padding: 2px 3px;
  }
  @media (max-width: 768px) {
    min-width: 55px;
    padding: 0 3px;
  }
`
export const CharacterKindText = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 1052px) {
    font-size: 12px;
    line-height: 20px;
  }
  @media (max-width: 768px) {
    font-size: 9px;
    line-height: 18px;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
`

export const ButtonLinkWrapper = styled.div`
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
`
export const CardSmallImage = styled.img`
  position: absolute;
  right: 15px;
  top: 0;
  width: 40px;
  height: 40px;
  @media (max-width: 768px) {
    right: 8px;
    top: 0;
    width: 25px;
    height: 25px;
    z-index: 2;
  }
`
