import { createGlobalStyle } from 'styled-components'

import OpenSansBoldWoff from './OpenSans-Bold/OpenSans-Bold.woff'
import OpenSansBoldWoff2 from './OpenSans-Bold/OpenSans-Bold.woff2'
import OpenSansBoldTtf from './OpenSans-Bold/OpenSans-Bold.ttf'

import OpenSansMediumWoff from './OpenSans-Medium/OpenSans-Medium.woff'
import OpenSansMediumWoff2 from './OpenSans-Medium/OpenSans-Medium.woff2'
import OpenSansMediumTtf from './OpenSans-Medium/OpenSans-Medium.ttf'

import OpenSansWoff from './OpenSans/OpenSans.woff'
import OpenSansWoff2 from './OpenSans/OpenSans.woff2'
import OpenSansTtf from './OpenSans/OpenSans.ttf'

import OpenSansItalicBoldWoff from './OpenSans-Italic-Bold/OpenSans-Italic-Bold.woff'
import OpenSansItalicBoldWoff2 from './OpenSans-Italic-Bold/OpenSans-Italic-Bold.woff2'
import OpenSansItalicBoldTtf from './OpenSans-Italic-Bold/OpenSans-Italic-Bold.ttf'

import OpenSansItalicMediumWoff from './OpenSans-Italic-Medium/OpenSans-Italic-Medium.woff'
import OpenSansItalicMediumWoff2 from './OpenSans-Italic-Medium/OpenSans-Italic-Medium.woff2'
import OpenSansItalicMediumTtf from './OpenSans-Italic-Medium/OpenSans-Italic-Medium.ttf'

import OpenSansItalicWoff from './OpenSans-Italic/OpenSans-Italic.woff'
import OpenSansItalicWoff2 from './OpenSans-Italic/OpenSans-Italic.woff2'
import OpenSansItalicTtf from './OpenSans-Italic/OpenSans-Italic.ttf'

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'OpenSans';
        src: local('OpenSans Bold'), local('OpenSans-Bold'),
        url(${OpenSansBoldWoff2}) format('woff2'),
        url(${OpenSansBoldWoff}) format('woff'),
        url(${OpenSansBoldTtf}) format('truetype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
        font-family: 'OpenSans';
        src: local('OpenSans'), local('OpenSans-Regular'),
        url(${OpenSansWoff2}) format('woff2'),
        url(${OpenSansWoff}) format('woff'),
        url(${OpenSansTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'OpenSans';
        src: local('OpenSans Medium'), local('OpenSans-Medium'),
        url(${OpenSansMediumWoff2}) format('woff2'),
        url(${OpenSansMediumWoff}) format('woff'),
        url(${OpenSansMediumTtf}) format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
      font-family: 'OpenSans';
      src: local('OpenSans-Italic Bold'), local('OpenSans-Italic-Bold'),
      url(${OpenSansItalicBoldWoff2}) format('woff2'),
      url(${OpenSansItalicBoldWoff}) format('woff'),
      url(${OpenSansItalicBoldTtf}) format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
      font-family: 'OpenSans';
      src: local('OpenSans-Italic'), local('OpenSans-Italic-Regular'),
      url(${OpenSansItalicWoff2}) format('woff2'),
      url(${OpenSansItalicWoff}) format('woff'),
      url(${OpenSansItalicTtf}) format('truetype');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
  }
  @font-face {
      font-family: 'OpenSans';
      src: local('OpenSans-Italic Medium'), local('OpenSans-Italic-Medium'),
      url(${OpenSansItalicMediumWoff2}) format('woff2'),
      url(${OpenSansItalicMediumWoff}) format('woff'),
      url(${OpenSansItalicMediumTtf}) format('truetype');
      font-weight: 500;
      font-style: italic;
      font-display: swap;
  }
`

export { GlobalFonts }
