import React from 'react'
import ReactDOM from 'react-dom'
import { GlobalStyles } from './styles'
import { GlobalFonts } from './fonts'
import { BrowserRouter as Router } from 'react-router-dom'
import UserProvider from './contexts/UserContext'
import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <GlobalFonts />
    <GlobalStyles />
    <Router>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
