import { useState, useEffect, useReducer, useContext } from 'react'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
import styled from 'styled-components'
import { Routes, Route, useLocation } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import {
  Home,
  CardDetailPage,
  NotFound,
  Profile,
  Unsubscribe,
  Buy,
} from './pages'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import networks from './networks.json'
import { SUPPORTED_CHAINS, ZENDESK_KEY } from './constants'
import { UserContext } from './contexts/UserContext'

const AppContainer = styled.div`
  min-height: 100vh;
  background: #0f083a;
  background: radial-gradient(
    46.6% 93.31% at 50% 53.4%,
    #000000 0%,
    #170f47 100%
  );
`

const AppWrapper = styled.div`
  min-height: 100vh;
  background-image: url(${require(`./images/MainBackground.png`)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`

const HelpButton = styled.button`
  cursor: pointer;
  position: fixed;
  z-index: 10;
  border: none;
  background: #3970fe;
  background: linear-gradient(
    88.82deg,
    #489dfe 11.9%,
    #3970fe 45.06%,
    #5e51ff 76.01%
  );
  bottom: 20px;
  right: 20px;
  height: 52px;
  border-radius: 20px;
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
`

const HelpButtonImg = styled.img`
  position: absolute;
  top: 19px;
  right: 23px;
  height: 24px;
  width: 13.5px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
const HelpButtonText = styled.p`
  margin: 0;
  font-family: 'OpenSans';
  padding: 5px 18px;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.4px;
  @media (max-width: 768px) {
    display: none;
  }
`

const setting = {
  color: {
    theme: '#5F59FF',
    launcher: '#5F59FF',
    launcherText: '#fff',
    button: '#5F59FF',
    header: '#5F59FF',
  },
}

function App() {
  const location = useLocation()

  const {
    userAddress,
    chainId,
    username,
    email,
    playfabId,
    resetUserInfo,
    getUserBalance,
    getMrunBalance,
    resetBalances,
    getGameBalances,
    resetGameBalances,
    getChain,
    getUserAddress,
    resetAccessToken,
  } = useContext(UserContext)

  const [searchValue, setSearchValue] = useState('')
  const [showMyTokens, setShowMyTokens] = useState(false)
  const [kindFilters, setKindFilters] = useState({
    Ignis: false,
    Oro: false,
    Penna: false,
    Ticket: false,
    MysteryBox: false,
    Classic: false,
    Epic: false,
    Legendary: false,
    Common: false,
    Rare: false,
    Mythical: false,
  })

  const [forcedBuy, forceUpdateBuy] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    if (SUPPORTED_CHAINS.includes(chainId) && userAddress) {
      getUserBalance()
    }
  }, [userAddress, chainId, location])

  useEffect(() => {
    if (
      SUPPORTED_CHAINS.includes(chainId) &&
      networks[chainId].metarunTokenContract &&
      userAddress
    ) {
      getMrunBalance()
    }
  }, [userAddress, chainId, location, forcedBuy])

  useEffect(() => {
    resetGameBalances()
    if (playfabId) {
      getGameBalances(playfabId)
    }
  }, [playfabId, forcedBuy])

  function onAddressChanged() {
    deleteSign()
    getUserAddress()
  }

  function onChainChanged() {
    deleteSign()
    getChain()
  }

  function deleteSign() {
    window.localStorage.removeItem('CloseHomePrompt')
    resetBalances()
    resetAccessToken()
    resetUserInfo()
  }

  function openWidget() {
    ZendeskAPI('webWidget', 'show')
    ZendeskAPI('webWidget', 'open')
  }

  function onZendeskLoaded() {
    ZendeskAPI('webWidget', 'setLocale', 'en-US')
    ZendeskAPI('webWidget', 'hide')
    ZendeskAPI('webWidget:on', 'close', function() {
      ZendeskAPI('webWidget', 'hide')
    })
    ZendeskAPI('webWidget', 'prefill', {
      name: {
        value: username,
      },
      email: {
        value: email,
      },
    })
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', onAddressChanged)
      window.ethereum.on('chainChanged', onChainChanged)
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAddressChanged)
        window.ethereum.removeListener('chainChanged', onChainChanged)
      }
    }
  }, [])

  useEffect(() => {
    onZendeskLoaded()
  }, [email, username])

  return (
    <>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={onZendeskLoaded}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppContainer>
          <AppWrapper>
            <HelpButton onClick={openWidget}>
              <HelpButtonImg
                src={require('./images/Question.svg').default}
                alt="question"
                width="13.5"
                height="24"
              />
              <HelpButtonText>Support</HelpButtonText>
            </HelpButton>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Home
                    forceUpdateBuy={forceUpdateBuy}
                    searchValue={searchValue}
                    kindFilters={kindFilters}
                    setKindFilters={setKindFilters}
                    setSearchValue={setSearchValue}
                    showMyTokens={showMyTokens}
                    setShowMyTokens={setShowMyTokens}
                  />
                }
              />
              <Route path="/detail/:tokenId/*" element={<CardDetailPage />} />
              <Route path="/buy/:tokenName/*" element={<Buy />} />
              <Route
                path="/profile/*"
                element={
                  <ProtectedRoute email={email}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route path="/unsubscribe/*" element={<Unsubscribe />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AppWrapper>
        </AppContainer>
      </LocalizationProvider>
    </>
  )
}

export default App
