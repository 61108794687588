import React from 'react'
import useUserInfo from '../hooks/useUserInfo'
import useUserBalances from '../hooks/useUserBalances'
import useConnection from '../hooks/useConnection'

export const UserContext = React.createContext()

const UserProvider = ({ children }) => {
  const {
    chainId,
    getChain,
    userAddress,
    getUserAddress,
    connectWallet,
    connectToBlockchain,
    accessToken,
    addAccessToken,
    resetAccessToken,
  } = useConnection()

  const {
    username,
    email,
    playfabId,
    referralCode,
    getUserInfo,
    resetUserInfo,
  } = useUserInfo({
    userAddress,
    chainId,
  })

  const {
    userBalance,
    getUserBalance,
    mrunBalance,
    getMrunBalance,
    resetBalances,
    opalBalance,
    mtrunBalance,
    getGameBalances,
    resetGameBalances,
  } = useUserBalances({ userAddress, chainId })

  return (
    <UserContext.Provider
      value={{
        userAddress,
        username,
        email,
        playfabId,
        userBalance,
        mrunBalance,
        opalBalance,
        mtrunBalance,
        accessToken,
        referralCode,
        chainId,
        getUserInfo,
        resetUserInfo,
        getUserBalance,
        getMrunBalance,
        resetBalances,
        getGameBalances,
        resetGameBalances,
        getChain,
        getUserAddress,
        connectWallet,
        connectToBlockchain,
        addAccessToken,
        resetAccessToken,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
