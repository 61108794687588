import styled from 'styled-components'
import { Link as RecatLink } from 'react-router-dom'

export const PageWrapper = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 0 20px 40px;

  @media (max-width: 768px) {
    padding: 0 12px 32px;
  }
`

export const PageHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`

export const BackButton = styled(RecatLink)`
  display: inline-flex;
  align-items: center;
  height: 56px;
  background: rgba(255, 255, 255, 0.05);
  padding: 7px 26px;
  gap: 12px;
  color: #fff;
  text-decoration: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  box-sizing: border-box;

  @media (max-width: 768px) {
    border-radius: 12px;
    height: 42px;

    line-height: 24px;
    letter-spacing: 0.5px;

    padding: 7px 24px;
  }
`

export const PageBody = styled.div`
  display: grid;
  grid-template-columns: 39% 61%;

  @media (max-width: 1100px) {
    gap: 12px;
    grid-template-columns: 100%;
  }
`

export const TokenPictureWrapper = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 15px;
`

export const CharacterKinds = styled.ul`
  position: absolute;
  left: 5px;
  top: -15px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    top: -20px;
  }
`
export const CharacterKindContainer = styled.li`
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  border-radius: 6px;
  transform: skew(-13deg, 0);
  padding: 1px;
`

export const CharacterKind = styled.div`
  padding: 3px;
  border-radius: 6px;
  min-width: 112px;
  box-sizing: border-box;
  background: #0f0e2b;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
  }
`
export const CharacterKindText = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

export const StyledRouterLink = styled(RecatLink)`
  cursor: pointer;
`
export const TokenPicture = styled.img`
  max-width: 469px;
  width: 100%;
  height: auto;
  margin-top: 24px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`
export const BuyTable = styled.div`
  box-sizing: border-box;
`

export const BuyTableContainer = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;
`

export const BuyTableHead = styled.div`
  display: grid;
  grid-template-columns: 8% 23% 23% 23% 23%;
  grid-template-rows: repeat(1, 1fr);
  padding: 0 15px 0 36px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;

  color: #7a97df;

  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const BuyTableRow = styled.div`
  display: grid;
  min-width: 100px;
  align-items: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;

  grid-template-columns: 8% 23% 23% 23% 23%;
  grid-template-rows: repeat(1, 1fr);
  padding: 15px;
  padding-left: 36px;

  border-radius: 20px;

  border-bottom-left-radius: ${({ show }) => {
    if (show) {
      return '0'
    }
    return '20px'
  }};
  border-bottom-right-radius: ${({ show }) => {
    if (show) {
      return '0'
    }
    return '20px'
  }};
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    font-size: 14px;
    font-weight: 700;
    text-align: center;

    padding: 18px 16px;

    border-bottom-left-radius: ${({ show }) => {
      if (show) {
        return '0'
      }
      return '12px'
    }};
    border-bottom-right-radius: ${({ show }) => {
      if (show) {
        return '0'
      }
      return '12px'
    }};
  }
`

export const BuyTableItem = styled.div`
  margin: 0;
  position: relative;
  color: ${({ color }) => {
    if (color === 'head') {
      return '#7a97df'
    }
    return 'white'
  }};
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: ${({ button }) => {
      if (button) {
        return 'center'
      }
      return 'justify-content'
    }};
    &:first-child {
      text-align: center;
    }
    margin-bottom: 8px;
  }
`

export const BuyTableItemDescription = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: block;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #7a97df;
  }
`

export const BuyTableItemLeft = styled.p`
  margin: 0;

  text-align: center;
  position: relative;
  @media (max-width: 768px) {
    text-align: center;
    left: 0;
  }
`

export const AccordionButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  position: absolute;
  top: 12px;
  right: 2px;
  padding: 0;
  @media (max-width: 768px) {
    display: none;
  }
`

export const BuyTableRowWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(38px);

  border-radius: 20px;
  @media (max-width: 768px) {
    border-radius: 12px;
    margin-bottom: 4px;
  }
`

export const BuyTableRowMore = styled.div`
  display: ${({ show }) => {
    if (show) {
      return 'block'
    }
    return 'none'
  }};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

export const Border = styled.div`
  height: 1px;
  width: 95%;
  margin: 0 auto;
  background: rgba(73, 158, 255, 0.35);
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );

  @media (max-width: 768px) {
    display: none;
  }
`
export const MobileBorder = styled.div`
  display: none;
  height: 1px;
  width: 100%;
  margin: 8px auto 24px;
  background: rgba(73, 158, 255, 0.35);
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );

  @media (max-width: 768px) {
    display: block;
  }
`

export const MoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 25px 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 16px;
    margin-top: -8px;
  }
`

export const MoreTitle = styled.h3`
  color: #7a97df;
  width: 180px;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
`

export const MoreList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    margin-top: -8px;
  }
`

export const MoreItem = styled.li`
  display: flex;
  width: 200px;
  justify-content: space-between;
  margin: 8px 0 12px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 6px 0;
  }
`

export const MoreItemDescription = styled.p`
  color: #7a97df;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0;
`

export const MoreItemValue = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0;
`

export const StyledLink = styled(RecatLink)`
  text-decoration: none;
  width: 100%;

  display: flex;
  justify-content: center;

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
`

export const ButtonLinkWrapper = styled.div`
  width: 100%;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
`

export const MoreDetails = styled.div`
  display: none;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.5px;
  position: relative;
  color: #ffffff8f;
  padding: 2px 0 4px;
  @media (max-width: 768px) {
    display: ${({ show }) => {
      if (!show) {
        return 'block'
      }
      return 'none'
    }};
  }
`

export const LessDetails = styled.div`
  display: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.5px;
  position: relative;
  color: #ffffff8f;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    display: ${({ show }) => {
      if (show) {
        return 'block'
      }
      return 'none'
    }};
  }
`

export const SaleText = styled.span`
  color: #d24724;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  text-decoration: line-through;
`

export const SaleChevron = styled.span`
  position: absolute;
  text-transform: uppercase;
  left: -30px;
  top: 5px;
  color: white;
  background-color: #d24724;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 21px;
  padding: 0 30px;
  letter-spacing: 0.5px;
  text-align: left;
  transform: rotate(-45deg);
  @media (max-width: 768px) {
    line-height: 17px;
  }
`

export const Icon = styled.img`
  width: 40px;
  height: 40px;

  @media (max-width: 1053px) {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    z-index: 2;
  }
`
