const host = process.env.REACT_APP_API_HOST

export async function getDuelRuns({ userAddress, page, today }) {
  const pageParam = page ? `page=${page}&` : ''
  return await fetch(
    `${host}/runs/?${pageParam}participant=${userAddress}&game_mode=duel&today_runs=${today}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getCompetitiveRuns({ userAddress, page, today }) {
  const pageParam = page ? `page=${page}&` : ''
  return await fetch(
    `${host}/runs/?${pageParam}participant=${userAddress}&game_mode=competitive&today_runs=${today}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
