import { useState, useContext } from 'react'
import { DeleteButton, TransactionLink } from '../CardDetail.styled'
import { getMetarunExchange } from '../../../api/contracts'
import { getOrders } from '../../../api'
import Spinner from '../../../components/Spinner'
import networks from '../../../networks.json'
import { shortenAddress } from '../../../utils/shortenAddress'
import { UserContext } from '../../../contexts/UserContext'

export function DeleteOrderButton({
  orderInformation,
  forceUpdate,
  ordersBefore,
  tokenId,
  tokenChain,
  setSwitchtNetworkModalOpen,
  setSwitchtNetworkModalText,
}) {
  const { accessToken, chainId, email } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState()

  async function handleDelete(e) {
    if (tokenChain !== Number(chainId)) {
      setSwitchtNetworkModalText(
        `Please switch to ${networks[tokenChain]?.name} and login to delete an order`,
      )
      setSwitchtNetworkModalOpen(true)
      return
    }
    e.preventDefault()
    setIsLoading(true)
    const regenerateObj = {
      amount: orderInformation.amount,
      creationTime: orderInformation.creationTime,
      expirationTime: orderInformation.expirationTime,
      id: orderInformation.id,
      price: orderInformation.price,
      salt: orderInformation.salt,
      seller: orderInformation.ownership.holder,
      tokenId: orderInformation.ownership.kind.contractTokenId,
    }

    const MetarunExchange = getMetarunExchange(chainId)
    MetarunExchange.cancel(regenerateObj, orderInformation.signature)
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then((res) => {
            setTimeout(function testTokens() {
              getOrders(tokenId, [0])
                .then((res) => {
                  if (res.count < ordersBefore) {
                    forceUpdate()
                    setIsLoading(false)
                    setTxHash()
                  } else {
                    setTimeout(testTokens, 2000)
                  }
                })
                .catch((e) => {
                  console.log(e)
                  setIsLoading(false)
                })
            }, 1000)
          })
          .catch((e) => {
            console.log(e)
            setIsLoading(false)
          })
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }
  return (
    <>
      {isLoading ? (
        <DeleteButton disabled>
          <Spinner size={'18px'} color="#5f59ff" />
          {txHash && (
            <TransactionLink
              target="_blank"
              href={
                networks[chainId]
                  ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                  : ''
              }
            >
              {`TX: ${shortenAddress(txHash)}`}
            </TransactionLink>
          )}
        </DeleteButton>
      ) : (
        <DeleteButton onClick={handleDelete} disabled={!accessToken || !email}>
          Delete
        </DeleteButton>
      )}
    </>
  )
}
