import styled from 'styled-components'

export const Modal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 31, 42, 0.9);

  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 300;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;

  visibility: ${({ isOpen }) => {
    if (isOpen) {
      return 'visible'
    }
    return 'hidden'
  }};

  opacity: ${({ isOpen }) => {
    if (isOpen) {
      return '1'
    }
    return '0'
  }};
`

export const ModalContainer = styled.div`
  width: max-content;
  max-width: 538px;
  background-color: #1d1f2a;
  position: relative;
  border: 1px solid rgba(73, 158, 255, 0.3);
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 336px;
    border-radius: 12px;
  }
`

export const ButtonsContainer = styled.div`
  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  padding: 20px;
  justify-content: end;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  @media (max-width: 768px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

export const ButtonWrapper = styled.div`
  margin-left: 10px;
`

export const TitleContainer = styled.div`
  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media (max-width: 768px) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
`
export const CalendarTitle = styled.h3`
  color: #9fa1a6;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 4px;
`
export const CalendarDate = styled.p`
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.4px;
  margin: 0;
`
