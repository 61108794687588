import styled from 'styled-components'

export const Modal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 31, 42, 0.9);

  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;

  visibility: ${({ isOpen }) => {
    if (isOpen) {
      return 'visible'
    }
    return 'hidden'
  }};

  opacity: ${({ isOpen }) => {
    if (isOpen) {
      return '1'
    }
    return '0'
  }};

  @media (max-width: 768px) {
    align-items: start;
    height: 100%;
    overflow-y: scroll;
  }
`

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 586px;

  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(73, 158, 255, 0.3);
  padding: 32px 0 28px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 336px;
    border-radius: 12px;
    padding: 24px 0;
    margin-top: 70px;
  }
`

export const ModalCloseButtonContainer = styled.div`
  position: absolute;
  background-color: rgba(16, 16, 44, 0.7);
  border: 1px solid rgba(73, 158, 255, 0.3);
  top: 0px;
  right: -70px;
  width: 57px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    top: -65px;
    right: 0px;
    border-radius: 12px;
    width: 52px;
    height: 52px;
  }
`

export const ModalMenuList = styled.div`
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  justify-content: space-around;
  width: 100%;
  max-width: 700px;
  position: relative;
  padding: 0 40px;
  display: flex;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #40407eb3;
    backdrop-filter: blur(10px);
  }
  @media (max-width: 768px) {
    gap: 0;
    padding: 0;
  }
`

export const ModalMenuItem = styled.button`
  text-transform: uppercase;
  background: transparent;
  position: relative;
  width: 50%;
  flex-grow: 1;
  max-width: 250px;
  text-decoration: none;
  border: none;
  color: ${({ current }) => {
    if (current) {
      return 'white'
    }
    return ' #7A97DF '
  }};
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  cursor: pointer;

  padding: 8px 13px 5px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: ${({ current }) => {
      if (current) {
        return '1px'
      }
      return '0'
    }};
    z-index: 1;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(270deg, #5e51ff 1.49%, #489dfe 100.89%);
  }

  &:active {
    background: ${({ current }) => {
      if (current === 'true') {
        return '#489dfe1a'
      }
      return 'transparent'
    }};
  }
  @media (max-width: 768px) {
    width: 50%;
    padding: 7px 13px;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 0.4px;
  }
`

export const ModalMain = styled.div`
  padding: 37px 40px 0;

  @media (max-width: 768px) {
    padding: 32px 24px 0;
  }
`

export const ModalTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: left;
  color: white;
  margin: ${({ marginBottom, marginTop }) => {
    if (marginBottom) {
      return '0 0 24px'
    } else if (marginTop) {
      return '12px 0 36px'
    }
    return '0 0 12px'
  }};

  text-transform: uppercase;
`

export const ModalQRcontainer = styled.div`
  width: 100%;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(73, 158, 255, 0.3);
  margin-bottom: ${({ marginBottom }) => {
    if (marginBottom) {
      return '40px'
    }
    return '0'
  }};
  border-radius: 20px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 0 24px;
  }
`

export const ModalQRwrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 0 24px;
  }
`

export const ModalQRtext = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  margin: 0;
  margin-right: 20px;
  @media (max-width: 768px) {
    line-height: 22px;
    margin: 0 0 24px;
  }
`
