import styled from 'styled-components'

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ size }) => {
    if (size) {
      return '0'
    }
    return '20px'
  }};
`
