const host = process.env.REACT_APP_API_HOST

export async function placeOrder(order) {
  return await fetch(`${host}/orders/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(order),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getOrders(tokenId, orderStatus, page) {
  const pageParam = page ? `page=${page}` : ''
  const active = orderStatus.includes(0) ? `&status=0` : ''
  const completed = orderStatus.includes(1) ? `&status=1` : ''
  const canceled = orderStatus.includes(2) ? `&status=2` : ''
  const expired = orderStatus.includes(3) ? `&status=3` : ''
  const notExecuted = orderStatus.includes(4) ? `&status=4` : ''

  const orders = active + completed + canceled + expired + notExecuted

  return await fetch(
    `${host}/orders/?${pageParam}&ownership=${tokenId}${orders}`,
  )
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function deleteOrder(orderHash, authorization) {
  return await fetch(`${host}/orders/${orderHash}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getTokenOrders({ tokenName, page }) {
  const pageParam = page ? `&page=${page}` : ''

  return await fetch(
    `${host}/orders/?status=0${pageParam}&full_token_name=${tokenName}`,
  )
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
