import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
body {
    margin: 0;
    font-family: 'OpenSans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width:  thin;
    scrollbar-color: #489DFE;
    scrollbar-gutter: stable;
  }
  
  code {
    font-family: 'OpenSans', sans-serif;
  }

  body::-webkit-scrollbar { 
    width: 10px;
    background-color: #10102c;
    cursor:pointer;
  }

  body::-webkit-scrollbar-track {
    cursor:pointer;
    background-color: #10102c;
  }

  body::-webkit-scrollbar-thumb {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background:  #489DFE;
    background: linear-gradient(269.99deg, #489DFE -0.04%, #5E51FF 99.98%);
    border-radius: 4px;
    cursor:pointer;
  }
`
