import React, { useState, useEffect, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import PageContainer from '../../components/PageContainer'
import { Button } from '../../components/Button'
import Header from '../../components/Header'
import BuyCharacterTableRowItem from './BuyCharacterTableRow'
import OrderBuyTableRowItem from './OrderBuyTableRow'
import Spinner from '../../components/Spinner'
import { CHARACTERS } from '../../constants/NewCharacters'
import { UserContext } from '../../contexts/UserContext'
import { getTokenOrders, getOwnerCards } from '../../api'
import { SUPPORTED_CHAINS } from '../../constants'

import {
  PageWrapper,
  PageHead,
  BackButton,
  PageBody,
  TokenPictureWrapper,
  TokenPicture,
  StyledRouterLink,
  CharacterKinds,
  CharacterKindContainer,
  CharacterKind,
  CharacterKindText,
  BuyTable,
  BuyTableContainer,
  BuyTableHead,
  BuyTableItem,
  BuyTableItemLeft,
} from './Buy.styled'

export default function Buy() {
  const { userAddress, chainId } = useContext(UserContext)
  const { tokenName } = useParams()

  const token = CHARACTERS[tokenName]

  const [tokensBefore, setTokensBefore] = useState(0)

  const [orders, setOrders] = useState([])
  const [isOrdersLoading, setIsOrdersLoading] = useState(false)
  const [currentOrderPage, setCurrentOrderPage] = useState(2)
  const [numberOfTokensOrder, setNumberOfTokensOrder] = useState(0)
  const [hasMoreOrderTokens, setHasMoreOrderTokens] = useState(false)

  function getCharactersCount() {
    getOwnerCards({ userAddress })
      .then((res) => {
        setTokensBefore(res.count)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function fetchOrders() {
    getTokenOrders({ tokenName })
      .then((orderCards) => {
        setNumberOfTokensOrder(orderCards.count)
        setOrders(orderCards.results)
        setHasMoreOrderTokens(orderCards.results.length < orderCards.count)
        setIsOrdersLoading(false)
        setCurrentOrderPage(2)
      })
      .catch((err) => {
        setIsOrdersLoading(false)
        console.log(err)
      })
  }

  async function fetchMoreOrders() {
    if (orders.length >= numberOfTokensOrder) {
      setHasMoreOrderTokens(false)
      return
    }

    if (orders.length < numberOfTokensOrder) {
      await getTokenOrders({
        tokenName,
        page: currentOrderPage,
      }).then(({ results }) => {
        setCurrentOrderPage((prev) => prev + 1)
        setOrders((prevValue) => {
          return prevValue.concat(results)
        })
      })
    }
  }

  useEffect(() => {
    setHasMoreOrderTokens(false)
    setCurrentOrderPage(2)
    setIsOrdersLoading(true)
    fetchOrders()
  }, [userAddress])

  useEffect(() => {
    if (userAddress && SUPPORTED_CHAINS.includes(chainId)) {
      getCharactersCount()
    }
  }, [userAddress, chainId])

  return (
    <>
      <Header />
      <PageContainer>
        {token ? (
          <>
            <PageWrapper>
              <PageHead>
                <div>
                  <BackButton to={'/'}>
                    <img
                      src={require('../../images/back-arrow.svg').default}
                      alt="back"
                    />
                    <span>Back</span>
                  </BackButton>
                </div>
              </PageHead>
              <PageBody>
                <TokenPictureWrapper>
                  {(token.name === 'IGNIS' ||
                    token.name === 'PENNA' ||
                    token.name === 'ORO') && (
                    <CharacterKinds>
                      <CharacterKindContainer type={token.kind}>
                        <CharacterKind>
                          <CharacterKindText type={token.kind}>
                            {token.kind}
                          </CharacterKindText>
                        </CharacterKind>
                      </CharacterKindContainer>
                      <CharacterKindContainer type={token.rarity}>
                        <CharacterKind>
                          <CharacterKindText type={token.rarity}>
                            {token.rarity}
                          </CharacterKindText>
                        </CharacterKind>
                      </CharacterKindContainer>
                    </CharacterKinds>
                  )}

                  <TokenPicture
                    src={token.imageUri}
                    alt="token"
                    width="604"
                    height="667"
                  />
                </TokenPictureWrapper>
                <BuyTable>
                  <BuyTableContainer>
                    {orders.length === 0 &&
                    (token.halloween || token.christmas) ? (
                      <p style={{ color: 'white' }}>Not available</p>
                    ) : (
                      <BuyTableHead>
                        <BuyTableItemLeft>Chain</BuyTableItemLeft>
                        <BuyTableItem color="head">Price</BuyTableItem>
                        <BuyTableItem color="head">Seller</BuyTableItem>
                        <BuyTableItem color="head">Token ID</BuyTableItem>
                        <BuyTableItem color="head"></BuyTableItem>
                      </BuyTableHead>
                    )}

                    {!token.halloween && !token.christmas && (
                      <BuyCharacterTableRowItem
                        info={token}
                        tokensBefore={tokensBefore}
                      />
                    )}
                    {isOrdersLoading ? (
                      <Spinner />
                    ) : (
                      orders.length > 0 && (
                        <InfiniteScroll
                          style={{ overflow: 'hidden' }}
                          dataLength={orders.length}
                          next={fetchMoreOrders}
                          hasMore={hasMoreOrderTokens}
                          loader={<Spinner />}
                        >
                          {orders.map((order, index) => (
                            <OrderBuyTableRowItem
                              key={index}
                              info={token}
                              order={order}
                            />
                          ))}
                        </InfiniteScroll>
                      )
                    )}
                  </BuyTableContainer>
                </BuyTable>
              </PageBody>
            </PageWrapper>
          </>
        ) : (
          <Box
            sx={{
              width: 320,
              height: 300,
              margin: '0 auto',
              mt: '60px',
              color: 'white',
              display: 'flex',
            }}
          >
            Token not found. Go to the main page to see your tokens{' '}
            <StyledRouterLink to="/">
              <Button>Go</Button>
            </StyledRouterLink>
          </Box>
        )}
      </PageContainer>
    </>
  )
}
