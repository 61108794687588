import styled from 'styled-components'
import { Border } from '../Modal/Modal.styled'

export const Modal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(29, 31, 42, 0.9);

  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10000;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;

  visibility: ${({ isOpen }) => {
    if (isOpen) {
      return 'visible'
    }
    return 'hidden'
  }};

  opacity: ${({ isOpen }) => {
    if (isOpen) {
      return '1'
    }
    return '0'
  }};
`

export const ModalContainer = styled.div`
  width: 100%;
  z-index: 10000;
  max-width: 477px;
  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(10px);

  border: 1px solid rgba(73, 158, 255, 0.3);
  padding: 24px 0 0;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 336px;
    border-radius: 12px;
    padding: 12px 0 0;
  }
`

export const ModalWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 32px 40px 40px;

  @media (max-width: 768px) {
    padding: 16px 20px 20px;
  }
`

export const ModalButtonContainer = styled.div`
  position: absolute;
  background-color: #0f0e2c;
  border: 1px solid rgba(73, 158, 255, 0.3);
  top: -1px;
  right: -60px;
  border-radius: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    top: -65px;
    right: 0px;
    width: 52px;
    height: 52px;
  }
`

export const ModalForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalInputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 24px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const ModalInputLabel = styled.label`
  box-sizing: border-box;
  display: block;
  color: #a3a4a9;
  position: absolute;
  top: 20px;
  left: 23px;
  padding: 1px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;

  font-size: 12px;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;

  @media (max-width: 768px) {
    font-size: 10px;

    line-height: 15px;
    letter-spacing: 0.5px;
    top: 10px;
    left: 16px;
  }
`

export const ModalError = styled.div`
  margin-left: 10px;
  color: red;
  font-size: 12px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: -20px;
  @media (max-width: 768px) {
    font-size: 10px;
    bottom: -16px;
  }
`

export const ModalNameWrapper = styled.div`
  border: none;
  cursor: ${({ max }) => {
    if (max) {
      return 'pointer'
    }
    return 'default'
  }};
  position: absolute;
  padding: 1px;
  background: linear-gradient(274.38deg, #499eff 16.74%, #5f51ff 39.72%);
  top: 26px;
  right: 24px;
  border-radius: 6px;
  transform: skew(-13deg, 0);
  @media (max-width: 768px) {
    top: 14px;
    right: 16px;
  }
`

export const ModalName = styled.div`
  background: rgb(16, 16, 44);
  border-radius: 6px;
  padding: 11px 16px;
  min-width: 109px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;
  color: white;
  text-transform: uppercase;

  background-color: ${({ invalid, disabled }) => {
    if (disabled) {
      return ' #262a35'
    }
    if (invalid) {
      return '#2a1a2a '
    }
    return 'rgb(16, 16, 44)'
  }};

  @media (max-width: 768px) {
    padding: 6px;
    min-width: 80px;
    max-width: 90px;
    font-size: 14px;
    line-height: 22px;
  }
`

export const ModalImg = styled.img`
  position: relative;
  left: -2px;
  top: 2px;
  transform: none;
  margin-right: ${({ variant }) => {
    if (variant === 'opal') {
      return '4px '
    }
    return '2px'
  }};

  @media (max-width: 768px) {
    height: 14px;
    margin-right: ${({ variant }) => {
      if (variant === 'opal') {
        return '4px '
      }
      return '0'
    }};
  }
`

export const ModalWithdrawButtonWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  @media (max-width: 768px) {
    margin-top: 17px;

    margin-top: 4px;
  }
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  width: 100%;
  margin-top: 16px;
  @media (max-width: 768px) {
    margin-top: 17px;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-top: 12px;
  }
`

export const ModalTextError = styled.p`
  color: red;
  margin: 0 auto 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  overflow: hidden;
  max-width: 100%;
`
export const ModalMenuList = styled.div`
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  justify-content: space-around;
  width: 100%;
  max-width: 700px;
  position: relative;

  display: flex;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #40407eb3;
    backdrop-filter: blur(10px);
  }
  @media (max-width: 768px) {
    gap: 0;
    padding: 0;
  }
`

export const ModalMenuItem = styled.button`
  text-transform: uppercase;
  background: transparent;
  position: relative;
  width: 50%;

  flex-grow: 1;
  max-width: 250px;
  text-decoration: none;
  border: none;
  color: ${({ current }) => {
    if (current) {
      return 'white'
    }
    return ' #7A97DF '
  }};
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  cursor: pointer;

  padding: 16px 13px 6px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: ${({ current }) => {
      if (current) {
        return '1px'
      }
      return '0'
    }};
    z-index: 1;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(270deg, #5e51ff 1.49%, #489dfe 100.89%);
  }

  &:active {
    background: ${({ current }) => {
      if (current === 'true') {
        return '#489dfe1a'
      }
      return 'transparent'
    }};
  }
  @media (max-width: 768px) {
    width: 50%;
    padding: 7px 13px;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 0.4px;
  }
`

export const ModalRules = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  text-align: left;
  color: white;
`

export const ModalBalances = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
  &:first-child {
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ModalBalance = styled.p`
  color: white;
  margin: 0;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
`

export const BorderStyled = styled(Border)`
  margin: 24px 0;
  width: 100%;
  @media (max-width: 768px) {
    margin: 12px 0;
  }
`

export const ModalLargeText = styled.p`
  color: white;
  margin: 0;
  align-self: flex-start;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.4px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 34px;
  }
`

export const ModalTextContainer = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
