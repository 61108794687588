export const CHARACTERS = [
  {
    name: 'IGNIS',
    contractKind: '0x00010802',
    imageUri: 'https://app.metarun.game/images/ignis-legendary-mythical.png',
    kind: 'Legendary',
    rarity: 'Mythical',
    price: '120',
    discountPrice: '120',
    earingPerHour: '$83.33',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020802',
    imageUri: 'https://app.metarun.game/images/penna-legendary-mythical.png',
    kind: 'Legendary',
    rarity: 'Mythical',
    price: '120',
    discountPrice: '120',
    earingPerHour: '$83.33',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'ORO',
    contractKind: '0x00030802',
    imageUri: 'https://app.metarun.game/images/oro-legendary-mythical.png',
    kind: 'Legendary',
    rarity: 'Mythical',
    price: '120',
    discountPrice: '120',
    earingPerHour: '$83.33',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010801',
    imageUri: 'https://app.metarun.game/images/ignis-legendary-rare.png',
    kind: 'Legendary',
    rarity: 'Rare',
    price: '60',
    discountPrice: '60',
    earingPerHour: '$66.67',
    maxLevel: '10',
    maxAbility: '30',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020801',
    imageUri: 'https://app.metarun.game/images/penna-legendary-rare.png',
    kind: 'Legendary',
    rarity: 'Rare',
    price: '60',
    discountPrice: '60',
    earingPerHour: '$66.67',
    maxLevel: '10',
    maxAbility: '30',
  },
  {
    name: 'ORO',
    contractKind: '0x00030801',
    imageUri: 'https://app.metarun.game/images/oro-legendary-rare.png',
    kind: 'Legendary',
    rarity: 'Rare',
    price: '60',
    discountPrice: '60',
    earingPerHour: '$66.67',
    maxLevel: '10',
    maxAbility: '30',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010800',
    imageUri: 'https://app.metarun.game/images/ignis-legendary-common.png',
    kind: 'Legendary',
    rarity: 'Common',
    price: '30',
    discountPrice: '30',
    earingPerHour: '$51.11',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020800',
    imageUri: 'https://app.metarun.game/images/penna-legendary-common.png',
    kind: 'Legendary',
    rarity: 'Common',
    price: '30',
    discountPrice: '30',
    earingPerHour: '$51.11',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'ORO',
    contractKind: '0x00030800',
    imageUri: 'https://app.metarun.game/images/oro-legendary-common.png',
    kind: 'Legendary',
    rarity: 'Common',
    price: '30',
    discountPrice: '30',
    earingPerHour: '$51.11',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010702',
    imageUri: 'https://app.metarun.game/images/ignis-epic-mythical.png',
    kind: 'Epic',
    rarity: 'Mythical',
    price: '80',
    discountPrice: '80',
    earingPerHour: '$36.67',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020702',
    imageUri: 'https://app.metarun.game/images/penna-epic-mythical.png',
    kind: 'Epic',
    rarity: 'Mythical',
    price: '80',
    discountPrice: '80',
    earingPerHour: '$36.67',
    maxLevel: '15',
    maxAbility: '45',
  },

  {
    name: 'ORO',
    contractKind: '0x00030702',
    imageUri: 'https://app.metarun.game/images/oro-epic-mythical.png',
    kind: 'Epic',
    rarity: 'Mythical',
    price: '80',
    discountPrice: '80',
    earingPerHour: '$36.67',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010701',
    imageUri: 'https://app.metarun.game/images/ignis-epic-rare.png',
    kind: 'Epic',
    rarity: 'Rare',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$29.17',
    maxLevel: '10',
    maxAbility: '30',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020701',
    imageUri: 'https://app.metarun.game/images/penna-epic-rare.png',
    kind: 'Epic',
    rarity: 'Rare',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$29.17',
    maxLevel: '10',
    maxAbility: '30',
  },

  {
    name: 'ORO',
    contractKind: '0x00030701',
    imageUri: 'https://app.metarun.game/images/oro-epic-rare.png',
    kind: 'Epic',
    rarity: 'Rare',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$29.17',
    maxLevel: '10',
    maxAbility: '30',
  },

  {
    name: 'IGNIS',
    contractKind: '0x00010700',
    imageUri: 'https://app.metarun.game/images/ignis-epic-common.png',
    kind: 'Epic',
    rarity: 'Common',
    price: '20',
    discountPrice: '20',
    earingPerHour: '$22.22',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020700',
    imageUri: 'https://app.metarun.game/images/penna-epic-common.png',
    kind: 'Epic',
    rarity: 'Common',
    price: '20',
    discountPrice: '20',
    earingPerHour: '$22.22',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'ORO',
    contractKind: '0x00030700',
    imageUri: 'https://app.metarun.game/images/oro-epic-common.png',
    kind: 'Epic',
    rarity: 'Common',
    price: '20',
    discountPrice: '20',
    earingPerHour: '$22.22',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010602',
    imageUri: 'https://app.metarun.game/images/ignis-classic-mythical.png',
    kind: 'Classic',
    rarity: 'Mythical',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$15.83',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020602',
    imageUri: 'https://app.metarun.game/images/penna-classic-mythical.png',
    kind: 'Classic',
    rarity: 'Mythical',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$15.83',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'ORO',
    contractKind: '0x00030602',
    imageUri: 'https://app.metarun.game/images/oro-classic-mythical.png',
    kind: 'Classic',
    rarity: 'Mythical',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$15.83',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    name: 'IGNIS',
    contractKind: '0x000010601',
    imageUri: 'https://app.metarun.game/images/ignis-classic-rare.png',
    kind: 'Classic',
    rarity: 'Rare',
    price: '20',
    discountPrice: '20',
    earingPerHour: '$10.00',
    maxLevel: '10',
    maxAbility: '30',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020601',
    imageUri: 'https://app.metarun.game/images/penna-classic-rare.png',
    kind: 'Classic',
    rarity: 'Rare',
    price: '20',
    discountPrice: '20',
    earingPerHour: '$10.00',
    maxLevel: '10',
    maxAbility: '30',
  },
  {
    name: 'ORO',
    contractKind: '0x00030601',
    imageUri: 'https://app.metarun.game/images/oro-classic-rare.png',
    kind: 'Classic',
    rarity: 'Rare',
    price: '20',
    discountPrice: '20',
    earingPerHour: '$10.00',
    maxLevel: '10',
    maxAbility: '30',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010600',
    imageUri: 'https://app.metarun.game/images/ignis-classic-common.png',
    kind: 'Classic',
    rarity: 'Common',
    price: '10',
    discountPrice: '10',
    earingPerHour: '$4.72',
    maxLevel: '5',
    maxAbility: '15',
  },

  {
    name: 'PENNA',
    contractKind: '0x00020600',
    imageUri: 'https://app.metarun.game/images/penna-classic-common.png',
    kind: 'Classic',
    rarity: 'Common',
    price: '10',
    discountPrice: '10',
    earingPerHour: '$4.72',
    maxLevel: '5',
    maxAbility: '15',
  },

  {
    name: 'ORO',
    contractKind: '0x00030600',
    imageUri: 'https://app.metarun.game/images/oro-classic-common.png',
    kind: 'Classic',
    rarity: 'Common',
    price: '10',
    discountPrice: '10',
    earingPerHour: '$4.72',
    maxLevel: '5',
    maxAbility: '15',
  },
]
export const HALLOWEEN_CHARACTERS = [
  {
    name: 'IGNIS',
    contractKind: '0x00010803',
    imageUri:
      'https://app.metarun.game/images/ignis-legendary-common-halloween.png',
    kind: 'Legendary',
    rarity: 'Common',
    price: '100',
    discountPrice: '100',
    earingPerHour: '$51.11',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020803',
    imageUri:
      'https://app.metarun.game/images/penna-legendary-common-halloween.png',
    kind: 'Legendary',
    rarity: 'Common',
    price: '100',
    discountPrice: '100',
    earingPerHour: '$51.11',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'ORO',
    contractKind: '0x00030803',
    imageUri:
      'https://app.metarun.game/images/oro-legendary-common-halloween.png',
    kind: 'Legendary',
    rarity: 'Common',
    price: '100',
    discountPrice: '100',
    earingPerHour: '$51.11',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010703',
    imageUri: 'https://app.metarun.game/images/ignis-epic-common-halloween.png',
    kind: 'Epic',
    rarity: 'Common',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$22.22',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020703',
    imageUri: 'https://app.metarun.game/images/penna-epic-common-halloween.png',
    kind: 'Epic',
    rarity: 'Common',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$22.22',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'ORO',
    contractKind: '0x00030703',
    imageUri: 'https://app.metarun.game/images/oro-epic-common-halloween.png',
    kind: 'Epic',
    rarity: 'Common',
    price: '40',
    discountPrice: '40',
    earingPerHour: '$22.22',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'IGNIS',
    contractKind: '0x00010603',
    imageUri:
      'https://app.metarun.game/images/ignis-classic-common-halloween.png',
    kind: 'Classic',
    rarity: 'Common',
    price: '15',
    discountPrice: '15',
    earingPerHour: '$4.72',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'PENNA',
    contractKind: '0x00020603',
    imageUri:
      'https://app.metarun.game/images/penna-classic-common-halloween.png',
    kind: 'Classic',
    rarity: 'Common',
    price: '15',
    discountPrice: '15',
    earingPerHour: '$4.72',
    maxLevel: '5',
    maxAbility: '15',
  },
  {
    name: 'ORO',
    contractKind: '0x00030603',
    imageUri:
      'https://app.metarun.game/images/oro-classic-common-halloween.png',
    kind: 'Classic',
    rarity: 'Common',
    price: '15',
    discountPrice: '15',
    earingPerHour: '$4.72',
    maxLevel: '5',
    maxAbility: '15',
  },
]

export const CHRISTMAS_CHARACTERS = [
  {
    christmas: true,
    name: 'IGNIS',
    contractKind: '0x00010804',
    imageUri:
      'https://app.metarun.game/images/ignis-legendary-mythical-christmas.png',
    kind: 'Legendary',
    rarity: 'Mythical',
    price: '300',
    discountPrice: '170',
    earingPerHour: '$83.33',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'PENNA',
    contractKind: '0x00020804',
    imageUri:
      'https://app.metarun.game/images/penna-legendary-mythical-christmas.png',
    kind: 'Legendary',
    rarity: 'Mythical',
    price: '300',
    discountPrice: '170',
    earingPerHour: '$83.33',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'ORO',
    contractKind: '0x00030804',
    imageUri:
      'https://app.metarun.game/images/oro-legendary-mythical-christmas.png',
    kind: 'Legendary',
    rarity: 'Mythical',
    price: '300',
    discountPrice: '170',
    earingPerHour: '$83.33',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'IGNIS',
    contractKind: '0x00010704',
    imageUri:
      'https://app.metarun.game/images/ignis-epic-mythical-christmas.png',
    kind: 'Epic',
    rarity: 'Mythical',
    price: '200',
    discountPrice: '120',
    earingPerHour: '$36.67',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'PENNA',
    contractKind: '0x00020704',
    imageUri:
      'https://app.metarun.game/images/penna-epic-mythical-christmas.png',
    kind: 'Epic',
    rarity: 'Mythical',
    price: '200',
    discountPrice: '120',
    earingPerHour: '$36.67',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'ORO',
    contractKind: '0x00030704',
    imageUri: 'https://app.metarun.game/images/oro-epic-mythical-christmas.png',
    kind: 'Epic',
    rarity: 'Mythical',
    price: '200',
    discountPrice: '120',
    earingPerHour: '$36.67',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'IGNIS',
    contractKind: '0x00010604',
    imageUri:
      'https://app.metarun.game/images/ignis-classic-mythical-christmas.png',
    kind: 'Classic',
    rarity: 'Mythical',
    price: '100',
    discountPrice: '70',
    earingPerHour: '$15.83',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'PENNA',
    contractKind: '0x00020604',
    imageUri:
      'https://app.metarun.game/images/penna-classic-mythical-christmas.png',
    kind: 'Classic',
    rarity: 'Mythical',
    price: '100',
    discountPrice: '70',
    earingPerHour: '$15.83',
    maxLevel: '15',
    maxAbility: '45',
  },
  {
    christmas: true,
    name: 'ORO',
    contractKind: '0x00030604',
    imageUri:
      'https://app.metarun.game/images/oro-classic-mythical-christmas.png',
    kind: 'Classic',
    rarity: 'Mythical',
    price: '100',
    discountPrice: '70',
    earingPerHour: '$15.83',
    maxLevel: '15',
    maxAbility: '45',
  },
]
