import React, { useState, useContext } from 'react'
import { utils } from 'ethers'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Button } from '../../components/Button'
import BuyCharacterModal from '../../components/BuyCharacterModal'
import Emoji from '../../components/Emoji'
import { SUPPORTED_CHAINS, EIP712_CHAIN_ID } from '../../constants'
import { getNetworkIcon } from '../../utils'
import { UserContext } from '../../contexts/UserContext'

import {
  BuyTableItem,
  BuyTableRow,
  AccordionButton,
  BuyTableRowWrapper,
  BuyTableRowMore,
  Border,
  MoreTitle,
  MoreList,
  MoreContainer,
  MoreItem,
  MoreItemDescription,
  MoreItemValue,
  BuyTableItemDescription,
  MoreDetails,
  LessDetails,
  MobileBorder,
  SaleText,
  SaleChevron,
  Icon,
} from './Buy.styled'
import SwitchNetworkModal from '../../components/SwitchNetworkModal'

export default function BuyCharacterTableRowItem({ info, tokensBefore }) {
  const { userAddress, chainId } = useContext(UserContext)

  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [buyCharacterModalIsOpen, setBuyCharacterModalIsOpen] = useState(false)
  const [swtichNetworkModalOpen, setSwitchtNetworkModalOpen] = useState(false)

  function handleCloseBuyModal() {
    setBuyCharacterModalIsOpen(false)
  }

  function handleOpenBuyModal() {
    if (Number(chainId) === EIP712_CHAIN_ID) {
      setBuyCharacterModalIsOpen(true)
    } else setSwitchtNetworkModalOpen(true)
  }

  return (
    <>
      <BuyTableRowWrapper>
        {info.christmas && <SaleChevron>Sale</SaleChevron>}
        <BuyTableRow show={isDetailsOpen}>
          <BuyTableItem>
            <BuyTableItemDescription>Chain</BuyTableItemDescription>
            <Icon src={getNetworkIcon(EIP712_CHAIN_ID)} alt="networkicon" />
          </BuyTableItem>
          <BuyTableItem>
            <BuyTableItemDescription>Price</BuyTableItemDescription>
            <span>
              <SaleText>{info.christmas && info.price}</SaleText>{' '}
              {info.discountPrice
                ? info.discountPrice
                : utils.formatEther(info.price)}{' '}
              BUSD
            </span>
          </BuyTableItem>
          <BuyTableItem>
            <BuyTableItemDescription>Seller</BuyTableItemDescription>
            <span style={{ color: '#4188FF' }}>METARUN</span>
          </BuyTableItem>
          <BuyTableItem>
            <BuyTableItemDescription>Token ID</BuyTableItemDescription>
            <span>-</span>
          </BuyTableItem>
          <MobileBorder />
          <BuyTableItem button>
            <Button
              variant="contained"
              size="buypage"
              onClick={handleOpenBuyModal}
              disabled={
                !info.christmas ||
                !userAddress ||
                !SUPPORTED_CHAINS.includes(chainId)
              }
            >
              Buy
            </Button>{' '}
            {info.name !== 'MYSTERY BOX' && (
              <AccordionButton onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
                {isDetailsOpen ? (
                  <ExpandLessIcon
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{
                      color: 'white',
                    }}
                  />
                )}
              </AccordionButton>
            )}
          </BuyTableItem>
          {info.name !== 'MYSTERY BOX' && (
            <MoreDetails
              show={isDetailsOpen}
              onClick={() => setIsDetailsOpen(true)}
            >
              See Details{' '}
              <ExpandMoreIcon
                sx={{
                  color: '#FFFFFF8F',
                  position: 'relative',
                  top: '6px',
                }}
              />
            </MoreDetails>
          )}
        </BuyTableRow>

        <BuyTableRowMore show={isDetailsOpen}>
          <Border />
          <MoreContainer>
            <MoreTitle>Attributes</MoreTitle>
            <MoreList>
              <MoreItem>
                <MoreItemDescription>Action Ability:</MoreItemDescription>
                <MoreItemValue>1/{info.maxAbility}</MoreItemValue>
              </MoreItem>
              <MoreItem>
                <MoreItemDescription>Mana Ability:</MoreItemDescription>
                <MoreItemValue>1/{info.maxAbility}</MoreItemValue>
              </MoreItem>
            </MoreList>
            <MoreList>
              <MoreItem>
                <MoreItemDescription>Level:</MoreItemDescription>
                <MoreItemValue>1/{info.maxLevel}</MoreItemValue>
              </MoreItem>
              {info.christmas && (
                <MoreItem>
                  <MoreItemValue>
                    <Emoji label="lightning" symbol="⚡" /> energy bonus{' '}
                    {info.energyBonus}
                  </MoreItemValue>
                </MoreItem>
              )}
            </MoreList>
          </MoreContainer>
          {info.name !== 'MYSTERY BOX' && (
            <LessDetails
              show={isDetailsOpen}
              onClick={() => setIsDetailsOpen(false)}
            >
              See Details{' '}
              <ExpandLessIcon
                sx={{
                  color: '#FFFFFF8F',
                  position: 'relative',
                  top: '6px',
                }}
              />
            </LessDetails>
          )}
        </BuyTableRowMore>
      </BuyTableRowWrapper>
      <BuyCharacterModal
        isOpen={buyCharacterModalIsOpen}
        onClose={handleCloseBuyModal}
        busdPrice={info.discountPrice}
        kind={info.contractKind}
        tokensBefore={tokensBefore}
      />
      <SwitchNetworkModal
        isOpen={swtichNetworkModalOpen}
        onClose={() => setSwitchtNetworkModalOpen(false)}
        openModal={() => setBuyCharacterModalIsOpen(true)}
      />
    </>
  )
}
