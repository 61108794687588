import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import MainMenu from '../../components/MainMenu'
import PageContainer from '../../components/PageContainer'
import CardsContainer from '../../components/CardsContainer'
import Header from '../../components/Header'
import PagesMenu from '../../components/PagesMenu'
import PromoForm from '../../components/PromoForm'

function Home(props) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const referral = searchParams.get('referral')
  const invite = searchParams.get('invite')
  const mysteryreferral = searchParams.get('mysteryreferral')
  const [isPromoOpen, setIsPromoOpen] = useState(false)
  const [promoFormIsSend, setPromoFormIsSend] = useState(true)
  const [referralName, setReferralName] = useState('')

  function handleClosePromo() {
    setIsPromoOpen(false)
    navigate('/')
  }
  function handleOpenPromo() {
    setIsPromoOpen(true)
  }

  useEffect(() => {
    if (referral) {
      window.localStorage.setItem('Referral', referral)
      setIsPromoOpen(true)
    }
  }, [referral])

  useEffect(() => {
    if (invite) {
      window.localStorage.setItem('Invite', invite)
    }
  }, [invite])

  useEffect(() => {
    if (mysteryreferral) {
      window.localStorage.setItem('Mysteryreferral', mysteryreferral)
    }
  }, [mysteryreferral])

  useEffect(() => {
    if (window.localStorage.getItem('PromoRegister')) {
      setPromoFormIsSend(true)
    } else {
      setPromoFormIsSend(false)
    }
  }, [referral])

  useEffect(() => {
    if (window.localStorage.getItem('Referral')) {
      setReferralName(window.localStorage.getItem('Referral'))
    }
  }, [referral])

  return (
    <>
      <Header
        promoFormIsSend={promoFormIsSend}
        handleOpenPromo={handleOpenPromo}
        referral={referralName}
        {...props}
      />
      <PagesMenu props />
      <PageContainer>
        <MainMenu {...props} />
        <CardsContainer {...props} />
      </PageContainer>
      <PromoForm
        referral={referral}
        onClose={handleClosePromo}
        isOpen={isPromoOpen}
        setPromoFormIsSend={setPromoFormIsSend}
      />
    </>
  )
}

export default Home
