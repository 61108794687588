import { useState, useCallback, useEffect, useRef } from 'react'
import validator from 'validator'
import { Button } from '../Button'
import { quickRegister } from '../../api'
import {
  FormContainer,
  MainForm,
  FormInputContainer,
  FormInputLabel,
  FormInput,
  Input,
  ButtonContainer,
  FormTitle,
  FormError,
  FormText,
  FormButtonContainer,
  FormCloseButton,
} from './PromoForm.styled'

export default function PromoForm({
  referral,
  isOpen,
  onClose,
  setPromoFormIsSend,
}) {
  const modal = useRef(null)
  const [answers, setAnswers] = useState({})
  const [errorText, setErrorText] = useState('')

  const handleEmail = (e) => {
    setAnswers((prevState) => {
      return {
        ...prevState,
        email: e.target.value,
      }
    })
  }

  const handleName = (e) => {
    setAnswers((prevState) => {
      return {
        ...prevState,
        name: e.target.value,
      }
    })
  }

  const handleAge = (e) => {
    const value = e.target.value
    const isInteger = /^[0-9]*$/g.test(value)

    if (isInteger) {
      setAnswers((prevState) => {
        return {
          ...prevState,
          age: value,
        }
      })
    }
  }
  const handleCountry = (e) => {
    setAnswers((prevState) => {
      return {
        ...prevState,
        country: e.target.value,
      }
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    quickRegister(answers, referral || window.localStorage.getItem('Referral'))
      .then(() => {
        setErrorText('')
        setPromoFormIsSend(true)
        window.localStorage.setItem('PromoRegister', true)
        onClose()
      })
      .catch((e) => {
        console.log(e)
        setErrorText('Something went wrong')
      })
  }

  const handleOverlayClose = useCallback((event) => {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }, [])

  useEffect(() => {
    if (modal.current) {
      modal.current.addEventListener('click', handleOverlayClose)
    }
    return () => {
      if (modal.current) {
        modal.current.removeEventListener('click', handleOverlayClose)
      }
    }
  }, [handleOverlayClose])

  return (
    <FormContainer isOpen={isOpen} ref={modal}>
      <MainForm>
        <FormButtonContainer onClick={onClose}>
          <FormCloseButton
            src={require('../../images/Close.svg').default}
            alt="close"
            width="14"
            height="14"
          ></FormCloseButton>
        </FormButtonContainer>
        <FormTitle>Quick registration</FormTitle>
        <FormText>
          Please fill out the form to access advanced features without
          connecting to an ethereum wallet
        </FormText>
        <FormInputContainer marginBottom="small">
          <FormInput>
            <Input value={answers.name || ''} onChange={handleName} />
            <FormInputLabel variant={answers.name ? 'filled' : ''}>
              Your name
            </FormInputLabel>
          </FormInput>
          <FormInput
            invalid={answers.email && !validator.isEmail(answers.email)}
          >
            <Input value={answers.email || ''} onChange={handleEmail} />
            <FormInputLabel variant={answers.email ? 'filled' : ''}>
              Email
            </FormInputLabel>
          </FormInput>
        </FormInputContainer>
        <FormInputContainer marginBottom="none">
          <FormInput>
            <Input value={answers.age || ''} onChange={handleAge} />
            <FormInputLabel variant={answers.age ? 'filled' : ''}>
              Your age
            </FormInputLabel>
          </FormInput>
          <FormInput>
            <Input value={answers.country || ''} onChange={handleCountry} />
            <FormInputLabel variant={answers.country ? 'filled' : ''}>
              Country
            </FormInputLabel>
          </FormInput>
        </FormInputContainer>

        <ButtonContainer>
          <FormError>{errorText}</FormError>
          <Button
            onClick={handleSubmit}
            disabled={
              !answers.name ||
              !answers.age ||
              !answers.email ||
              !answers.country ||
              (answers.email && !validator.isEmail(answers.email))
            }
            variant="contained"
            size="registerModal"
          >
            Submit
          </Button>
        </ButtonContainer>
      </MainForm>
    </FormContainer>
  )
}
