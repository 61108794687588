import styled from 'styled-components'

export const NetworkContainer = styled.div`
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  background-color: #1b1a37;
  border-radius: 20px;
  color: white;
  border: none;
  display: flex;
  cursor: pointer;
  padding: 12px 14px;
  align-items: center;
  @media (max-width: 768px) {
    border-radius: 12px;
    padding: 5px;
  }
`

export const NetworkText = styled.p`
  margin: 0;
  margin-left: 8px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const NetworkImg = styled.img`
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`
