import React, { useState, useEffect } from 'react'
import { TokenVideo } from './CardDetail.styled'

export default function CardDetailVideo({ videoUri }) {
  const [isVideoPlay, setIsVideoPlay] = useState(true)

  useEffect(() => {
    const videoTimeout = setTimeout(() => {
      setIsVideoPlay(true)
    }, 6000)

    return () => {
      clearInterval(videoTimeout)
    }
  }, [isVideoPlay])

  return (
    <TokenVideo
      url={[{ src: videoUri, type: 'video/webm' }]}
      playing={isVideoPlay}
      volume={0}
      muted
      playsinline
      onEnded={() => setIsVideoPlay(false)}
    />
  )
}
