import styled from 'styled-components'
import { Border } from '../Modal/Modal.styled'

export const ModalMain = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalTextWrapper = styled.span`
  font-weight: 700;
`

export const ModalBoldText = styled.div`
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  margin: 20px 0 30px;
`

export const ModalError = styled.p`
  color: red;
  margin: 0 auto 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 100%;
  overflow: hidden;
`

export const BorderStyled = styled(Border)`
  width: 100%;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    width: 95%;
  }
`

export const ModalButtonItem = styled.div`
  margin: 0 auto;
  width: max-content;
`
