import React, { useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { shortenAddress } from '../../utils/shortenAddress'
import { Button } from '../Button'
import ModalComponent from '../Modal'

import {
  ModalAddress,
  ModalButtonWrapper,
  ModalImage,
  ModalWrapper,
} from './SuccessModal.styled'

import { ModalTitle, ModalText } from '../Modal/Modal.styled'

export default function SuccessModal({ isOpen, onClose }) {
  const { userAddress } = useContext(UserContext)

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <ModalTitle margin success>
        Awesome!
      </ModalTitle>
      <ModalWrapper>
        <ModalImage
          src={require('../../images/Congratulations.png')}
          alt="congratulations"
          width="84"
          height="80"
        ></ModalImage>
        <ModalText type="success" mb="32px">
          Wallet:{' '}
          <ModalAddress>
            {userAddress && shortenAddress(userAddress)}
          </ModalAddress>{' '}
          now linked to your game account to get started
        </ModalText>
        <ModalButtonWrapper>
          <Button
            size="successModal"
            type="submit"
            variant="successModal"
            onClick={onClose}
          >
            Ok
          </Button>
        </ModalButtonWrapper>
      </ModalWrapper>
    </ModalComponent>
  )
}
