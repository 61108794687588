import styled from 'styled-components'

export const SpinnerSummary = styled.div`
  list-style: none;
  display: flex;
  justify-content: center;
  max-width: 1020px;
  width: 100%;
  padding: 9px 0;
  box-sizing: border-box;

  margin-bottom: 32px;
  background: #489dfe1a;
  backdrop-filter: blur(38px);
  border-radius: 20px;
  @media (max-width: 1203px) {
    padding: 105px 0;
  }
`
export const SummaryContainer = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  max-width: 1020px;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  margin: 0;
  justify-content: space-between;
  margin-bottom: 32px;
  background: #489dfe1a;
  backdrop-filter: blur(38px);
  border-radius: 20px;
  @media (max-width: 1203px) {
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    padding: 0 16px;

    .item-2:before {
      content: '';
      position: absolute;
      top: 16px;
      bottom: 0;
      left: 0;
      width: 1px;

      background: rgba(73, 158, 255, 0.35);
      background: linear-gradient(
        278.44deg,
        rgba(73, 158, 255, 0.35) 42.55%,
        rgba(95, 81, 255, 0.35) 84.23%
      );
    }
    .item-4:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;

      background: rgba(73, 158, 255, 0.35);
      background: linear-gradient(
        278.44deg,
        rgba(73, 158, 255, 0.35) 42.55%,
        rgba(95, 81, 255, 0.35) 84.23%
      );
    }
    .item-5 {
      grid-column: 1 / 3;
    }
  }
`
export const SummaryItem = styled.li`
  padding: 0 25px;
  box-sizing: border-box;
  position: relative;
  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(
      278.44deg,
      rgba(73, 158, 255, 0.35) 42.55%,
      rgba(95, 81, 255, 0.35) 84.23%
    );
  }

  @media (max-width: 1203px) {
    min-width: 152px;
    padding: 16px 10px;

    &:not(:last-child):after {
      height: 1px;
      width: 100%;
      bottom: 0;
      top: auto;
    }
  }
`

export const SummaryItemTitle = styled.h3`
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #7a97df;
  @media (max-width: 1203px) {
    text-align: center;
  }
`

export const SummaryItemText = styled.p`
  margin: 0;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: 1203px) {
    text-align: center;
  }
`

export const SummaryItemRuns = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  display: inline;
  position: relative;
  vertical-align: super;
  right: -8px;
`

export const SummaryItemRun = styled.span`
  color: white;
`
