import { useState, useEffect } from 'react'
import { getUser } from '../api'

const useUserInfo = ({ userAddress, chainId }) => {
  const [username, setUserName] = useState('')
  const [email, setEmail] = useState()
  const [playfabId, setPlayfabId] = useState()
  const [referralCode, setReferralCode] = useState()

  function getUserInfo() {
    getUser(userAddress)
      .then((res) => {
        setReferralCode(res.referralCode)
        setUserName(res.username)
        setEmail(res.email)
        setPlayfabId(res.playfabId)
      })
      .catch((err) => console.log(err))
  }

  function resetUserInfo() {
    setReferralCode()
    setUserName('')
    setEmail()
    setPlayfabId()
  }

  useEffect(() => {
    if (userAddress) {
      getUserInfo()
    }
  }, [userAddress, chainId])

  return {
    username,
    email,
    playfabId,
    referralCode,
    getUserInfo,
    resetUserInfo,
  }
}

export default useUserInfo
