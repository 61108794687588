import { format } from 'date-fns'

export function formatReferralDate(date) {
  const datestring =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1) +
    '-' +
    date.getDate() +
    'T' +
    date.getUTCHours() +
    ':' +
    date.getMinutes() +
    ':' +
    date.getSeconds() +
    '+00:00'

  return datestring
}

export function formatDate(date, withTime) {
  if (!date) return
  if (withTime) {
    return format(new Date(date * 1000), 'dd MMM yyyy HH:mm')
  }

  return format(new Date(date * 1000), 'dd MMM yyyy')
}

export function getTimeFromTimeStamp(datetimeStamp) {
  const date = new Date(datetimeStamp * 1000)
  let minutes = date.getUTCMinutes()
  let hours = date.getUTCHours()
  let month = date.getUTCMonth() + 1
  let days = date.getUTCDate()

  if (Number(minutes) < 10) {
    minutes = '0' + minutes
  }
  if (Number(hours) < 10) {
    hours = '0' + hours
  }
  if (Number(days) < 10) {
    days = '0' + days
  }
  if (Number(month) < 10) {
    month = '0' + month
  }

  const formattedTime =
    date.getUTCFullYear() +
    '-' +
    month +
    '-' +
    days +
    ' ' +
    hours +
    ':' +
    minutes +
    ' UTC'

  return formattedTime
}

export function getFormattedUTCDateFromString(dateString) {
  const date = new Date(Date.parse(dateString))
  const UTC = date.toUTCString(date).split(' ')
  const UTCTime = UTC[4].split(':')[0] + ':' + UTC[4].split(':')[1]
  const formated =
    UTC[2] + ' ' + UTC[1] + ', ' + UTC[3] + ' ' + UTCTime + ' UTC'
  return formated
}

export function getFormattedUTCDate(date) {
  const UTC = date.toUTCString(date).split(' ')
  const UTCTime = UTC[4].split(':')[0] + ':' + UTC[4].split(':')[1]
  const formated =
    UTC[0] +
    ' ' +
    UTC[2] +
    ' ' +
    UTC[1] +
    ', ' +
    UTC[3] +
    ' ' +
    UTCTime +
    ' UTC'
  return formated
}

export function getStringMonth(month) {
  switch (month) {
    case 1:
      return 'January'
    case 2:
      return 'February'
    case 3:
      return 'March'
    case 4:
      return 'April'
    case 5:
      return 'May'
    case 6:
      return 'June'
    case 7:
      return 'July'
    case 8:
      return 'August'
    case 9:
      return 'September'
    case 10:
      return 'October'
    case 11:
      return 'November'
    case 12:
      return 'December'
    default:
      return ''
  }
}
