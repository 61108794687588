import styled from 'styled-components'

export const Modal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(29, 31, 42, 0.9);

  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;

  visibility: ${({ isOpen }) => {
    if (isOpen) {
      return 'visible'
    }
    return 'hidden'
  }};

  opacity: ${({ isOpen }) => {
    if (isOpen) {
      return '1'
    }
    return '0'
  }};
`
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-right: 20px;
  &::-webkit-scrollbar {
    width: 0;
  }
  overflow: hidden auto;
  @media (max-width: 768px) {
    padding-right: 13px;
  }
`

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 527px;
  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(73, 158, 255, 0.3);
  padding: 40px 40px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 85vh;
  max-height: 574px;
  &::-webkit-scrollbar {
    width: 0;
  }
  @media (max-width: 768px) {
    max-width: 336px;
    border-radius: 12px;
    padding: 24px;
    max-height: 480px;
  }
`

export const ModalButtonContainer = styled.div`
  position: absolute;
  background-color: rgba(16, 16, 44, 0.7);
  border: 1px solid rgba(73, 158, 255, 0.3);
  top: 0px;
  right: -70px;
  border-radius: 20px;
  width: 57px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    top: -65px;
    right: 0px;
    border-radius: 12px;
    width: 52px;
    height: 52px;
  }
`

export const ModalForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalInputContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  border-radius: 20px;
  padding: 0px;
  margin-bottom: ${({ margin }) => {
    return margin || '26px'
  }};

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const ModalButtonInputContainer = styled.div`
  display: flex;
  gap: 22px;
  align-items: center;
`
export const ModalInputLabel = styled.label`
  box-sizing: border-box;
  display: block;
  color: #f0f0f2;
  position: absolute;
  top: 28px;
  left: 24px;
  height: 100%;
  pointer-events: none;
  border: none;
  transform-origin: 0 0;

  font-size: 12px;

  font-family: 'OpenSans';
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  opacity: 0.5;

  @media (max-width: 768px) {
    font-size: 10px;

    line-height: 15px;
    letter-spacing: 0.5px;
    top: 10px;
    left: 16px;
  }
`

export const ModalInputError = styled.span`
  box-sizing: border-box;
  display: block;
  color: red;
  position: absolute;
  bottom: -22px;
  left: 10px;

  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;
  opacity: 0.5;

  @media (max-width: 768px) {
    font-size: 9px;

    line-height: 15px;
    letter-spacing: 0.5px;
    bottom: -17px;
    left: 4px;
  }
`

export const StyledButtonContainer = styled.div`
  position: absolute;
  top: 29px;
  right: 30px;
  display: flex;
  justify-content: center;
  max-width: 138px;
  height: 48px;
  &:disabled,
  &[disabled] {
    background: transparent;
  }
  @media (max-width: 768px) {
    top: 12px;
    right: 15px;
    max-width: 130px;
    height: 35px;
  }
`

export const StyledButton = styled.button`
  position: relative;
  border: 1px solid rgba(73, 158, 255, 0.3);
  width: 138px;
  background: rgba(16, 16, 44, 0.7);
  border-radius: 6px;
  transform: skew(-16deg, 0);
  max-width: 138px;
  letter-spacing: 0.4px;
  font-family: OpenSans;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background: rgba(72, 157, 254, 0.1);
    backdrop-filter: blur(38px);
    border: none;
    opacity: 0.4;
  }

  @media (max-width: 768px) {
    width: 130px;
    font-size: 11px;
    max-width: 100px;
    line-height: 15px;
  }
`

export const ModalAddress = styled.span`
  font-weight: 700;
`

export const ModalLinkButton = styled.button`
  color: #499eff;
  font-weight: 700;
  text-decoration: none;
  font-size: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
`
