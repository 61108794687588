import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import { useParams, useNavigate } from 'react-router-dom'
import PageContainer from '../../components/PageContainer'
import Spinner from '../../components/Spinner'
import { Button } from '../../components/Button'
import Header from '../../components/Header'
import { getCard } from '../../api'
import ShareIcon from '@mui/icons-material/Share'
import OrderModal from '../../components/OrderModal'
import SwitchNetworkModal from '../SwitchNetworkModal'
import networks from '../../networks.json'
import { UserContext } from '../../contexts/UserContext'

import CardDetailBody from './CardDetailBody'
import CardDetailVideo from './CardDetalVideo.jsx'

import {
  PageWrapper,
  PageHead,
  BackButton,
  HeadShareButtons,
  HeadOpenSeaLink,
  HeadShareLink,
  PageBody,
  TokenPictureWrapper,
  TokenPictureHeadTitle,
  TokenPicture,
  StyledRouterLink,
  CharacterKinds,
  CharacterKindContainer,
  CharacterKind,
  CharacterKindText,
} from './CardDetail.styled'

export default function CardDetail() {
  const navigate = useNavigate()
  const { chainId } = useContext(UserContext)
  const [isModalWindowOpen, setOpenModalWindow] = React.useState(false)
  const [token, setToken] = useState()
  const [isFetch, setIsFetch] = useState(false)
  const [orderIsAdded, setOrderIsAdded] = useState(false)
  const [swtichNetworkModalOpen, setSwitchtNetworkModalOpen] = useState(false)
  const [swtichNetworkModalText, setSwitchtNetworkModalText] = useState(
    'Please swith to correct chain',
  )

  const { tokenId } = useParams()

  const handleOpenModalWindow = () => {
    if (Number(chainId) === token.kind.chain) {
      setOpenModalWindow(true)
    } else {
      setSwitchtNetworkModalText(
        `Please switch to ${
          networks[token?.kind.chain]?.name
        } and login to place an order`,
      )
      setSwitchtNetworkModalOpen(true)
    }
  }
  const handleCloseModalWindow = () => setOpenModalWindow(false)

  function onShare() {
    window.open(
      `https://www.facebook.com/sharer.php?u=${window.location.href}','sharer','status=0,toolbar=0,width=650,height=500`,
    )
  }

  useEffect(() => {
    getCard(tokenId)
      .then((card) => {
        setToken(card)
        setIsFetch(true)
      })
      .catch((err) => {
        console.log(err)
        setIsFetch(true)
      })
  }, [tokenId, orderIsAdded])

  return (
    <>
      <Header />
      <PageContainer>
        {token && token.kind ? (
          <>
            <PageWrapper>
              <PageHead>
                <div>
                  <BackButton onClick={() => navigate(-1)}>
                    <img
                      src={require('../../images/back-arrow.svg').default}
                      alt="back"
                    />
                    <span>Back</span>
                  </BackButton>
                </div>
                <HeadShareButtons>
                  <HeadOpenSeaLink>
                    <img
                      src={require('../../images/opensea-2.svg').default}
                      alt="opensea"
                    />
                  </HeadOpenSeaLink>
                  <HeadShareLink onClick={onShare}>
                    <ShareIcon />
                  </HeadShareLink>
                </HeadShareButtons>
              </PageHead>
              <PageBody>
                <TokenPictureWrapper>
                  {token.kind.tokenType === 'Character' && (
                    <CharacterKinds>
                      <CharacterKindContainer type={token.kind.tokenKind}>
                        <CharacterKind>
                          <CharacterKindText type={token.kind.tokenKind}>
                            {token.kind.tokenKind}
                          </CharacterKindText>
                        </CharacterKind>
                      </CharacterKindContainer>
                      <CharacterKindContainer type={token.kind.tokenRarity}>
                        <CharacterKind>
                          <CharacterKindText type={token.kind.tokenRarity}>
                            {token.kind.tokenRarity}
                          </CharacterKindText>
                        </CharacterKind>
                      </CharacterKindContainer>
                    </CharacterKinds>
                  )}
                  {token.kind.tokenType === 'Character' &&
                  token.kind.videoUri ? (
                    <CardDetailVideo videoUri={token.kind.videoUri} />
                  ) : (
                    <TokenPicture
                      src={token.kind.imageUri}
                      alt="token"
                      width="604"
                      height="667"
                    />
                  )}
                  <TokenPictureHeadTitle>
                    #{token.kind.contractTokenId}
                  </TokenPictureHeadTitle>
                </TokenPictureWrapper>
                <CardDetailBody
                  token={token}
                  handleOpenModalWindow={handleOpenModalWindow}
                  orderIsAdded={orderIsAdded}
                  setSwitchtNetworkModalOpen={setSwitchtNetworkModalOpen}
                  setSwitchtNetworkModalText={setSwitchtNetworkModalText}
                />
              </PageBody>
            </PageWrapper>
            <OrderModal
              isOpen={isModalWindowOpen}
              onClose={handleCloseModalWindow}
              orderIsAdded={orderIsAdded}
              setOrderIsAdded={setOrderIsAdded}
              tokenKind={token.kind.contractTokenId || 0}
              tokenChainId={token.kind.chain || 0}
            />
          </>
        ) : !token && isFetch ? (
          <Box
            sx={{
              width: 320,
              height: 300,
              margin: '0 auto',
              mt: '60px',
              color: 'white',
              display: 'flex',
            }}
          >
            Token not found. Go to the main page to see your tokens{' '}
            <StyledRouterLink to="/">
              <Button>Go</Button>
            </StyledRouterLink>
          </Box>
        ) : (
          <Spinner />
        )}
      </PageContainer>
      <SwitchNetworkModal
        isOpen={swtichNetworkModalOpen}
        onClose={() => setSwitchtNetworkModalOpen(false)}
        info={swtichNetworkModalText}
      />
    </>
  )
}
