import PageContainer from '../../components/PageContainer'
import Header from '../../components/Header'
import {
  ButtonWrapper,
  NotFoundWrapper,
  NotFoundTitle,
  NotFoundText,
  BackButton,
} from './NotFound.styled'

function NotFound() {
  return (
    <>
      <Header />
      <PageContainer>
        <ButtonWrapper>
          <BackButton to={'/'}>
            <img
              src={require('../../images/back-arrow.svg').default}
              alt="back"
            />
            <span>Back</span>
          </BackButton>
        </ButtonWrapper>
        <NotFoundWrapper>
          <NotFoundTitle>404</NotFoundTitle>
          <NotFoundText>Not found</NotFoundText>
        </NotFoundWrapper>
      </PageContainer>
    </>
  )
}

export default NotFound
