import styled from 'styled-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  flex-basis: 300px;
  min-width: 300px;
  top: 100px;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(29, 31, 42, 0.9);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 13;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s ease;

    visibility: ${({ isOpen }) => {
      if (isOpen) {
        return 'visible'
      }
      return 'hidden'
    }};

    opacity: ${({ isOpen }) => {
      if (isOpen) {
        return '1'
      }
      return '0'
    }};
  }
`

export const FilterTitle = styled.h3`
  margin: 6px 0 25px;
  padding: 0;
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    display: none;
  }
`

export const FilterPanel = styled.div`
  padding: 5px 16px;
  border-radius: 20px;
  background: #0f0e2c;
  color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 768px) {
    height: 100%;
    overflow-y: scroll;
  }
`

export const FilterItem = styled.div`
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #ffffff29;
  padding: 22px 0;
  position: relative;
  &:nth-child(7) {
    border: none;
  }
`
export const FilterItemTitle = styled.h4`
  margin: 0;
  padding: 0;
  cursor: pointer;
`

export const FilterItemList = styled.div`
  display: ${({ show }) => {
    if (show) {
      return 'block'
    }
    return 'none'
  }};
  padding: 25px 0 10px;
`

export const FilterSearchInputWrapper = styled.div`
  position: relative;
`
export const FilterSearchInput = styled.input`
  border: none;
  border-radius: 16px;
  padding: 0 12px;
  padding-left: 50px;
  background: #030208;
  height: 48px;
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 100%;
  box-sizing: border-box;
  &:focus-visible {
    outline: 1px solid white;
  }
`

export const FilterPropertyItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`
export const FilterPropertyItemWrapper = styled.div`
  position: relative;
`
export const FilterPropertyItem = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 16px;
  padding: 0 12px;
  background: ${({ isChecked }) => {
    if (isChecked) {
      return '#1c1b38'
    }
    return '#030208'
  }};
  height: 44px;
  color: white;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  transition: background-color 0.5s;

  &:hover {
    background-color: #1c1b38;
  }

  &:active {
    background-color: #1c1b38;
  }

  @media (max-width: 1000px) {
    &:hover {
      background: ${({ isChecked }) => {
        if (isChecked) {
          return '#1c1b38'
        }
        return '#030208'
      }};
    }

    &:active {
      background: ${({ isChecked }) => {
        if (isChecked) {
          return '#1c1b38'
        }
        return '#030208'
      }};
    }
  }
`

export const FilterButton = styled.button`
  display: none;
  position: fixed;
  z-index: 10;
  border: none;
  background: #3970fe;
  background: linear-gradient(
    88.82deg,
    #489dfe 11.9%,
    #3970fe 45.06%,
    #5e51ff 76.01%
  );
  bottom: 20px;
  left: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  @media (max-width: 768px) {
    display: block;
  }
`

export const FilterButtonImg = styled.img`
  position: absolute;
  top: 20px;
  right: 17px;
  height: 24px;
  width: 24px;
`

export const FilterModalButtonsContainer = styled.div`
  padding: 36px 0 20px;
  display: none;
  position: relative;
  gap: 12px;
  margin-top: auto;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 48% 48%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(
      278.44deg,
      rgba(73, 158, 255, 0.35) 42.55%,
      rgba(95, 81, 255, 0.35) 84.23%
    );
  }
`

export const ExpandFilterLessIcon = styled(ExpandLessIcon)`
  position: absolute;
  color: white;
  right: 10px;
`

export const ExpandFilterMoreIcon = styled(ExpandMoreIcon)`
  position: absolute;
  color: white;
  right: 10px;
`
