import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Button'
import Spinner from '../Spinner'
import ModalComponent from '../Modal'
import { UserContext } from '../../contexts/UserContext'
import { getMetarunSwap, getMetarunCollection } from '../../api/contracts'
import { getOwnerCards } from '../../api'
import networks from '../../networks.json'
import { SUPPORTED_CHAINS } from '../../constants'
import { shortenAddress } from '../../utils/shortenAddress'

import {
  ModalMain,
  ModalError,
  BorderStyled as Border,
  ModalList,
  ModalItem,
} from './SwapTicketModal.styled'

import {
  ModalTitle,
  ModalTxText,
  ModalTxLink,
  ModalTextDiv,
} from '../Modal/Modal.styled'

export default function SwapTicketModal({ isOpen, onClose, tokenId }) {
  const navigate = useNavigate()
  const { userAddress, chainId } = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState()
  const [isApproved, setIsApproved] = useState(true)
  const [charactersBefore, setCharactersBefore] = useState(0)

  const [error, setError] = useState('')

  function closeModal() {
    setTxHash()
    setIsLoading(false)
    setError('')
    onClose()
  }

  function getTicketsCount(abortController) {
    getOwnerCards({ userAddress, characterFilter: true })
      .then((res) => {
        const aborted = abortController.signal.aborted
        if (!aborted) {
          setCharactersBefore(res.count)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getAllowance(abortController) {
    const MetarunCollection = getMetarunCollection(chainId)

    await MetarunCollection.isApprovedForAll(
      userAddress,
      networks[chainId].metarunSwapContract.address,
    )
      .then((approve) => {
        const aborted = abortController.signal.aborted
        if (!aborted) {
          setIsApproved(approve)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsApproved(false)
      })
  }

  async function getApprove(e) {
    e.preventDefault()
    setIsLoading(true)
    const MetarunCollection = getMetarunCollection(chainId)
    if (!isApproved) {
      await MetarunCollection.setApprovalForAll(
        networks[chainId].metarunSwapContract.address,
        true,
      )
        .then((tx) => {
          setTxHash(tx.hash)
          tx.wait()
            .then(() => {
              setTxHash()
              setIsApproved(true)
              swapTicket()
            })
            .catch((err) => {
              console.log(err)
              setIsLoading(false)
            })
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    } else {
      swapTicket()
    }
  }

  async function swapTicket() {
    setIsLoading(true)
    setError('')
    const MetarunSwap = getMetarunSwap(chainId)

    MetarunSwap.swapTicketToCharacter(tokenId, { gasLimit: 500000 })
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then((res) => {
            setTimeout(function testTokens() {
              getOwnerCards({ userAddress, characterFilter: true })
                .then((res) => {
                  if (res.count > charactersBefore) {
                    onClose()
                    setIsLoading(false)
                    setTxHash()
                    navigate('/')
                  } else {
                    setTimeout(testTokens, 2000)
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            }, 1000)
          })
          .catch((err) => {
            console.log(err)
            setError(
              (err.data && err.data.message) ||
                err.message ||
                'Something went wrong',
            )
            setIsLoading(false)
            setTxHash()
          })
      })
      .catch((err) => {
        console.log(err)
        setError(
          (err.data && err.data.message) ||
            err.message ||
            'Something went wrong',
        )
        setIsLoading(false)
        setTxHash()
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (userAddress && SUPPORTED_CHAINS.includes(chainId)) {
      getAllowance(abortController)
      getTicketsCount(abortController)
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [userAddress, chainId])

  return (
    <ModalComponent isOpen={isOpen} onClose={closeModal}>
      <ModalTitle margin>Swap Ticket</ModalTitle>
      <ModalMain>
        {isLoading ? (
          <>
            <Spinner size="80px" />
            {!txHash ? (
              <ModalTxText>Await confirmation</ModalTxText>
            ) : (
              <ModalTxLink
                target="_blank"
                href={
                  networks[chainId]
                    ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                    : ''
                }
              >
                TxHash: {shortenAddress(txHash)}
              </ModalTxLink>
            )}
          </>
        ) : (
          <>
            <ModalTextDiv>
              A swap ticket gives you the chance to get one of these
              randomly-generated characters:
            </ModalTextDiv>
            <ModalList>
              <ModalItem>Ignis classic common</ModalItem>
              <ModalItem>Penna classic common</ModalItem>
              <ModalItem>Oro classic common</ModalItem>
            </ModalList>
            <ModalError>{error}</ModalError>

            {!isApproved && (
              <>
                <Border />
                <ModalTextDiv>
                  To approve Metarun to manage your collection tokens (including
                  tickets), first, you need to complete a free (plus gas)
                  transaction. Confirm it in your wallet and keep this modal
                  open!
                </ModalTextDiv>
                <ModalTextDiv>
                  You&apos;ll never have to do this approval again. It only
                  needs to be done once.
                </ModalTextDiv>
              </>
            )}

            <Button
              size="extralong"
              variant="contained"
              onClick={getApprove}
              disabled={
                !networks[chainId] || !networks[chainId].metarunSwapContract
              }
            >
              Swap
            </Button>
          </>
        )}
      </ModalMain>
    </ModalComponent>
  )
}
