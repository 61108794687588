import React, { useState } from 'react'
import { Button } from '../Button'
import PagesContainer from '../PageContainer'
import StartGameModal from '../StartGameModal'

import {
  PagesMenuContainer,
  PagesMenuList,
  PagesMenuItem,
  PagesMenuItemLink,
  PagesMenuItemTextMobile,
  PagesMenuItemTextDesktop,
} from './PagesMenu.styled'

function PagesMenu() {
  const [gameModalOpen, setGameModalOpen] = useState(false)
  const currentLocation = {
    dashboard: true,
  }

  function handleCloseGameModal() {
    setGameModalOpen(false)
  }

  return (
    <>
      <PagesContainer>
        <PagesMenuContainer>
          <PagesMenuList>
            <PagesMenuItem
              hidden={false}
              current={currentLocation.dashboard.toString()}
              to="/"
            >
              Marketplace
            </PagesMenuItem>
            <PagesMenuItemLink
              href="https://mysterybox.metarun.game/"
              target="_blank"
            >
              <PagesMenuItemTextDesktop>
                Mystery Box Sale
              </PagesMenuItemTextDesktop>
              <PagesMenuItemTextMobile>MB Sale</PagesMenuItemTextMobile>
            </PagesMenuItemLink>
            <PagesMenuItemLink
              href="https://stake.metarun.game/"
              target="_blank"
            >
              Staking
            </PagesMenuItemLink>
          </PagesMenuList>
          <Button
            variant="contained"
            size="install"
            onClick={() => {
              setGameModalOpen(true)
            }}
          >
            Install Game
          </Button>
        </PagesMenuContainer>
      </PagesContainer>
      <StartGameModal isOpen={gameModalOpen} onClose={handleCloseGameModal} />
    </>
  )
}

export default PagesMenu
