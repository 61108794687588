import styled from 'styled-components'

export const List = styled.div`
  position: absolute;
  z-index: 2;
  border-radius: 20px;
  width: 100%;
  margin-top: 10px;
  max-width: 200px;
  min-width: 100px;
  background: #0f0e2c;
  top: 56px;
  flex-direction: column;
  display: ${({ show }) => {
    if (show) {
      return 'flex'
    }
    return 'none'
  }};

  @media (max-width: 768px) {
    top: 40px;
  }
`

export const ListItem = styled.p`
  margin: 0;
  color: white;
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  &:first-child {
    border-bottom: 1px solid #ffffff29;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 18px;
  }
`
