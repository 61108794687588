import React, { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Summary from '../Summary'
import DuelRuns from './DuelRuns'
import CompetitiveRuns from './CompetitiveRuns'

import {
  RunsContainer,
  RunsLink,
  RunsNavigation,
  RunsLinks,
  RunsSummary,
  SummaryItemButtonsContainer,
  SummaryItemButton,
  SummaryItem,
  SummaryItems,
  ItemImg,
  HiddenText,
} from './Runs.styled'

export default function Runs({ summary, summaryIsLoading }) {
  const location = useLocation()

  const [currentLocation, setCurrentLocation] = useState({
    duel: false,
    competitive: false,
  })

  const [todayRuns, setTodayRuns] = useState(false)

  useEffect(() => {
    if (location.pathname.includes('/duel')) {
      setCurrentLocation({
        duel: true,
        competitive: false,
      })
    } else if (location.pathname.includes('/competitive')) {
      setCurrentLocation({
        duel: false,
        competitive: true,
      })
    } else {
      setCurrentLocation({
        duel: false,
        competitive: false,
      })
    }
  }, [location])

  return (
    <RunsContainer>
      <Summary summary={summary} summaryIsLoading={summaryIsLoading} />
      <RunsNavigation>
        <RunsLinks>
          <RunsLink
            to="/profile/runs/duel"
            current={currentLocation.duel.toString()}
          >
            Duel Run
          </RunsLink>
          <RunsLink
            to="/profile/runs/competitive"
            current={currentLocation.competitive.toString()}
          >
            Competitive Runs
          </RunsLink>
        </RunsLinks>
        {currentLocation.duel && (
          <RunsSummary>
            <SummaryItemButtonsContainer>
              <SummaryItemButton
                active={!todayRuns}
                onClick={() => setTodayRuns(false)}
              >
                Total<HiddenText> Runs</HiddenText>
              </SummaryItemButton>
              <SummaryItemButton
                active={todayRuns}
                onClick={() => setTodayRuns(true)}
              >
                Today<HiddenText> Runs</HiddenText>
              </SummaryItemButton>
            </SummaryItemButtonsContainer>
            <SummaryItems>
              <SummaryItem>
                <ItemImg
                  src={require('../../images/GreenMedal.svg').default}
                  alt="medal"
                  width="23px"
                  height="28px"
                />
                {!todayRuns
                  ? summary.totalWinsDuel || 0
                  : summary.todayWinsDuel || 0}
              </SummaryItem>
              <SummaryItem>
                <ItemImg
                  src={require('../../images/Lose.svg').default}
                  alt="medal"
                  width="23px"
                  height="28px"
                />
                {!todayRuns
                  ? summary.totalLosesDuel || 0
                  : summary.todayLosesDuel || 0}
              </SummaryItem>
            </SummaryItems>
          </RunsSummary>
        )}
        {currentLocation.competitive && (
          <RunsSummary>
            <SummaryItemButtonsContainer>
              <SummaryItemButton
                active={!todayRuns}
                onClick={() => setTodayRuns(false)}
              >
                Total<HiddenText> Medals</HiddenText>
              </SummaryItemButton>
              <SummaryItemButton
                active={todayRuns}
                onClick={() => setTodayRuns(true)}
              >
                Today<HiddenText> Medals</HiddenText>
              </SummaryItemButton>
            </SummaryItemButtonsContainer>
            <SummaryItems>
              <SummaryItem>
                <ItemImg
                  src={require('../../images/GoldMedal.svg').default}
                  alt="medal"
                  width="23px"
                  height="28px"
                />
                {!todayRuns
                  ? summary.totalWinsCompetitive || 0
                  : summary.todayWinsCompetitive || 0}
              </SummaryItem>
              <SummaryItem>
                <ItemImg
                  src={require('../../images/SilverMedal.svg').default}
                  alt="medal"
                  width="23px"
                  height="28px"
                />
                {!todayRuns
                  ? summary.totalSecondPlaces || 0
                  : summary.todaySecondPlaces || 0}
              </SummaryItem>
              <SummaryItem>
                <ItemImg
                  src={require('../../images/BronzeMedal.svg').default}
                  alt="medal"
                  width="23px"
                  height="28px"
                />
                {!todayRuns
                  ? summary.totalLosesCompetitive || 0
                  : summary.todayLosesCompetitive || 0}
              </SummaryItem>
            </SummaryItems>
          </RunsSummary>
        )}
      </RunsNavigation>
      <Routes>
        <Route exact path="/duel" element={<DuelRuns today={todayRuns} />} />
        <Route
          exact
          path="/competitive"
          element={<CompetitiveRuns today={todayRuns} />}
        />
        <Route path="*" element={<h3>Please select runs</h3>} />
      </Routes>
    </RunsContainer>
  )
}
