const host = process.env.REACT_APP_API_HOST

export async function getRewards({ userAddress, page }) {
  const pageParam = page ? `page=${page}&` : ''
  return await fetch(`${host}/rewards/?${pageParam}user=${userAddress}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
