export function getAttributeName(attribute) {
  if (!attribute) return attribute
  const newStr = attribute[0].toUpperCase() + attribute.slice(1)
  return newStr.split(/(?=[A-Z])/).join(' ')
}

export function getOrderStatus(orderCode) {
  switch (orderCode) {
    case 0:
      return 'Active'
    case 1:
      return 'Completed'
    case 2:
      return 'Cancelled'
    case 3:
      return 'Expired'
    default:
      return 'Not Executed'
  }
}

export function getKindImage(kind) {
  switch (kind) {
    case 'Oro':
      return require('../images/WhiteFighter.svg').default
    case 'Ignis':
      return require('../images/WhiteCraftsman.svg').default
    case 'Penna':
      return require('../images/WhiteCharacter.svg').default
    case 'Pet':
      return require('../images/WhitePet.svg').default
    case 'Skin':
      return require('../images/WhiteSkin.svg').default
    case 'Mana':
      return require('../images/WhiteMana.svg').default
    case 'Speed':
      return require('../images/WhiteSpeedFilter.svg').default
    case 'Health':
      return require('../images/WhiteHealth.svg').default
    case 'Collision damage':
      return require('../images/WhiteDamage.svg').default
    case 'Artifact':
      return require('../images/WhiteArtifact.svg').default
    case 'Ticket':
      return require('../images/WhiteTicketIcon.svg').default
    case 'Mystery Box':
      return require('../images/MysteryBox.svg').default
    default:
      return require('../images/WhiteOpal.svg').default
  }
}

export function getTransactionImage(status) {
  switch (status) {
    case 0:
      return require('../images/Process.svg').default
    case 1:
      return require('../images/Success.svg').default
    default:
      return require('../images/Error.svg').default
  }
}

export function getNetworkIcon(chain) {
  switch (chain) {
    case 97:
      return require('../images/BNB.svg').default
    case 56:
      return require('../images/BNB.svg').default
    case 137:
      return require('../images/polygon-icon.svg').default
    case 80001:
      return require('../images/polygon-icon.svg').default
    default:
      return require('../images/Error.svg').default
  }
}
