import styled from 'styled-components'

const media = '768px'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  color: #fff;
  background: #0f0e2b;
  border-radius: 12px;
`

export const Title = styled.button`
  font-family: 'OpenSans';
  display: block;
  text-align: left;
  width: 100%;
  border: none;
  position: relative;
  cursor: pointer;
  padding: 12px 44px;
  background: none;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;

  @media (min-width: ${media}) {
    padding: 16px 48px;
    font-size: 16px;
  }
  &:disabled,
  &[disabled] {
    cursor: auto;
  }
`

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);

  svg {
    width: 100%;
    height: 100%;
  }
`

export const Arrow = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  svg {
    width: 100%;
    height: 100%;
  }
`

export const Content = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 16px;
  box-sizing: border-box;
  transition: height 0.2s;
`
