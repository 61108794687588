import styled from 'styled-components'

export const FormContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(29, 31, 42, 0.9);

  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;

  visibility: ${({ isOpen }) => {
    if (isOpen) {
      return 'visible'
    }
    return 'hidden'
  }};

  opacity: ${({ isOpen }) => {
    if (isOpen) {
      return '1'
    }
    return '0'
  }};

  @media (max-width: 768px) {
    align-items: start;
    height: 100%;
    overflow-y: scroll;
  }
`

export const MainForm = styled.form`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(73, 158, 255, 0.3);
  border-radius: 20px;
  max-width: 1020px;
  padding: 40px;
  background: rgba(16, 16, 44, 0.7);
  margin: 0 24px;

  @media (max-width: 990px) {
    padding: 24px;
    margin: 0 12px;
    margin-bottom: 24px;
    margin-top: 70px;
  }
`

export const FormInputContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-grow: 1;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => {
    switch (marginBottom) {
      case 'small':
        return '24px'
      case 'none':
        return '0px'
    }
    return '60px'
  }};
  position: relative;
  @media (max-width: 990px) {
    width: 288px;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${({ marginBottom }) => {
      switch (marginBottom) {
        case 'small':
          return '12px'
        case 'none':
          return '12px'
      }
      return '12px'
    }};
    gap: 12px;
  }
`

export const FormInputLabel = styled.label`
  box-sizing: border-box;
  transform: ${({ variant }) => {
    switch (variant) {
      case 'filled':
        return 'translate(0, 12px) scale(0.5)'
    }
    return 'translate(0, 36px) scale(1)'
  }};
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  display: block;
  color: #f0f0f2;
  color: ${({ disabled }) => {
    if (disabled) {
      return '#a3a4a9'
    }
    return '#f0f0f2'
  }};

  position: absolute;
  left: 24px;
  pointer-events: none;
  border: 1px solid transparent;
  font-size: 24px;
  font-weight: ${({ variant }) => {
    switch (variant) {
      case 'filled':
        return '400'
    }
    return '700'
  }};
  line-height: 36px;
  letter-spacing: 0.4px;
  opacity: 0.5;

  @media (max-width: 990px) {
    font-size: 16px;
    line-height: 32px;
    left: 16px;
    transform: ${({ variant }) => {
      switch (variant) {
        case 'filled':
          return 'translate(0, 4px) scale(0.5)'
      }
      return 'translate(0, 14px) scale(1)'
    }};
  }
`

export const FormInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 398px;
  height: 104px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.4px;
  color: white;
  background-color: #1d1f2a;
  overflow: hidden;
  background-color: ${({ invalid }) => {
    if (invalid) {
      return '#ED49151A'
    }
    return 'inherit'
  }};
  background-clip: padding-box;
  border: 1px solid #2f313b;
  border-color: ${({ invalid }) => {
    if (invalid) {
      return '#322328'
    }
    return '#2f313b'
  }};
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;

  &:focus {
    color: white;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(158, 158, 158, 0.25);
  }
  &:disabled {
    background-color: #262a35;
    color: #a3a4a9;
  }

  &:focus-within {
    label {
      transform: translate(0, 12px) scale(0.5);
      color: #f0f0f2;
      opacity: 0.5;
    }
  }

  @media (max-width: 990px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    width: 288px;
    box-sizing: border-box;
    height: 60px;
    &:focus-within {
      label {
        transform: translate(0, 4px) scale(0.5);
      }
    }
  }
`

export const Input = styled.input`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  font-family: 'OpenSans';
  letter-spacing: 0.4px;
  padding: 35px 24px;
  background-color: #1d1f2a;
  background-color: ${({ invalid }) => {
    if (invalid) {
      return '#ED49151A'
    }
    return 'inherit'
  }};
  border: none;
  outline: none;
  color: white;
  padding: 36px 24px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    background-color: #262a35b8;
  }

  @media (max-width: 990px) {
    width: 256px;
    height: 60px;
    padding: 0 16px;
    font-size: 18px;
    line-height: 27px;
  }
`

export const ButtonContainer = styled.div`
  width: 820px;
  margin: 36px auto 0;
  border-top: 1px solid #2f313b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 990px) {
    margin-top: 10px;
    width: 288px;
  }
`

export const FormTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0 0 16px;
  text-transform: uppercase;
  margin-bottom: 24px;
`

export const FormError = styled.p`
  margin: 20px auto;
  color: red;

  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.4px;

  @media (max-width: 768px) {
    font-size: 9px;
    line-height: 15px;
    letter-spacing: 0.5px;
    margin: 8px auto;
  }
`

export const FormText = styled.p`
  margin: 0 0 16px;
  width: 100%;
  color: white;

  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`

export const FormButtonContainer = styled.div`
  position: absolute;
  background-color: rgba(16, 16, 44, 0.7);
  border: 1px solid rgba(73, 158, 255, 0.3);
  top: 0px;
  right: -70px;
  border-radius: 20px;
  width: 57px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 1050px) {
    top: -65px;
    right: 0px;
    border-radius: 12px;
    width: 52px;
    height: 52px;
  }
`

export const FormCloseButton = styled.img`
  width: 14px;
  height: 14px;
`
