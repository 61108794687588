import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Filter from '../Filter'
import Card from '../Card'
import Spinner from '../Spinner'
import BuyBoxCard from '../BuyBoxCard'
import BuyCharacterCard from '../BuyCharacterCard'
import { getOwnerCards, getAllTokens } from '../../api'
import { UserContext } from '../../contexts/UserContext'
import {
  CHARACTERS,
  HALLOWEEN_CHARACTERS,
  CHRISTMAS_CHARACTERS,
} from '../../constants/Characters'

import {
  MainContainer,
  MainPanel,
  SortingPanel,
  ButtonsWrapper,
  SortingButton,
  SortingList,
  SortingListItem,
  CardsGrid,
  PanelTextContainer,
  PanelText,
  PanelMainText,
  SortingWrapper,
  ButtonImg,
  HiddenText,
  GalleryMain,
  GalleryMainText,
} from './CardsContainer.styled'

export default function CardsContainer({
  searchValue,
  kindFilters,
  setKindFilters,
  setSearchValue,
  showMyTokens,
  setShowMyTokens,
}) {
  const { userAddress } = useContext(UserContext)

  const [ordering, setOrdering] = useState('-last_price')
  const [isLoading, setIsLoading] = useState(true)

  const [ownerCardsData, setOwnerCardsData] = useState([])
  const [isOwnerCardsLoading, setIsOwnerCardsLoading] = useState(false)
  const [currentOwnerPage, setCurrentOwnerPage] = useState(2)
  const [numberOfTokensOwner, setNumberOfTokensOwner] = useState(0)
  const [hasMoreOwnerTokens, setHasMoreOwnerTokens] = useState(false)

  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false)

  const [saleFilters, setSaleFilters] = useState({
    Character: false,
    Kind: false,
    Rarity: false,
  })
  const [levelFilters, setLevelFilters] = useState()

  const minRange = 0
  const maxRange = 100
  const [minLevel, setMinLevel] = useState(minRange)
  const [maxLevel, setMaxLevel] = useState(maxRange)

  const sorting = useRef(null)

  const [showSorting, setShowSorting] = useState(false)
  const [sortingName, setSortingName] = useState('Price: High to Low')

  const [allTokensData, setAllTokensData] = useState({})

  function handleFilterModal() {
    setFilterModalIsOpen(!filterModalIsOpen)
  }

  function handleSortingList() {
    setShowSorting(!showSorting)
  }

  const searchHandler = (e) => {
    if (Number(e.target.value) || e.target.value === '') {
      setSearchValue(e.target.value)
    }
  }

  function fetchAllTokens() {
    getAllTokens()
      .then(({ results }) => {
        setAllTokensData(results)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function fetchInitialOwnerCards() {
    getOwnerCards({
      userAddress,
      kindFilters,
      ordering,
      level: levelFilters,
      searchValue,
    })
      .then((ownerCards) => {
        setNumberOfTokensOwner(ownerCards.count)
        setOwnerCardsData(ownerCards.results)

        setHasMoreOwnerTokens(ownerCards.results.length < ownerCards.count)

        setIsLoading(false)
        setIsOwnerCardsLoading(false)
        setCurrentOwnerPage(2)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  function fetchOwnerCards() {
    if (userAddress) {
      setHasMoreOwnerTokens(false)
      setCurrentOwnerPage(2)
      setIsOwnerCardsLoading(true)
      fetchInitialOwnerCards()
    } else {
      setIsLoading(false)
    }
  }

  function resetRange() {
    setMinLevel(minRange)
    setMaxLevel(maxRange)
    setLevelFilters()
  }

  useEffect(() => {
    fetchOwnerCards()
  }, [userAddress, ordering, levelFilters, kindFilters])

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      fetchOwnerCards()
    }, 500)

    return () => {
      clearInterval(searchTimeout)
    }
  }, [searchValue])

  async function fetchMoreOwnerCards() {
    if (ownerCardsData.length >= numberOfTokensOwner) {
      setHasMoreOwnerTokens(false)
      return
    }

    if (ownerCardsData.length < numberOfTokensOwner) {
      await getOwnerCards({
        ordering,
        page: currentOwnerPage,
        userAddress,
        kindFilters,
        level: levelFilters,
        searchValue,
      }).then(({ results }) => {
        setCurrentOwnerPage((prev) => prev + 1)
        setOwnerCardsData((prevValue) => {
          return prevValue.concat(results)
        })
      })
    }
  }

  const handleCloseMenu = useCallback((event) => {
    if (sorting.current) {
      if (
        !Array.from(sorting.current.getElementsByTagName('*')).includes(
          event.target,
        )
      ) {
        setShowSorting(false)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('click', handleCloseMenu)
    return () => {
      window.removeEventListener('click', handleCloseMenu)
    }
  }, [handleCloseMenu])

  useEffect(() => {
    setSaleFilters({
      Character: kindFilters.Ignis || kindFilters.Oro || kindFilters.Penna,
      Rarity: kindFilters.Common || kindFilters.Rare || kindFilters.Mythical,
      Kind: kindFilters.Classic || kindFilters.Epic || kindFilters.Legendary,
      Another: kindFilters.Ticket || kindFilters.MysteryBox,
    })
  }, [kindFilters])

  useEffect(() => {
    fetchAllTokens()
  }, [])

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <MainContainer>
          <Filter
            setKindFilters={setKindFilters}
            minLevel={minLevel}
            maxLevel={maxLevel}
            handleFilterModal={handleFilterModal}
            searchHandler={searchHandler}
            resetRange={resetRange}
            filterModalIsOpen={filterModalIsOpen}
            searchValue={searchValue}
            kindFilters={kindFilters}
            minRange={minRange}
            maxRange={maxRange}
            setMaxLevel={setMaxLevel}
            setMinLevel={setMinLevel}
            setLevelFilters={setLevelFilters}
            levelFilters={levelFilters}
          />
          <MainPanel>
            <SortingPanel ref={sorting}>
              <PanelTextContainer>
                <PanelMainText
                  active={!showMyTokens}
                  onClick={() => setShowMyTokens(false)}
                >
                  Tokens for sale
                </PanelMainText>
                <PanelMainText
                  active={showMyTokens}
                  onClick={() => setShowMyTokens(true)}
                >
                  My tokens{' '}
                  <PanelText active={showMyTokens}>
                    (<HiddenText>You have </HiddenText>
                    {numberOfTokensOwner === 1
                      ? '1 item'
                      : numberOfTokensOwner + ' items'}
                    )
                  </PanelText>
                </PanelMainText>
              </PanelTextContainer>
              <ButtonsWrapper>
                <SortingWrapper>
                  <SortingButton onClick={handleSortingList}>
                    <ButtonImg
                      src={require('../../images/Sorting.svg').default}
                      alt="sorting"
                      width="18"
                      height="18"
                    />
                    {sortingName}
                    <ExpandMoreIcon
                      sx={{
                        position: 'absolute',
                        color: 'white',
                        right: '20px',
                      }}
                    />
                  </SortingButton>
                  <SortingList show={showSorting}>
                    <FormGroup>
                      <SortingListItem
                        onClick={() => {
                          setOrdering('-last_price')
                          setSortingName('Price: High to Low')
                        }}
                      >
                        Price: High to Low
                      </SortingListItem>
                      <SortingListItem
                        onClick={() => {
                          setOrdering('last_price')
                          setSortingName('Price: Low to High')
                        }}
                      >
                        Price: Low to High
                      </SortingListItem>
                    </FormGroup>
                  </SortingList>
                </SortingWrapper>
              </ButtonsWrapper>
            </SortingPanel>
            {showMyTokens ? (
              isOwnerCardsLoading ? (
                <Spinner />
              ) : ownerCardsData && ownerCardsData.length === 0 ? (
                <GalleryMain>
                  <GalleryMainText>
                    You don&apos;t have any token yet.
                  </GalleryMainText>{' '}
                </GalleryMain>
              ) : (
                <InfiniteScroll
                  style={{ overflow: 'hidden' }}
                  dataLength={ownerCardsData.length}
                  next={fetchMoreOwnerCards}
                  hasMore={hasMoreOwnerTokens}
                  loader={<Spinner />}
                >
                  <CardsGrid>
                    {ownerCardsData &&
                      ownerCardsData.map((_, index) => (
                        <Grid item key={index} sx={{ margin: '0 auto' }}>
                          <Card cardData={ownerCardsData[index]} ownersCard />
                        </Grid>
                      ))}
                  </CardsGrid>
                </InfiniteScroll>
              )
            ) : (!saleFilters.Character &&
                kindFilters.Ticket &&
                !kindFilters.MysteryBox) ||
              (saleFilters.Another &&
                !saleFilters.Character &&
                (saleFilters.Kind || saleFilters.Rarity)) ? (
              <GalleryMain>
                <GalleryMainText>Not found.</GalleryMainText>{' '}
              </GalleryMain>
            ) : (
              <CardsGrid>
                <BuyBoxCard
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.MysteryBox
                      : true) &&
                    !saleFilters.Rarity &&
                    !saleFilters.Kind
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[0]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[1]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[2]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[3]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[4]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[5]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[6]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[7]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[8]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[9]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[10]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[11]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[12]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[13]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[14]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[15]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[16]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[17]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[18]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[19]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[20]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[21]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[22]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[23]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Rare : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[24]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[25]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHARACTERS[26]}
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[0]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[1]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[2]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[3]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[4]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[5]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[6]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[7]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={CHRISTMAS_CHARACTERS[8]}
                  christmas
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Mythical : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[0]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[1]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[2]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Legendary : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[3]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[4]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[5]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Epic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[6]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Ignis
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[7]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Penna
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
                <BuyCharacterCard
                  attributes={HALLOWEEN_CHARACTERS[8]}
                  halloween
                  activeOrdersNumber={allTokensData}
                  display={
                    (saleFilters.Character || saleFilters.Another
                      ? kindFilters.Oro
                      : true) &&
                    (saleFilters.Rarity ? kindFilters.Common : true) &&
                    (saleFilters.Kind ? kindFilters.Classic : true)
                  }
                />
              </CardsGrid>
            )}
          </MainPanel>
        </MainContainer>
      )}
    </>
  )
}
