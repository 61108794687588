import { Routes, Route } from 'react-router-dom'
import CardDetail from '../../components/CardDetail'
import RevealBox from '../../components/RevealBox'

export default function CardDetailPage() {
  return (
    <>
      <Routes>
        <Route exact path="/reveal" element={<RevealBox />} />
        <Route path="*" element={<CardDetail />} />
      </Routes>
    </>
  )
}
