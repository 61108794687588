import React, { useState, useContext } from 'react'
import networks from '../../../networks.json'
import { UserContext } from '../../../contexts/UserContext'
import BuyOrderModal from '../../BuyOrderModal'

import { BuyButton } from '../CardDetail.styled'
export default function BuyOrderButton({
  orderInformation,
  allTokensBefore,
  token,
  setSwitchtNetworkModalOpen,
  setSwitchtNetworkModalText,
}) {
  const { accessToken, chainId, email } = useContext(UserContext)
  const [buyModalOpen, setBuyModalOpen] = useState(false)

  const handleOpenModal = () => {
    if (token.kind.chain !== Number(chainId)) {
      setSwitchtNetworkModalText(
        `Please switch to ${networks[token.kind.chain]?.name} and login to buy`,
      )
      setSwitchtNetworkModalOpen(true)
      return
    }
    setBuyModalOpen(true)
  }
  const handleCloseModal = () => setBuyModalOpen(false)

  return (
    <>
      <BuyOrderModal
        isOpen={buyModalOpen}
        onClose={handleCloseModal}
        orderInformation={orderInformation}
        allTokensBefore={allTokensBefore}
        token={token}
      />
      {orderInformation && (
        <BuyButton
          onClick={handleOpenModal}
          disabled={!token || !accessToken || !email}
        >
          Buy now
        </BuyButton>
      )}
    </>
  )
}
