import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '../Button'
import Spinner from '../Spinner'
import PageContainer from '../PageContainer'
import { UserContext } from '../../contexts/UserContext'
import { getMysteryBoxReveal, getMetarunCollection } from '../../api/contracts'
import { getOwnerCards, getCard } from '../../api'
import networks from '../../networks.json'
import { SUPPORTED_CHAINS } from '../../constants'
import SwitchNetworkModal from '../SwitchNetworkModal'

import { shortenAddress } from '../../utils/shortenAddress'

import {
  RevealWrapper,
  RevealTitleWrapper,
  RevealContainer,
  RevealMain,
  RevealTitle,
  RevealText,
  RevealTxText,
  RevealTxLink,
  RevealError,
  RevealVideo,
  RevealImage,
  RevealImageContainer,
  RevealImageHead,
  BackButton,
  RevealKindContainer,
  RevealKind,
  RevealKindText,
  RevealSubtitle,
  RevealSubtitleSpan,
  RevealHiddenContainer,
} from './RevealBox.styled'

export default function RevealBox() {
  const { userAddress, chainId } = useContext(UserContext)
  const { tokenId } = useParams()

  const [token, setToken] = useState()
  const [newToken, setNewToken] = useState()
  const [video, setVideo] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState()
  const [isApproved, setIsApproved] = useState(true)
  const [openSwitchModal, setOpenSwitchModal] = useState(false)

  const [displayTokenImg, setDisplayTokenImg] = useState(false)

  const [error, setError] = useState('')

  useEffect(() => {
    getCard(tokenId)
      .then((token) => {
        setToken(token)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [tokenId])

  const reveal = (e) => {
    if (+chainId !== token.kind.chain) {
      setOpenSwitchModal(true)
    } else getApprove(e)
  }


  async function getAllowance(abortController) {
    const MetarunCollection = getMetarunCollection(chainId)

    await MetarunCollection.isApprovedForAll(
      userAddress,
      networks[chainId].mysteryBoxRevealContract.address,
    )
      .then((approve) => {
        const aborted = abortController.signal.aborted
        if (!aborted) {
          setIsApproved(approve)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsApproved(false)
      })
  }

  async function getApprove(e) {
    e.preventDefault()
    setIsLoading(true)
    const MetarunCollection = getMetarunCollection(chainId)
    if (!isApproved) {
      await MetarunCollection.setApprovalForAll(
        networks[chainId].mysteryBoxRevealContract.address,
        true,
      )
        .then((tx) => {
          setTxHash(tx.hash)
          tx.wait()
            .then(() => {
              setIsApproved(true)
              revealMysteryBox()
            })
            .catch((err) => {
              console.log(err)
              setIsLoading(false)
            })
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    } else {
      revealMysteryBox()
    }
  }

  async function revealMysteryBox() {
    setIsLoading(true)
    setError('')
    const MysteryBoxReveal = getMysteryBoxReveal(chainId)

    MysteryBoxReveal.revealMysteryBox(token.kind.contractTokenId, {
      gasLimit: 500000,
    })
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then((res) => {
            const newTokenId = res.events[2].args[2].toString()

            setVideo(true)
            setTimeout(function testTokens() {
              getOwnerCards({ searchValue: newTokenId })
                .then((res) => {
                  if (res.count > 0) {
                    setNewToken(res.results[0])
                    setIsLoading(false)
                    setTxHash()
                  } else {
                    setTimeout(testTokens, 2000)
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            }, 1000)
          })
          .catch((err) => {
            console.log(err)
            setError(
              (err.data && err.data.message) ||
                err.message ||
                'Something went wrong',
            )
            setIsLoading(false)
            setTxHash()
          })
      })
      .catch((err) => {
        console.log(err)
        setError(
          (err.data && err.data.message) ||
            err.message ||
            'Something went wrong',
        )
        setIsLoading(false)
        setTxHash()
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (userAddress && SUPPORTED_CHAINS.includes(chainId)) {
      getAllowance(abortController)
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [userAddress, chainId])

  return (
    <>
      <PageContainer>
        <RevealWrapper>
          <RevealContainer>
            <RevealTitleWrapper>
              <RevealTitle>Reveal Mystery Box</RevealTitle>
              <BackButton to={'/'}>back</BackButton>
            </RevealTitleWrapper>
            {token && token.kind.contractTokenName === 'Mystery Box' ? (
              isLoading ? (
                <RevealMain type="spinner">
                  <Spinner size="80px" />
                  {!txHash ? (
                    <RevealTxText>Await confirmation</RevealTxText>
                  ) : (
                    <RevealTxLink
                      target="_blank"
                      href={
                        networks[chainId]
                          ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                          : ''
                      }
                    >
                      TxHash: {shortenAddress(txHash)}
                    </RevealTxLink>
                  )}
                </RevealMain>
              ) : newToken && newToken.kind ? (
                <RevealMain type="image">
                  {video && (
                    <RevealVideo
                      url={[
                        { src: '../../video/Reveal.mp4', type: 'video/mp4' },
                      ]}
                      playing
                      volume={0}
                      muted
                      onStart={() => {
                        setDisplayTokenImg(true)
                      }}
                      onEnded={() => {
                        setVideo(false)
                      }}
                      playsinline
                    />
                  )}
                  <RevealHiddenContainer display={displayTokenImg}>
                    <RevealImageContainer>
                      <RevealImageHead>
                        <RevealKindContainer type={newToken.kind.tokenKind}>
                          <RevealKind>
                            <RevealKindText type={newToken.kind.tokenKind}>
                              {newToken.kind.tokenKind}
                            </RevealKindText>
                          </RevealKind>
                        </RevealKindContainer>
                        <RevealKindContainer type={newToken.kind.tokenRarity}>
                          <RevealKind>
                            <RevealKindText type={newToken.kind.tokenRarity}>
                              {newToken.kind.tokenRarity}
                            </RevealKindText>
                          </RevealKind>
                        </RevealKindContainer>
                      </RevealImageHead>
                      <RevealImage src={newToken.kind.imageUri} width="200px" />
                    </RevealImageContainer>
                    <RevealSubtitle>
                      Congratulations! Your Token:{' '}
                      <RevealSubtitleSpan>
                        {newToken.kind.contractTokenName}
                      </RevealSubtitleSpan>
                    </RevealSubtitle>
                  </RevealHiddenContainer>
                </RevealMain>
              ) : (
                <RevealMain type={!isApproved ? 'approval' : ''}>
                  <RevealText>
                    Reveal a box gives an 88% chance of getting a classic common
                    character and a 12% chance of getting a higher amount NFT.
                    This means that out of every 100 boxes there will be 12 NFTs
                    that will be expensive.
                  </RevealText>
                  {!isApproved && (
                    <>
                      <RevealText>
                        To approve Metarun to manage your collection
                        tokens(including mystery boxes), you must first complete
                        a free (excluding Gas) transaction. Please confirm this
                        in your wallet and keep this Reveal open!
                      </RevealText>
                      <RevealText>
                        You&apos;ll never have to do this approval again, it
                        needs to be done once.
                      </RevealText>
                    </>
                  )}
                  <RevealError>{error}</RevealError>
                  <div>
                    <Button size="reveal" variant="contained" onClick={reveal}>
                      Reveal
                    </Button>
                  </div>
                </RevealMain>
              )
            ) : (
              <RevealMain>
                <RevealText>Reveal not available </RevealText>
              </RevealMain>
            )}
          </RevealContainer>
        </RevealWrapper>
      </PageContainer>
      <SwitchNetworkModal
        isOpen={openSwitchModal}
        onClose={() => setOpenSwitchModal(false)}
        chain={token?.kind.chain}
      />
    </>
  )
}
