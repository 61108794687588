import styled from 'styled-components'

export const CharactersContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  margin: 0px auto;
`

export const TicketContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  margin: 0 0 60px;
  @media (max-width: 1203px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto 32px;
    align-items: center;
    max-width: 336px;
    gap: 16px;
  }
`

export const TicketCardContainer = styled.div`
  max-width: 330px;
  @media (max-width: 768px) {
    margin: 0;
    margin-left: -8px;
    padding: 0;
  }
`

export const TicketInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
  max-width: 672px;
  width: 100%;
  box-sizing: border-box;
`

export const RewardInfoContainer = styled.div`
  display: flex;
  gap: 16px;
  max-width: 672px;
  width: 100%;
  box-sizing: border-box;
`

export const RewardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ variant }) => {
    switch (variant) {
      case 'win':
        return 'rgba(57, 221, 136, 0.1)'
      case 'lose':
        return 'rgba(237, 73, 21, 0.1)'
    }
    return 'white'
  }};
  border-radius: 20px;
  backdrop-filter: blur(38px);
  padding: 24px 0px;
  max-width: 328px;
  width: 100%;
  box-sizing: border-box;
  min-width: 160px;
  margin: 0;
  @media (max-width: 768px) {
    padding: 24px 3px 31px;
    min-height: 196px;
    border-radius: 12px;
  }
`

export const RewardIconContainer = styled.div`
  border: ${({ variant }) => {
    switch (variant) {
      case 'win':
        return '1px solid #39DD88'
      case 'lose':
        return '1px solid #ED4915'
    }
    return '1px solid white'
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 20px;
  width: 56px;
  height: 56px;
  margin-bottom: 16px;
`

export const RewardIcon = styled.img`
  width: 24px;
  height: ${({ variant }) => {
    switch (variant) {
      case 'win':
        return '28px'
      case 'lose':
        return '24px'
    }
    return '24px'
  }};
`

export const RewardText = styled.p`
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${({ variant }) => {
    switch (variant) {
      case 'win':
        return '#39DD88;'
      case 'lose':
        return '#ED4915'
    }
    return 'white'
  }};
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 6px;
  }
`

export const InfoMainText = styled.h1`
  margin: 0;
  font-style: italic;
  font-weight: 700;
  font-size: 32px;
  line-height: 51px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 38px;
  }
`

export const RunsInfoContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const RunsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(72, 157, 254, 0.1);
  backdrop-filter: blur(38px);
  border-radius: 20px;
  padding: 16px 29px;
  width: stretch;
  @media (max-width: 768px) {
    padding: 16px 2px;
    min-height: 63px;
    border-radius: 12px;
  }
`

export const RunsText = styled.p`
  color: #7a97df;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin: 0;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 19px;
  }
`

export const CharactersMainText = styled.h2`
  margin: 0 0 16px;
  text-transform: uppercase;
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 38px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 29px;
    margin-left: 5px;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledCard = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  max-width: 336px;
  min-width: 162px;
  background: linear-gradient(
    184.67deg,
    rgba(72, 157, 254, 0.63) 1.89%,
    rgba(34, 7, 90, 0.42) 96.23%
  );
  border: none;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  padding: 18px 0 21px;
  @media (max-width: 768px) {
    width: 336px;
    padding: 6px 0;
    border-radius: 12px;
  }
`

export const CardImage = styled.img`
  min-height: 362px;
  max-height: 350px;
  min-width: 321px;
  max-width: 324px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 768px) {
    min-height: 362px;
    min-width: 160px;
    max-height: 172px;
    width: 100%;
    max-width: 324px;
  }
`

export const InfoHeadContainer = styled.div`
  min-height: 36px;
  margin-bottom: 8px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0 8px;
    margin-bottom: 4px;
    min-height: 0;
  }
`

export const CharacterKinds = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    gap: 2px;
    max-width: 140px;
  }
`
export const CharacterKindContainer = styled.li`
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  border-radius: 6px;
  transform: skew(-13deg, 0);
  padding: 1px;
`

export const CharacterKind = styled.div`
  padding: 3px;
  border-radius: 6px;
  min-width: 104px;
  box-sizing: border-box;
  background: #182f52;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    min-width: 55px;
    padding: 0 3px;
  }
`
export const CharacterKindText = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 9px;
    line-height: 18px;
  }
`

export const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  color: #a4bad7;
  font-size: 16px;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 140px;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    max-width: 140px;
  }
`

export const ItemsImage = styled.img`
  height: 14px;
  width: 18px;
  margin-right: 7px;
  margin-top: 4px;
  align-self: flex-start;
  @media (max-width: 768px) {
    height: 18px;
    width: 14px;
    align-self: flex-start;
    margin-right: 7px;
    margin-top: 4px;
  }
`
