import Calendar from 'react-calendar'
import { Button } from '../Button'
import './Calendar.css'
import {
  Modal,
  ModalContainer,
  ButtonsContainer,
  TitleContainer,
  CalendarTitle,
  CalendarDate,
  ButtonWrapper,
} from './Calendar.styled'
import { getStringMonth } from '../../utils/formatDate'

export default function CalendarModal({
  setDate,
  date,
  isCalendarOpen,
  setCalendarOpen,
  oldDate,
  setOldDate,
}) {
  function handleClose(evt) {
    evt.preventDefault()
    setOldDate(date)
    setCalendarOpen(false)
  }

  function handleCancelClose(evt) {
    evt.preventDefault()
    setDate(oldDate)
    setCalendarOpen(false)
  }

  return (
    <Modal isOpen={isCalendarOpen}>
      <ModalContainer>
        <TitleContainer>
          <CalendarTitle>Deadline</CalendarTitle>
          <CalendarDate>
            {date &&
              getStringMonth(date.getUTCMonth(date) + 1) +
                ' ' +
                date.getUTCFullYear(date)}
          </CalendarDate>
        </TitleContainer>
        <Calendar
          onChange={(value) => {
            setDate(
              new Date(
                Date.UTC(
                  value.getFullYear(value),
                  value.getMonth(value),
                  value.getDate(value),
                ),
              ),
            )
          }}
          value={date}
          locale="en-en"
        />
        <ButtonsContainer>
          <Button size="small" onClick={handleCancelClose}>
            Cancel
          </Button>
          <ButtonWrapper>
            <Button size="small" variant="contained" onClick={handleClose}>
              Ok
            </Button>
          </ButtonWrapper>
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  )
}
