import networks from '../networks.json'

const PRODUCTION = process.env.REACT_APP_PRODUCTION || 'staging'
const STAGING_SUPPORTED_CHAINS = Object.keys(networks)
const PRODUCTION_SUPPORTED_CHAINS = ['56', '137']

export const SUPPORTED_CHAINS =
  PRODUCTION === 'production'
    ? PRODUCTION_SUPPORTED_CHAINS
    : STAGING_SUPPORTED_CHAINS

export const EXCHANGE_SUPPORTS = [56, 97]

export const EIP712_CHAIN_ID = PRODUCTION === 'production' ? 56 : 97
export const EIP712_NAME = 'metarun.game'
export const EIP712_VERSION = '0.1'
export const EIP712_TYPE = 'AuthToken'
export const MAX_AUTH_TOKEN_LIFETIME = 60 * 60

export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY
