import ModalComponent from '../Modal'
import { Button } from '../Button'

import {
  ModalLink,
  ModalButtonWrapper,
  ModalImage,
  ModalWrapper,
} from './InstallMetamaskModal.styled'

import { ModalTitle, ModalText } from '../Modal/Modal.styled'

export default function InstallMetamaskModal({ isOpen, onClose }) {
  const dappUrl = window.location.href.split('//')[1]
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <ModalTitle>important</ModalTitle>
      <ModalWrapper>
        <ModalImage
          src={require('../../images/Metamask.png')}
          alt="metamask"
          width="80"
          height="80"
        ></ModalImage>
        <ModalText type="metamask">
          To register on the marketplace, an ethereum wallet must be installed.
        </ModalText>
        <ModalText type="metamask">
          You can download the metamask from the link:
        </ModalText>
        <ModalLink href={metamaskAppDeepLink} target="_blank" rel="noreferrer">
          {metamaskAppDeepLink}
        </ModalLink>
        <ModalButtonWrapper>
          <Button
            size="successModal"
            type="submit"
            variant="successModal"
            onClick={onClose}
          >
            Ok
          </Button>
        </ModalButtonWrapper>
      </ModalWrapper>
    </ModalComponent>
  )
}
