const host = process.env.REACT_APP_API_HOST

export async function getRates(currency) {
  return await fetch(`${host}/rates/?currency=${currency}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
