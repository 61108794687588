import { FormGroup } from '@mui/material'
import { SUPPORTED_CHAINS } from '../../constants'
import networks from '../../networks.json'
import { List, ListItem } from './NetworkMenu.styled'

const NetworkMenu = ({ showChains, handleChainClick }) => {

    
  return (
    <List show={showChains}>
      <FormGroup>
        {SUPPORTED_CHAINS.map((chain, idx) => {
          return (
            <ListItem key={idx} onClick={() => handleChainClick(chain)}>
              {networks[chain].params.chainName}
            </ListItem>
          )
        })}
      </FormGroup>
    </List>
  )
}

export default NetworkMenu
