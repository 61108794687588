import { useState, useContext } from 'react'
import { Button } from '../Button'
import Spinner from '../Spinner'
import ModalComponent from '../Modal'
import { ModalTitle } from '../Modal/Modal.styled'
import { UserContext } from '../../contexts/UserContext'

const SwitchNetworkModal = ({ isOpen, onClose, openModal, info, chain }) => {
  const { connectToBlockchain } = useContext(UserContext)

  const [loading, setLoading] = useState(false)

  async function switchNetwork() {
    setLoading(true)
    await connectToBlockchain(chain).then(async (res) => {
      setLoading(false)
      if (res) {
        onClose()
        !!openModal && openModal()
      }
    })
  }
  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <ModalTitle success margin>
        change your network before continuing
      </ModalTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading ? (
          <Spinner />
        ) : info ? (
          <p style={{ color: 'white' }}>{info}</p>
        ) : (
          <Button onClick={switchNetwork}>switch network</Button>
        )}
      </div>
    </ModalComponent>
  )
}

export default SwitchNetworkModal
