const host = process.env.REACT_APP_API_HOST

export async function registerNewEmail(email) {
  return await fetch(`${host}/auth/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      auth_type: 'CREATE_NEW_ACCOUNT',
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function registerExistingEmail(email) {
  return await fetch(`${host}/auth/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      auth_type: 'AUTH_WITH_EMAIL',
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function registerVerify(emailVerify, code, invite) {
  const obj = {
    email: emailVerify,
    code,
    auth_type: 'CONFIRM_VERIFY_CODE',
  }
  if (invite) {
    obj.invite = invite
  }
  return await fetch(`${host}/auth/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function registerAddress(
  metamaskSignature,
  timestamp,
  token,
  chainId,
  address,
) {
  return await fetch(`${host}/auth/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      signature: metamaskSignature,
      timestamp,
      address,
      chainId,
      auth_type: 'BIND_ETH_ADDRESS',
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function login(metamaskSignature, timestamp, address, chainId) {
  return await fetch(`${host}/auth/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      signature: metamaskSignature,
      timestamp,
      address,
      chainId,
      auth_type: 'AUTH_WITH_SIGNATURE',
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.text())
    .catch((err) => {
      throw new Error(err)
    })
}
