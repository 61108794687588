import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ProfileContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 20px 10px 32px 10px;
  align-items: start;
  gap: 40px;
  color: white;
  @media (max-width: 950px) {
    flex-direction: column;
    padding: 0 12px 40px;
    gap: 32px;
  }
`

export const SideMenuWrapper = styled.div`
  flex-shrink: 0;
  width: 300px;
  box-sizing: border-box;
  position: relative;
  top: -26px;

  @media (max-width: 950px) {
    width: 100%;
    top: 0;
    z-index: 1;
  }
`

export const SideMenuContainer = styled.div`
  border-radius: 20px;
  flex-shrink: 0;
  width: 300px;
  box-sizing: border-box;
  background: #10102cb2;
  backdrop-filter: blur(15px);
  flex-direction: column;
  display: flex;
  overflow: hidden;

  @media (max-width: 950px) {
    width: 100%;
    border-radius: ${({ rightBottomBorderRadius }) => {
      if (rightBottomBorderRadius === 'square') {
        return '12px 12px 0 12px'
      }
      return '12px'
    }};
    border: 1px solid #1e1e35;
    overflow: visible;
  }
`

export const SideMenuInfo = styled.div`
  padding: 40px 40px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 950px) {
    flex-direction: row;
    padding: 32px;
    gap: 16px;
  }
`

export const SideMenuInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 950px) {
    align-items: start;
  }
`

export const SideMenuAvatarContainer = styled.div`
  backdrop-filter: blur(38px);

  padding: 1px;
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );

  border-radius: 20px;
  margin-bottom: 17px;

  @media (max-width: 950px) {
    margin-bottom: 0px;
  }
`

export const SideMenuAvatar = styled.div`
  padding: 11px 7px 0 8px;
  background: #10102c;
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 950px) {
    padding: 6px 5px 0 6px;
  }
`
export const SideMenuAvatarImg = styled.img`
  position: relative;
  bottom: -5px;
  @media (max-width: 950px) {
    width: 53px;
    height: 53px;
  }
`

export const SideMenuNickname = styled.p`
  margin: 0;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.5px;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 190px;
  overflow: hidden;
`

export const SideMenuButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 6px;
  position: relative;
  bottom: -2px;
  transition: all 0.5s;

  &:active {
    opacity: 0.5;
  }
`

export const SideMenuAddress = styled.p`
  margin: 0;

  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-align: left;
  color: #7a97df;
`

export const SideMenuItem = styled(Link)`
  text-decoration: none;
  margin: 0;
  padding: 32px;
  cursor: pointer;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  box-sizing: border-box;
  color: ${({ current }) => {
    if (current === 'true') {
      return 'white'
    }
    return ' #7A97DF '
  }};
  background: ${({ current }) => {
    if (current === 'true') {
      return 'linear-gradient( 157.67deg,  rgba(72, 157, 254, 0.63) 0.4%, rgba(34, 7, 90, 0.42) 87.86% )'
    }
    return ' transparent '
  }};

  &:hover {
    background: linear-gradient(
      157.67deg,
      rgba(72, 157, 254, 0.63) 0.4%,
      rgba(34, 7, 90, 0.42) 87.86%
    );
  }
  @media (max-width: 950px) {
    font-size: 18px;
    line-height: 27px;
    padding: 8.5px 10px;
    border-radius: 0;
    width: 100%;
    border-top: 1px solid #1e1e35;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-radius: 0 0 0 12px;
      border-right: 1px solid #1e1e35;
      overflow: hidden;
    }
  }
`

export const DesktopSideMenuItem = styled.div`
  display: flex;
  @media (max-width: 950px) {
    display: none;
  }
`

export const MobileSideMenuItem = styled.div`
  display: none;
  width: 34%;
  height: auto;
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 1px solid #1e1e35;
  }
`

export const SideMenuIcon = styled.span`
  margin-right: 16px;
  @media (max-width: 950px) {
    display: none;
  }
`
export const SideMenuList = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 950px) {
    flex-direction: row;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
`

export const HiddenInput = styled.input`
  opacity: 0;
  position: fixed;
  top: -200%;
  left: -200%;
`

export const SideMenuItemContainer = styled.div`
  display: flex;
  height: 25.5px;
  align-items: flex-start;
  @media (max-width: 950px) {
    max-height: 72px;
    height: 100%;
    align-items: center;
  }
`

export const SideMenuListButton = styled.button`
  @media (max-width: 950px) {
    background: transparent;
    cursor: pointer;
    font-size: 18px;
    border-radius: 0;
    width: 100%;
    border: none;
    border-left: 1px solid #1e1e35;
    height: 100%;
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 27px;
    color: #7a97df;
    background: transparent;
    padding: 9px;
  }
`

export const MoreIcon = styled.img`
  @media (max-width: 950px) {
    width: 12px;
    height: 8px;
    margin-left: 12px;
  }
`

export const MoreMenu = styled.div`
  display: none;
  @media (max-width: 950px) {
    display: ${({ show }) => {
      if (show === 'true') {
        return 'flex'
      }
      return 'none'
    }};
    flex-direction: column;
    position: absolute;
    right: 0px;
    bottom: -68px;
    z-index: 2;
    border-radius: 20px;
    background: #0f0e2c;
    flex-direction: column;
    color: white;
    width: 34%;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #1e1e35;
    border-top: none;
    box-sizing: border-box;
  }
`

export const MoreMenuContainer = styled.div`
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
`

export const MoreMenuListItem = styled(Link)`
  &:hover {
    background: linear-gradient(
      157.67deg,
      rgba(72, 157, 254, 0.63) 0.4%,
      rgba(34, 7, 90, 0.42) 87.86%
    );
  }
  @media (max-width: 950px) {
    display: inline-block;
    color: ${({ current }) => {
      if (current === 'true') {
        return 'white'
      }
      return ' #7A97DF '
    }};
    background: ${({ current }) => {
      if (current === 'true') {
        return 'linear-gradient( 157.67deg,  rgba(72, 157, 254, 0.63) 0.4%, rgba(34, 7, 90, 0.42) 87.86% )'
      }
      return ' transparent '
    }};
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.5px;
    text-align: left;
    &:last-child {
      border-radius: 0 0 12px 12px;
      border-top: 1px solid #1e1e35;
    }
  }
`

export const MoreMenuListItemContainer = styled.div`
  @media (max-width: 950px) {
    padding: 20px 8px;
    box-sizing: border-box;
    margin: 0px auto;
    max-width: 110px;
  }
`

export const BackButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  height: 56px;
  font-size: 16px;
  padding: 7px 26px;
  background: #10102cb2;

  gap: 8px;
  color: #fff;
  text-decoration: none;

  font-weight: 500;
  line-height: 1;
  box-sizing: border-box;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    border-radius: 12px;
    font-size: 14px;
    padding: 5px 14px;
    height: 36px;
  }
`
