import styled from 'styled-components'
import Link from '@mui/material/Link'
import { Link as RecatLink } from 'react-router-dom'
import ReactPlayer from 'react-player'

const vars = {
  media: '768px',
}

export const StyledLink = styled(Link)`
  transition: opacity 0.5s;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

export const StyledButtonLink = styled(RecatLink)`
  text-decoration: none;
  width: 100%;
  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
`

export const StyledRouterLink = styled(RecatLink)`
  cursor: pointer;
`

export const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
`

export const TokenPrice = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`

export const PageWrapper = styled.div`
  margin: auto;
  max-width: 1046px;
  padding: 0 12px 32px;

  @media (min-width: ${(props) => vars.media}) {
    padding: 0 20px 40px;
  }
`

export const PageHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 30px;

  @media (min-width: ${(props) => vars.media}) {
    margin-bottom: 32px;
  }
`

export const BackButton = styled.a`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 36px;
  background: #10102cb2;
  padding: 5px 14px;
  gap: 8px;
  color: #fff;
  text-decoration: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  box-sizing: border-box;

  @media (min-width: ${(props) => vars.media}) {
    border-radius: 20px;
    height: 56px;
    font-size: 16px;
    padding: 7px 26px;
  }
`

export const HeadShareButtons = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  @media (min-width: ${(props) => vars.media}) {
    gap: 16px;
  }
`

export const HeadOpenSeaLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #2081e2;
  border-radius: 12px;
  opacity: 1;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    fill: #fff;
    width: 36px;
    height: 36px;
  }

  @media (min-width: ${(props) => vars.media}) {
    width: 56px;
    height: 56px;
    border-radius: 20px;

    svg {
      fill: #fff;
      width: 40px;
      height: 40px;
    }
  }
`

export const HeadShareLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #1c1b39;
  border-radius: 12px;
  opacity: 1;
  transition: opacity 0.2s;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  svg {
    fill: #fff;
    width: 24px;
    height: 24px;
  }

  @media (min-width: ${(props) => vars.media}) {
    width: 56px;
    height: 56px;
    border-radius: 20px;
  }
`

export const PageBody = styled.div`
  display: grid;
  gap: 12px;

  @media (min-width: ${(props) => vars.media}) {
    grid-template-columns: 58% 39%;
    gap: 32px;
  }
`

export const TokenPictureWrapper = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const CharacterKinds = styled.ul`
  position: absolute;
  left: 5px;
  top: -15px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    top: -20px;
  }
`
export const CharacterKindContainer = styled.li`
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  border-radius: 6px;
  transform: skew(-13deg, 0);
  padding: 1px;
`

export const CharacterKind = styled.div`
  padding: 3px;
  border-radius: 6px;
  min-width: 104px;
  box-sizing: border-box;
  background: #0f0e2b;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    min-width: 80px;
  }
`
export const CharacterKindText = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const TokenPictureHeadTitle = styled.h1`
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.75px;
  text-align: center;
  position: relative;
  top: -20px;
  margin: 0;
  color: #9cacfe;

  @media (max-width: 768px) {
    font-size: 21px;
  }
`

export const TokenHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TokenHeadCount = styled.div`
  color: #a3bad6;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const TokenPicture = styled.img`
  max-width: 604px;
  width: 100%;
  height: auto;
  margin-top: 24px;
`

export const TokenBody = styled.div`
  position: relative;
  top: -85px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: $fff;

  @media (max-width: 768px) {
    position: static;
  }

  @media (min-width: ${(props) => vars.media}) {
    gap: 16px;
    max-width: 410px;
  }
`

export const TokenBodyCard = styled.div`
  background: #0f0e2b;
  border-radius: 12px;
  padding: 12px;

  @media (min-width: ${(props) => vars.media}) {
    border-radius: 20px;
    padding: 24px 16px;
  }
`

export const InfoLabel = styled.div`
  font-size: 14px;
  color: #7a97df;

  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;

  @media (min-width: ${(props) => vars.media}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TokenBodyCardLast = styled.div`
  display: grid;
  gap: 12px;
  margin-bottom: 40px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(
      278.44deg,
      rgba(73, 158, 255, 0.35) 42.55%,
      rgba(95, 81, 255, 0.35) 84.23%
    );
  }
`

export const TokenBodyCardLastData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;

  .price {
    color: #fff;
    font-size: 21px;
    max-width: 250px;
    overflow: hidden;
    font-style: italic;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.5px;

    @media (max-width: ${(props) => vars.media}) {
      font-size: 24px;
      max-width: 190px;
    }
  }
`

export const TokenBodyCardGrid = styled.div`
  display: grid;
  grid-template-columns: 49% 46%;
  gap: 6px;
  margin-bottom: 40px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(
      278.44deg,
      rgba(73, 158, 255, 0.35) 42.55%,
      rgba(95, 81, 255, 0.35) 84.23%
    );
  }
`

export const TokenBodyCardInfo = styled.div`
  display: flex;

  gap: 12px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`

export const TokenBodyCardInfoBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const TokenBodyCardInfoKind = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin-right: 4px;
  background: #65b905;
  background: ${({ tokenKind }) => {
    switch (tokenKind) {
      case 'Penna':
        return '#65b905'
      case 'Oro':
        return '#1884FF'
      case 'Ignis':
        return '#D24724'
      case 'Ticket':
        return '#499eff'
      case 'Mystery Box':
        return '#5c0095'
    }
    return '#E58857'
  }};
  border-radius: 12px;
  @media (max-width: 768px) {
    height: 36px;
    width: 36px;
    border-radius: 8px;
  }
`
export const TokenBodyCardInfoKindImg = styled.img`
  height: 27px;
  width: 27px;
  color: green;

  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
    margin-top: 2px;
  }
`

export const TokenBodyCardInfoOwnerImg = styled.img`
  width: 48px;
  height: 48px;
  @media (max-width: 768px) {
    height: 36px;
    width: 36px;
    border-radius: 8px;
  }
`

export const TokenBodyCardInfoText = styled.div`
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;

  letter-spacing: 0.46px;
  text-align: left;

  @media (min-width: 877px) {
    font-size: 16px;
    line-height: 26px;
  }
`

export const OrderStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

export const BuyButton = styled.button`
  display: inline-flex;
  transform: skew(-13deg, 0);
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  min-height: 48px;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(
    89.54deg,
    #489dfe 10.41%,
    #3970fe 23.53%,
    #5e51ff 35.77%
  );

  opacity: 1;
  transition: opacity 0.2s, transform ease-in 0.1s;

  &:hover {
    opacity: 0.7;
  }

  @media (min-width: ${(props) => vars.media}) {
    min-height: 56px;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.4;
    background: #1d1f2a;
    cursor: not-allowed;
  }

  &:active {
    transform: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return 'scale(0.9)'
      }
      return 'skew(-13deg, 0) scale(0.9)'
    }};
    opacity: 0.9;
  }

  &:disabled&:active,
  &[disabled]&:active {
    transform: skew(-13deg, 0);
    opacity: 0.4;
  }
`

export const TransactionLink = styled.a`
  display: block;
  text-align: center;
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`

export const SaleOrderButton = styled.button`
  display: inline-flex;
  transform: skew(-13deg, 0);
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  min-height: 48px;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(91.22deg, #03d5ff 0%, #5f59ff 100%);
  opacity: 1;
  transition: opacity 0.8s, transform ease-in 0.1s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @media (min-width: ${(props) => vars.media}) {
    min-height: 56px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background: #0f0e2b;
    z-index: -1;
    border-radius: 8px;
  }

  &:hover:before {
    opacity: 0.7;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 1;
    background: #1d1f2a;
    color: #ffffff6b;
  }
  &:disabled:before,
  &[disabled]:before {
    opacity: 1;
    cursor: not-allowed;
  }

  &:active {
    transform: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return 'scale(0.9)'
      }
      return 'skew(-13deg, 0) scale(0.9)'
    }};
    opacity: 0.9;
  }

  &:disabled&:active,
  &[disabled]&:active {
    transform: skew(-13deg, 0);
    opacity: 1;
  }
`

export const DeleteButton = styled(SaleOrderButton)`
  background: #ed4915;
  &:disabled,
  &[disabled] {
    opacity: 1;
    background: #1d1f2a;
    cursor: not-allowed;
  }
`

export const OrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #40444d;
  border-radius: 20px;
  padding: 20px 10px;
  margin-bottom: 10px;
`

export const OrderContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: ${({ variant }) => {
      switch (variant) {
        case 'withoutMargin':
          return '12px'
      }
      return '35px'
    }};
  }
`

export const OrderName = styled.p`
  margin: 0;
  padding: 0;
  width: 35%;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #9fa1a6;
  @media (min-width: ${(props) => vars.media}) {
    font-size: 14px;
    line-height: 21px;
  }
`
export const OrderValue = styled.p`
  margin: 0;
  padding: 0;

  font-size: 16px;

  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: white;
  @media (min-width: ${(props) => vars.media}) {
    font-size: 14px;
    line-height: 21px;
  }
`

export const AttributeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AttributeName = styled.p`
  color: #9fa1a6;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  @media (min-width: ${(props) => vars.media}) {
    font-family: OpenSans;
    font-size: 14px;
    line-height: 21px;
  }
`
export const AttributeValue = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: right;
  color: white;
  @media (min-width: ${(props) => vars.media}) {
    font-size: 14px;
    line-height: 21px;
  }
`

export const TokenVideo = styled(ReactPlayer)`
  width: 100% !important;
  height: 667px !important;

  @media (max-width: 768px) {
    height: 360px !important;
  }
`

export const InfoText = styled.p`
  color: white;
  margin: 20px auto 10px;
  text-align: center;
  font-size: 18px;
  max-width: 300px;
  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 250px;
  }
`
