import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MainContainer = styled.div`
  background: #0f0f24;
  border: 1px solid #292c72;
  padding: 40px;
  border-radius: 20px;
  margin: 0 20px 32px;
  position: relative;

  @media (max-width: 950px) {
    margin: 0 12px;
    padding: 0;
    padding-top: 0;
    background: transparent;
    border: none;
  }
`
export const BalancesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  @media (max-width: 1300px) {
    justify-content: space-around;
    margin-bottom: 128px;
  }
  @media (max-width: 950px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

export const InfoContainer = styled.div`
  background: #489dfe1a;
  background: ${({ variant }) => {
    switch (variant) {
      case 'opal':
        return '#E487571A '
    }
    return ' rgba(94, 81, 255, 0.1)'
  }};
  box-sizing: border-box;
  max-width: 407px;
  min-width: 332px;
  flex-grow: 1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 21px 24px;
  @media (max-width: 950px) {
    padding: 24px 28px;
    max-width: 450px;
    width: 100%;
    min-width: 155px;
  }
`
export const InfoBalanceContainer = styled.div`
  margin-right: 5px;
`

export const InfoBalanceDescription = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #7a97df;
  @media (max-width: 950px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const InfoBalance = styled.div`
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  color: white;
  white-space: nowrap;
  max-width: 230px;
  overflow: hidden;
  @media (max-width: 950px) {
    font-size: 18px;
    max-width: 180px;
    line-height: 24px;
  }
`

export const InfoImg = styled.img`
  border: none;
  width: ${({ variant }) => {
    switch (variant) {
      case 'mrun':
        return '34px'
      case 'mtrun':
        return '38px'
      case 'plus':
        return '23px'
      case 'arrows':
        return '32px'
    }
    return ' 24px'
  }};

  height: ${({ variant }) => {
    switch (variant) {
      case 'mtrun':
        return '32px'
      case 'plus':
        return '23px'
      case 'arrows':
        return '32px'
    }
    return ' 24px'
  }};
  @media (max-width: 950px) {
    width: 23px;
    height: 24px;
  }
`

export const InfoImgContainer = styled.div`
  display: flex;
  border: none;
  padding: ${({ variant }) => {
    switch (variant) {
      case 'mrun':
        return '16px 11px'
      case 'mtrun':
        return '15px 8px 9px 10px'
    }
    return ' 16px'
  }};
  border-radius: 20px;
  background: #191c26;
  @media (max-width: 950px) {
    border-radius: 12px;
    padding: 9px;
  }
`

export const AddButton = styled.button`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  padding: 16px;
  padding: ${({ type }) => {
    switch (type) {
      case 'mtrun':
        return '12px 11px 11px 12px'
      case 'opal':
        return '12px 11px 11px 12px'
      case 'arrows':
        return '12px 11px 11px 12px'
    }
    return '16px'
  }};
  border-radius: 20px;
  border: none;
  border: ${({ type }) => {
    switch (type) {
      case 'mrun':
        return 'none'
      case 'mtrun':
        return 'none'
      case 'opal':
        return 'none'
    }
    return '1px solid rgba(255, 255, 255, 0.1)'
  }};

  background: ${({ type }) => {
    switch (type) {
      case 'opal':
        return '#E48757'
      case 'mtrun':
        return '#499EFF'
      case 'mrun':
        return '#5F51FF'
      default:
        return 'transparent'
    }
  }};
  box-shadow: ${({ type }) => {
    switch (type) {
      case 'opal':
        return '0 0 10px 1px #E48757'
      case 'mtrun':
        return '0 0 10px 1px #499EFF'
      case 'mrun':
        return '0 0 10px 1px #5F51FF'
      default:
        return 'none'
    }
  }};
  transition: 0.5s all;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.6;
  }
  &:disabled:hover,
  &[disabled]:hover {
    opacity: 1;
  }
  @media (max-width: 950px) {
    border-radius: 12px;
    padding: 9px;
  }
`

export const ImgBorder = styled.div`
  background: ${({ variant }) => {
    switch (variant) {
      case 'violet':
        return '#5F51FF'
      case 'mtrun':
        return '#499EFF'
    }
    return ' #E48757 '
  }};
  border-radius: 20px;
  backdrop-filter: blur(38px);
  display: inline-block;
  font-size: 20px;
  padding: 1px;
  text-decoration: none;
  margin-right: 16px;

  @media (max-width: 950px) {
    border-radius: 12px;
  }
`

export const InfoContainerCenter = styled.div`
  background: #489dfe1a;
  box-sizing: border-box;
  max-width: 407px;
  min-width: 320px;
  // width: 100%;
  flex-grow: 1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 21px 24px;
  @media (max-width: 1300px) {
    width: 100%;
    position: absolute;
    top: 170px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media (max-width: 950px) {
    padding: 24px 28px;
    max-width: 450px;
    min-width: 155px;

    position: static;
    transform: none;
  }
`

export const PromptLayout = styled.div`
  margin: 0 auto;
  margin-top: 32px;
  background: url(${require(`../../images/PromptBackground.png`)});
  background-size: cover;
  border-radius: 20px;
  display: ${({ promptIsOpen }) => {
    if (promptIsOpen) {
      return 'block'
    }
    return 'none'
  }};

  @media (max-width: 1300px) {
    margin-top: 160px;
  }

  @media (max-width: 950px) {
    margin-top: 32px;
    max-width: 450px;
  }
`

export const PromptInfo = styled.div`
  position: relative;
  color: white;
  background: linear-gradient(
    88.82deg,
    #489dfe 11.9%,
    #3970fe 45.06%,
    #5e51ff 76.01%
  );
  opacity: 0.9;
  border-radius: 16px;
  padding: 24px;
  padding-left: 240px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 950px) {
    flex-direction: column-reverse;

    align-items: end;
    padding: 24px;
  }
`
export const PromptImg = styled.img`
  position: absolute;
  bottom: 0;
  bottom: ${({ type }) => {
    if (type === 'mrun') {
      return '-35px'
    }
    return '0'
  }};
  left: 0;
  width: ${({ type }) => {
    if (type === 'mrun') {
      return '220px'
    }
    return ' 166px'
  }};
  height: ${({ type }) => {
    if (type === 'mrun') {
      return '194px'
    }
    return ' 157px'
  }};
  @media (max-width: 950px) {
    display: none;
  }
`

export const PromptContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
`

export const PromptTitle = styled.h3`
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  @media (max-width: 950px) {
    margin-bottom: 12px;
  }
`
export const PromptText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  max-width: 814px;
  @media (max-width: 950px) {
    font-size: 13px;
  }
`

export const CloseButtonContainer = styled.div`
  border: 1px solid #ffffff;
  top: 0px;
  right: -60px;
  border-radius: 20px;
  width: 56px;
  min-width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 15px;
  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 950px) {
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 12px;
    width: 42px;
    min-width: 42px;
    height: 42px;
  }
`

export const CloseButton = styled.img`
  width: 14px;
  height: 14px;
`

export const HiddenInput = styled.input`
  opacity: 0;
  position: fixed;
  top: -200%;
  left: -200%;
`

export const ButtonSmallText = styled.span`
  display: block;
  font-size: 12px;
  @media (max-width: 950px) {
    display: inline;
    font-size: 10px;
  }
  @media (max-width: 370px) {
    font-size: 9px;
  }
`

export const PromptRouterLink = styled(Link)`
  color: white;
  font-weight: 700;
  text-decoration: underline;
  font-style: italic;
`

export const Tooltip = styled.div`
  border: transparent;
  white-space: nowrap;
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translate(-50%, calc(120% - 7px));
  padding: 4px 16px 6px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 10px 21px 0 rgba(173, 182, 217, 0.3);
  border: none;
  font-size: 14px;
  transition: all 0.25s ease;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  &.opened {
    visibility: visible;
    opacity: 1;
  }
  &:after {
    content: '';
    border: transparent;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom: 7px solid white;
    position: absolute;
    bottom: 97%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 3px 8px 3px;
  }
`
export const TooltipWrapper = styled.div`
  position: relative;
  margin-left: auto;
`
