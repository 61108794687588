import { BuyButton } from '../CardDetail.styled'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { useContext, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import SwitchNetworkModal from '../../SwitchNetworkModal'

export function RevealBoxButton({ chain, style }) {
  const [openSwitchModal, setOpenSwitchModal] = useState(false)
  const { chainId } = useContext(UserContext)
  const navigate = useNavigate()

  const reveal = () => {
    if (+chainId !== chain) {
      setOpenSwitchModal(true)
    } else {
      navigate('reveal')
    }
  }

  return (
    <>
      <BuyButton style={style} onClick={reveal}>
        <AutoAwesomeIcon />
        Reveal
      </BuyButton>
      <SwitchNetworkModal
        isOpen={openSwitchModal}
        chain={chain}
        onClose={() => setOpenSwitchModal(false)}
      />
    </>
  )
}
