import { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import MultiRangeSlider from '../MultiRangeSlider'
import { Button } from '../Button'
import ChainFilter from './ChainFilter'

import {
  FilterContainer,
  FilterTitle,
  FilterPanel,
  FilterItem,
  FilterItemTitle,
  FilterItemList,
  FilterSearchInput,
  FilterSearchInputWrapper,
  FilterPropertyItemList,
  FilterPropertyItem,
  FilterPropertyItemWrapper,
  FilterButton,
  FilterButtonImg,
  FilterModalButtonsContainer,
  ExpandFilterLessIcon,
  ExpandFilterMoreIcon,
} from './Filter.styled'

export default function Filter({
  setKindFilters,
  minLevel,
  maxLevel,
  handleFilterModal,
  searchHandler,
  resetRange,
  filterModalIsOpen,
  searchValue,
  kindFilters,
  minRange,
  maxRange,
  setMaxLevel,
  setMinLevel,
  setLevelFilters,
  levelFilters,
}) {
  const [isFilterItemsOpen, setIsFilterItemsOpen] = useState({
    Tokens: true,
    Chains: true,
    Name: true,
    Kind: true,
    Rarity: true,
    Another: true,
    Stats: true,
  })

  return (
    <>
      <FilterContainer isOpen={filterModalIsOpen}>
        <FilterTitle>Filter</FilterTitle>
        <FilterPanel>
          <FilterItem>
            <FilterItemTitle
              onClick={() => {
                setIsFilterItemsOpen((prevState) => {
                  return {
                    ...prevState,
                    Tokens: !isFilterItemsOpen.Tokens,
                  }
                })
              }}
            >
              Tokens
              {isFilterItemsOpen.Tokens ? (
                <ExpandFilterLessIcon />
              ) : (
                <ExpandFilterMoreIcon />
              )}
            </FilterItemTitle>

            <FilterItemList show={isFilterItemsOpen.Tokens}>
              <FilterSearchInputWrapper>
                <SearchIcon
                  sx={{
                    position: 'absolute',
                    color: 'white',
                    left: '15px',
                    top: '12px',
                  }}
                />
                <FilterSearchInput
                  placeholder="Search tokens by id"
                  value={searchValue}
                  onChange={searchHandler}
                />
              </FilterSearchInputWrapper>
            </FilterItemList>
          </FilterItem>
          <ChainFilter
            isFilterItemsOpen={isFilterItemsOpen}
            setIsFilterItemsOpen={setIsFilterItemsOpen}
            setKindFilters={setKindFilters}
            kindFilters={kindFilters}
          />
          <FilterItem>
            <FilterItemTitle
              onClick={() => {
                setIsFilterItemsOpen((prevState) => {
                  return {
                    ...prevState,
                    Name: !isFilterItemsOpen.Name,
                  }
                })
              }}
            >
              Name
              {isFilterItemsOpen.Name ? (
                <ExpandFilterLessIcon />
              ) : (
                <ExpandFilterMoreIcon />
              )}
            </FilterItemTitle>

            <FilterItemList show={isFilterItemsOpen.Name}>
              <FilterPropertyItemList>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Ignis: !kindFilters.Ignis,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Ignis}
                  >
                    Ignis
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Oro: !kindFilters.Oro,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Oro}
                  >
                    Oro
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Penna: !kindFilters.Penna,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Penna}
                  >
                    Penna
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
              </FilterPropertyItemList>
            </FilterItemList>
          </FilterItem>
          <FilterItem>
            <FilterItemTitle
              onClick={() => {
                setIsFilterItemsOpen((prevState) => {
                  return {
                    ...prevState,
                    Kind: !isFilterItemsOpen.Kind,
                  }
                })
              }}
            >
              Kind
              {isFilterItemsOpen.Kind ? (
                <ExpandFilterLessIcon />
              ) : (
                <ExpandFilterMoreIcon />
              )}
            </FilterItemTitle>
            <FilterItemList show={isFilterItemsOpen.Kind}>
              <FilterPropertyItemList>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Classic: !kindFilters.Classic,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Classic}
                  >
                    Classic
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Epic: !kindFilters.Epic,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Epic}
                  >
                    Epic
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Legendary: !kindFilters.Legendary,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Legendary}
                  >
                    Legendary
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
              </FilterPropertyItemList>
            </FilterItemList>
          </FilterItem>
          <FilterItem>
            <FilterItemTitle
              onClick={() => {
                setIsFilterItemsOpen((prevState) => {
                  return {
                    ...prevState,
                    Rarity: !isFilterItemsOpen.Rarity,
                  }
                })
              }}
            >
              Rarity
              {isFilterItemsOpen.Rarity ? (
                <ExpandFilterLessIcon />
              ) : (
                <ExpandFilterMoreIcon />
              )}
            </FilterItemTitle>
            <FilterItemList show={isFilterItemsOpen.Rarity}>
              <FilterPropertyItemList>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Common: !kindFilters.Common,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Common}
                  >
                    Common
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Rare: !kindFilters.Rare,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Rare}
                  >
                    Rare
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Mythical: !kindFilters.Mythical,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Mythical}
                  >
                    Mythical
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
              </FilterPropertyItemList>
            </FilterItemList>
          </FilterItem>
          <FilterItem>
            <FilterItemTitle
              onClick={() => {
                setIsFilterItemsOpen((prevState) => {
                  return {
                    ...prevState,
                    Another: !isFilterItemsOpen.Another,
                  }
                })
              }}
            >
              Another
              {isFilterItemsOpen.Another ? (
                <ExpandFilterLessIcon />
              ) : (
                <ExpandFilterMoreIcon />
              )}
            </FilterItemTitle>
            <FilterItemList show={isFilterItemsOpen.Another}>
              <FilterPropertyItemList>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        Ticket: !kindFilters.Ticket,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.Ticket}
                  >
                    Ticket
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
                <FilterPropertyItemWrapper
                  onClick={() => {
                    setKindFilters((prevState) => {
                      return {
                        ...prevState,
                        MysteryBox: !kindFilters.MysteryBox,
                      }
                    })
                  }}
                >
                  <FilterPropertyItem
                    isChecked={kindFilters && kindFilters.MysteryBox}
                  >
                    Mystery Box
                  </FilterPropertyItem>
                </FilterPropertyItemWrapper>
              </FilterPropertyItemList>
            </FilterItemList>
          </FilterItem>
          <FilterItem>
            <FilterItemTitle
              onClick={() => {
                setIsFilterItemsOpen((prevState) => {
                  return {
                    ...prevState,
                    Stats: !isFilterItemsOpen.Stats,
                  }
                })
              }}
            >
              Stats
              {isFilterItemsOpen.Stats ? (
                <ExpandFilterLessIcon />
              ) : (
                <ExpandFilterMoreIcon />
              )}
            </FilterItemTitle>
            <FilterItemList show={isFilterItemsOpen.Stats}>
              <MultiRangeSlider
                min={minRange}
                max={maxRange}
                minVal={minLevel}
                maxVal={maxLevel}
                setMaxVal={setMaxLevel}
                setMinVal={setMinLevel}
                setLevelFilters={setLevelFilters}
                levelFilters={levelFilters}
              />
            </FilterItemList>
          </FilterItem>
          <FilterModalButtonsContainer>
            <Button
              size="filter"
              onClick={() => {
                setKindFilters({
                  Ignis: false,
                  Oro: false,
                  Penna: false,
                  Ticket: false,
                  MysteryBox: false,
                  Classic: false,
                  Epic: false,
                  Legendary: false,
                  Common: false,
                  Rare: false,
                  Mythical: false,
                })
                resetRange()
              }}
            >
              Clear All
            </Button>
            <Button
              size="filter"
              variant="contained"
              onClick={handleFilterModal}
            >
              Done
            </Button>
          </FilterModalButtonsContainer>
        </FilterPanel>
      </FilterContainer>

      <FilterButton onClick={handleFilterModal}>
        <FilterButtonImg
          src={require('../../images/Filter.svg').default}
          alt="filter"
          width="24"
          height="24"
        />
      </FilterButton>
    </>
  )
}
