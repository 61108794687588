import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledCard = styled.div`
  display: ${({ display }) => {
    if (display) {
      return 'flex'
    }
    return 'none'
  }};
  height: 100%;
  flex-direction: column;
  max-width: 324px;
  min-width: 162px;
  background: #120f7a;
  background: linear-gradient(
    184.67deg,
    rgba(72, 157, 254, 0.63) 1.89%,
    rgba(34, 7, 90, 0.42) 96.23%
  );
  border: none;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  padding: 12px 0;
  @media (max-width: 768px) {
    padding: 10px 0 6px;
    border-radius: 12px;
  }
`

export const CardImage = styled.img`
  min-height: 350px;
  max-height: 363px;
  min-width: 321px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 1052px) {
    min-height: 250px;
    min-width: 230px;
    max-height: 250px;

    max-width: 230px;
  }
  @media (max-width: 768px) {
    min-height: 165px;
    min-width: 120px;
    max-height: 182px;
    width: 100%;
    max-width: 167px;
  }
`

export const CardContent = styled.div`
  margin-top: 3px;
`

export const InfoContainer = styled.div`
  display: flex;
  padding: 0 16px;
  margin: 12px 0 12px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin: 8px 0 6px;
    padding: 0 8px;
  }
`

export const InfoHeadContainer = styled.div`
  min-height: 52px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1052px) {
    padding: 0 8px;
    min-height: 0;
  }
`
export const KindContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-size: 16px;

  font-weight: 700;
  font-style: italic;
  line-height: 26px;
  letter-spacing: 0.5px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.5;
    align-items: center;
  }
`

export const KindImageContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin-right: 8px;
  background: #4e037c;
  border-radius: 12px;
  @media (max-width: 768px) {
    height: 18px;
    width: 18px;
    border-radius: 5px;
  }
`
export const KindImage = styled.img`
  height: 20px;
  width: 20px;
  color: green;

  @media (max-width: 768px) {
    height: 10px;
    width: 10px;
  }
`

export const PriceContainer = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #7a97df;

  @media (max-width: 768px) {
    font-size: 10px;
    max-width: 65px;
    line-height: 15px;
    letter-spacing: 0px;
  }
`
export const PriceWrapper = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-style: italic;
  line-height: 38px;
  letter-spacing: 0.5px;
  color: #ffffff;
  @media (max-width: 1052px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    font-size: 11px;

    line-height: 22px;
  }
`

export const ButtonContainer = styled.div`
  padding: 20px 0 10px;
  display: flex;
  margin: 0 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #a4bad7;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 10px;
    padding-top: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.5px;
    margin: 0 8px;
  }
`
export const Border = styled.div`
  height: 1px;
  margin: 0 16px;
  background: rgba(73, 158, 255, 0.35);
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );
  @media (max-width: 768px) {
    margin: 0 8px;
  }
`
export const StyledLink = styled(Link)`
  text-decoration: none;
  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
`

export const ButtonLinkWrapper = styled.div`
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
`
