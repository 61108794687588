import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { Scrollbars } from 'react-custom-scrollbars'
import { Button } from '../../components/Button'
import ModalComponent from '../Modal'

import {
  ModalButtonWrapper,
  ModalTerms,
  ModalSubtitle,
  ModalCheckboxLabel,
  ModalButtonItem,
} from './TermsModal.styled'

import { ModalTitle, ModalScrollbar, ModalText } from '../Modal/Modal.styled'

export default function TermsModal({ isOpen, onClose }) {
  const [checkboxDisabled, setCheckboxDisabled] = useState(true)
  const [checkboxChecked, setCheckboxChecked] = useState(false)

  const handleScroll = (values) => {
    const { top } = values
    if (top === 1) {
      setCheckboxDisabled(false)
      setCheckboxChecked(true)
    }
  }

  return (
    <ModalComponent width="676px" isOpen={isOpen} onClose={onClose}>
      <ModalTitle margin>Wallet usage agreement</ModalTitle>
      <ModalScrollbar terms>
        <Scrollbars
          autoHeight={true}
          autoHeightMax={395}
          style={{ marginBottom: '24px' }}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                right: '2px',
                bottom: '2px',
                top: '2px',
                borderRadius: '3px',
                width: '3px',
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '3px',
                borderRadius: '4px',
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
                backgroundColor: '#ffffff',
                background:
                  ' linear-gradient(269.99deg, #489DFE -0.04%, #5E51FF 99.98%)',
                cursor: 'pointer',
              }}
            />
          )}
          onScrollFrame={handleScroll}
        >
          <ModalTerms>
            <ModalSubtitle>Wallets and Digital Assets</ModalSubtitle>
            <ModalText type="terms">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta,
              autem. Necessitatibus cumque minus sunt consequatur placeat
              corrupti eos quidem veniam, at enim. Iste nam blanditiis illo
              accusantium at. Accusantium, quos. Lorem ipsum, dolor sit amet
              consectetur adipisicing elit. Ad alias nulla delectus voluptatum
              ex, nobis molestiae deserunt officia dolorem corporis dolor amet
              dignissimos repellendus repellat vero magnam accusamus, iure
              suscipit. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Veniam incidunt natus cupiditate minus quasi quaerat? Ut
              quod, ipsa non nostrum reiciendis praesentium quis enim nihil
              debitis amet maiores! Delectus, laborum.
            </ModalText>
            <ModalSubtitle>Interaction with Protocols</ModalSubtitle>
            <ModalText type="terms">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta,
              autem. Necessitatibus cumque minus sunt consequatur placeat
              corrupti eos quidem veniam, at enim. Iste nam blanditiis illo
              accusantium at. Accusantium, quos. Lorem ipsum, dolor sit amet
              consectetur adipisicing elit. Ad alias nulla delectus voluptatum
              ex, nobis molestiae deserunt officia dolorem corporis dolor amet
              dignissimos repellendus repellat vero magnam accusamus, iure
              suscipit. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Veniam incidunt natus cupiditate minus quasi quaerat? Ut
              quod, ipsa non nostrum reiciendis praesentium quis enim nihil
              debitis amet maiores! Delectus, laborum.
            </ModalText>
          </ModalTerms>
        </Scrollbars>
      </ModalScrollbar>
      <ModalCheckboxLabel>
        <Checkbox
          disabled={checkboxDisabled}
          checked={checkboxChecked}
          checkedIcon={<CheckBoxOutlinedIcon />}
          onClick={() => {
            setCheckboxChecked(!checkboxChecked)
          }}
          sx={{
            color: '#5758FF',
            padding: 0,
            marginRight: '12px',
            '&.Mui-disabled': {
              color: '#303030e8',
            },
            '&.Mui-checked': {
              color: '#5758FF',
            },
          }}
        />
        I have read the above Agreements
      </ModalCheckboxLabel>
      <ModalButtonWrapper>
        <ModalButtonItem>
          <Button size="terms-modal">Back</Button>
        </ModalButtonItem>
        <ModalButtonItem>
          <Button
            type="submit"
            size="terms-modal"
            variant="contained"
            disabled={!checkboxChecked}
          >
            Accept
          </Button>
        </ModalButtonItem>
      </ModalButtonWrapper>
    </ModalComponent>
  )
}
