import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const AppHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 40px 15px 0;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    padding: 12px;
    margin-bottom: 12px;
  }
`
export const TransparentHeader = styled.div`
  background: ${({ isSticky }) => {
    if (isSticky) {
      return '#10102cb2'
    }
    return '#10102c'
  }};
  background: rgba(16, 16, 44, 0.7);
  transition: background 1s;
  backdrop-filter: blur(10px);
  padding: 24px;
  border-radius: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 12px;
    border-radius: 8px;
  }
`

export const StyledRouterLink = styled.a`
  text-decoration: none;
  color: white;
  margin-right: auto;
`

export const MenuList = styled.div`
  position: absolute;
  border-radius: 20px;
  z-index: 2;
  width: max-content;
  background: #0f0e2c;
  top: 65px;
  right: 0;
  flex-direction: column;
  display: ${({ show }) => {
    if (show) {
      return 'flex'
    }
    return 'none'
  }};
  @media (max-width: 768px) {
    top: 48px;
  }
`
export const MenuListItem = styled(Link)`
  text-decoration: none;
  margin: 0;
  color: white;
  padding: 15px 25px;
  cursor: pointer;
  text-align: left;
  font-size: 16px;

  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;

  &:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  &:hover {
    background: #489dfe1a;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 18px;
  }
`

export const MenuListItemButton = styled.button`
  color: white;
  background-color: transparent;
  padding: 15px 25px;
  border: none;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  font-family: 'OpenSans';

  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;

  &:hover {
    background: #489dfe1a;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 18px;
  }
`

export const MoreIconButton = styled.button`
  padding: 14.5px 16px;
  background-color: #1b1a37;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  margin-left: 16px;
  border: none;
  &:disabled,
  &[disabled] {
    color: gray;
  }

  @media (max-width: 768px) {
    padding: 6px 8px;
    border-radius: 12px;
    margin-left: 8px;
  }
`

export const MoreButtonWrapper = styled.div`
  position: relative;
`

export const ButtonText = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 2px;
  margin-right: 3px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const SignTime = styled.div`
  display: inline-block;
  width: 50px;
  color: #9fa1a6;
  margin-top: 2px;
  @media (max-width: 992px) {
    display: none;
  }
`

export const LoaderImg = styled.img`
  margin-right: 8px;
  width: 32px;
  height: 32px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`

export const HiddenText = styled.span`
  @media (max-width: 992px) {
    display: none;
  }
`

export const ButtonWrapper = styled.div`
  margin-left: 16px;
  @media (max-width: 768px) {
    margin-left: 8px;
  }
`
