const host = process.env.REACT_APP_API_HOST

export async function getCards(params = {}) {
  const {
    page,
    ordering,
    level,
    kindFilters,
    searchValue,
    characterFilter,
  } = params

  let nameParam = ''
  if (
    kindFilters &&
    (kindFilters.Oro ||
      kindFilters.Ignis ||
      kindFilters.Penna ||
      kindFilters.Ticket ||
      kindFilters.MysteryBox)
  ) {
    nameParam = `&token_name=${kindFilters.Ignis ? `Ignis,` : ''}${
      kindFilters.Oro ? `Oro,` : ''
    }${kindFilters.Penna ? `Penna,` : ''}${
      kindFilters.Ticket ? `Ticket,` : ''
    }${kindFilters.MysteryBox ? `Mystery+Box,` : ''}`
  }

  let kindParam = ''
  if (
    kindFilters &&
    (kindFilters.Classic || kindFilters.Epic || kindFilters.Legendary)
  ) {
    kindParam = `&token_kind=${kindFilters.Classic ? `Classic,` : ''}${
      kindFilters.Epic ? `Epic,` : ''
    }${kindFilters.Legendary ? `Legendary,` : ''}`
  }

  let rarityParam = ''
  if (
    kindFilters &&
    (kindFilters.Common || kindFilters.Rare || kindFilters.Mythical)
  ) {
    rarityParam = `&token_rarity=${kindFilters.Common ? `Common,` : ''}${
      kindFilters.Rare ? `Rare,` : ''
    }${kindFilters.Mythical ? `Mythical,` : ''}`
  }

  let chainsParam = ''
  if (kindFilters && (kindFilters.BSC || kindFilters.Polygon)) {
    chainsParam = `&chain=${kindFilters.BSC ? `bsc,` : ''}${
      kindFilters.Polygon ? `polygon,` : ''
    }`
  }

  const characterParam = characterFilter ? `token_type=Character&` : ''
  const pageParam = page ? `page=${page}&` : ''
  const levelParam = level ? `level_between=${level.min},${level.max}&` : ''

  const concatParams = `?${levelParam}${pageParam}${characterParam}${chainsParam}ordering=${ordering ||
    ''}${nameParam}${kindParam}${rarityParam}&contract_token_id=${searchValue ||
    ''}`
  return await fetch(`${host}/ownerships/${concatParams}`)
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getOwnerCards(params = {}) {
  const {
    userAddress,
    page,
    ordering,
    level,
    kindFilters,
    searchValue,
    characterFilter,
  } = params
  let nameParam = ''
  if (
    kindFilters &&
    (kindFilters.Oro ||
      kindFilters.Ignis ||
      kindFilters.Penna ||
      kindFilters.Ticket ||
      kindFilters.MysteryBox)
  ) {
    nameParam = `&token_name=${kindFilters.Ignis ? `Ignis,` : ''}${
      kindFilters.Oro ? `Oro,` : ''
    }${kindFilters.Penna ? `Penna,` : ''}${
      kindFilters.Ticket ? `Ticket,` : ''
    }${kindFilters.MysteryBox ? `Mystery+Box,` : ''}`
  }

  let kindParam = ''
  if (
    kindFilters &&
    (kindFilters.Classic || kindFilters.Epic || kindFilters.Legendary)
  ) {
    kindParam = `&token_kind=${kindFilters.Classic ? `Classic,` : ''}${
      kindFilters.Epic ? `Epic,` : ''
    }${kindFilters.Legendary ? `Legendary,` : ''}`
  }

  let rarityParam = ''
  if (
    kindFilters &&
    (kindFilters.Common || kindFilters.Rare || kindFilters.Mythical)
  ) {
    rarityParam = `&token_rarity=${kindFilters.Common ? `Common,` : ''}${
      kindFilters.Rare ? `Rare,` : ''
    }${kindFilters.Mythical ? `Mythical,` : ''}`
  }

  let chainsParam = ''
  if (kindFilters && (kindFilters.BSC || kindFilters.Polygon)) {
    chainsParam = `&chain=${kindFilters.BSC ? `bsc,` : ''}${
      kindFilters.Polygon ? `polygon,` : ''
    }`
  }

  const characterParam = characterFilter ? `token_type=Character&` : ''
  const pageParam = page ? `page=${page}&` : ''
  const levelParam = level ? `level_between=${level.min},${level.max}&` : ''

  const concatParams = `?${levelParam}${pageParam}${characterParam}${chainsParam}ordering=${ordering ||
    ''}&holder=${userAddress ||
    ''}${nameParam}${kindParam}${rarityParam}&contract_token_id=${searchValue ||
    ''}`

  return await fetch(`${host}/ownerships/${concatParams}`)
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getCardsWithoutOwner(params = {}) {
  const {
    userAddress,
    page,
    ordering,
    level,
    kindFilters,
    searchValue,
    characterFilter,
  } = params
  let nameParam = ''
  if (
    kindFilters &&
    (kindFilters.Oro ||
      kindFilters.Ignis ||
      kindFilters.Penna ||
      kindFilters.Ticket ||
      kindFilters.MysteryBox)
  ) {
    nameParam = `&token_name=${kindFilters.Ignis ? `Ignis,` : ''}${
      kindFilters.Oro ? `Oro,` : ''
    }${kindFilters.Penna ? `Penna,` : ''}${
      kindFilters.Ticket ? `Ticket,` : ''
    }${kindFilters.MysteryBox ? `Mystery+Box,` : ''}`
  }

  let kindParam = ''
  if (
    kindFilters &&
    (kindFilters.Classic || kindFilters.Epic || kindFilters.Legendary)
  ) {
    kindParam = `&token_kind=${kindFilters.Classic ? `Classic,` : ''}${
      kindFilters.Epic ? `Epic,` : ''
    }${kindFilters.Legendary ? `Legendary,` : ''}`
  }

  let rarityParam = ''
  if (
    kindFilters &&
    (kindFilters.Common || kindFilters.Rare || kindFilters.Mythical)
  ) {
    rarityParam = `&token_rarity=${kindFilters.Common ? `Common,` : ''}${
      kindFilters.Rare ? `Rare,` : ''
    }${kindFilters.Mythical ? `Mythical,` : ''}`
  }
  let chainsParam = ''
  if (
    kindFilters &&
    (kindFilters.BSC ||
      kindFilters.Polygon)
  ) {
    chainsParam = `&chain=${kindFilters.BSC ? `bsc,` : ''}${
      kindFilters.Polygon ? `polygon,` : ''
    }`
  }

  const characterParam = characterFilter ? `token_type=Character&` : ''
  const pageParam = page ? `page=${page}&` : ''
  const levelParam = level ? `level_between=${level.min},${level.max}&` : ''

  const concatParams = `?sell_orders=true&${levelParam}${pageParam}${characterParam}${chainsParam}ordering=${ordering ||
    ''}&holder_exclude=${userAddress ||
    ''}${nameParam}${kindParam}${rarityParam}&contract_token_id=${searchValue ||
    ''}`

  return await fetch(`${host}/ownerships/${concatParams}`)
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getCard(id) {
  return await fetch(`${host}/ownerships/${id}/`)
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
