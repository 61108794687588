import React, { useEffect, useState, useContext } from 'react'
import { ethers } from 'ethers'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { EIP712_NAME, EIP712_VERSION, SUPPORTED_CHAINS } from '../../constants'
import { getMetarunCollection } from '../../api/contracts'
import networks from '../../networks.json'
import { placeOrder } from '../../api'
import { Button } from '../../components/Button'
import CalendarModal from '../Calendar'
import Spinner from '../Spinner'
import ModalComponent from '../Modal'
import { getFormattedUTCDate } from '../../utils/formatDate'
import { shortenAddress } from '../../utils/shortenAddress'
import { UserContext } from '../../contexts/UserContext'

import {
  ModalForm,
  ModalInputContainer,
  ModalInputContainerWrapper,
  ModalInputLabel,
  DatePickerButton,
  DatePickerButtonContainer,
  ModalDateInput,
  ModalDateInputContainer,
  ModalDateInputLabel,
  ModalTextError,
  ModalSpinnerWrapper,
} from './OrderModal.styled'

import {
  ModalTitle,
  ModalTxText,
  ModalTxLink,
  ModalInput,
  ModalTextDiv,
} from '../Modal/Modal.styled'

export default function OrderModal({
  isOpen,
  onClose,
  tokenKind,
  setOrderIsAdded,
  orderIsAdded,
  tokenChainId,
}) {
  const { userAddress, chainId } = useContext(UserContext)

  const [calendarOpen, setCalendarOpen] = useState(false)

  const [allowance, setAllowance] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState()
  const [error, setError] = useState('')
  const [isApproveProcess, setIsApproveProcess] = useState(false)
  const [isAllowanceLoaded, setIsAllowanceLoaded] = useState(false)

  function toTimestamp(strDate) {
    const datum = Date.parse(strDate)
    return datum / 1000
  }

  const dateNow = new Date()
  const [date, setDate] = useState(
    new Date(
      Date.UTC(
        dateNow.getUTCFullYear(),
        dateNow.getUTCMonth(),
        dateNow.getUTCDate(),
      ),
    ),
  )
  const [oldDate, setOldDate] = useState(date)
  const amount = 1
  const [price, setPrice] = useState('')

  async function getAllowance() {
    const MetarunCollection = getMetarunCollection(chainId)

    await MetarunCollection.isApprovedForAll(
      userAddress,
      networks[chainId].metarunExchangeContract.address,
    )
      .then((allowance) => {
        setAllowance(allowance)
        setIsAllowanceLoaded(true)
      })
      .catch((err) => {
        console.log(err)
        setIsAllowanceLoaded(true)
      })
  }

  async function getApprove(e) {
    e.preventDefault()
    setIsLoading(true)
    setError('')
    const MetarunCollection = getMetarunCollection(chainId)
    if (!allowance) {
      setIsApproveProcess(true)
      await MetarunCollection.setApprovalForAll(
        networks[chainId].metarunExchangeContract.address,
        true,
      )
        .then((tx) => {
          setIsApproveProcess(false)
          setTxHash(tx.hash)
          tx.wait()
            .then(() => {
              getAllowance()
              setIsLoading(false)
              setTxHash()
              setOrder()
            })
            .catch((err) => {
              console.log(err)
              setIsApproveProcess(false)
              setError(
                (err.data && err.data.message) ||
                  err.message ||
                  'Something went wrong',
              )
              setIsLoading(false)
              setTxHash()
            })
        })
        .catch((err) => {
          console.log(err)
          setIsApproveProcess(false)
          setError(
            (err.data && err.data.message) ||
              err.message ||
              'Something went wrong',
          )
          setIsLoading(false)
          setTxHash()
        })
    } else {
      setOrder()
    }
  }

  async function setOrder() {
    setIsLoading(true)
    const typedData = {
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        SellOrder: [
          { name: 'seller', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'amount', type: 'uint256' },
          { name: 'expirationTime', type: 'uint256' },
          { name: 'price', type: 'uint256' },
          { name: 'salt', type: 'uint256' },
        ],
      },
      primaryType: 'SellOrder',
      domain: {
        name: EIP712_NAME,
        version: EIP712_VERSION,
        chainId,
        verifyingContract: networks[chainId].metarunExchangeContract.address,
      },
      message: {
        seller: userAddress,
        tokenId: tokenKind,
        amount,
        expirationTime: toTimestamp(date),
        price: ethers.utils.parseUnits(price, 'ether').toString(),
        salt: Math.floor(Math.random() * 100000000000000),
      },
      userAddress,
    }

    try {
      const signature = await window.ethereum.request({
        method: 'eth_signTypedData_v4',
        params: [userAddress, JSON.stringify(typedData)],
      })

      const generatedOrders = generateOrder(
        typedData.message,
        signature,
        tokenChainId,
      )

      placeOrder(generatedOrders)
        .then(() => {
          setOrderIsAdded(!orderIsAdded)
          onClose()
          setIsLoading(false)
        })
        .catch((e) => {
          console.log(e)
          setIsLoading(false)
        })
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  function generateOrder(message, signature, chainId) {
    return {
      ...message,
      signature,
      chainId,
    }
  }

  const priceHandler = (e) => {
    const value = e.target.value
    const isFloat = /^([0-9]*)+([.][0-9]*)?$/g.test(value)

    if (isFloat && value !== '00') {
      setPrice(value)
    }
  }

  useEffect(() => {
    if (
      userAddress &&
      SUPPORTED_CHAINS.includes(chainId) &&
      networks[chainId].metarunExchangeContract
    ) {
      getAllowance()
    }
  }, [userAddress, chainId])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ModalComponent isOpen={isOpen} onClose={onClose} width="538px">
        <ModalTitle margin>Creating An Order</ModalTitle>

        {isLoading ? (
          <>
            {!isApproveProcess && (
              <ModalSpinnerWrapper>
                {' '}
                <Spinner size="80px" />
              </ModalSpinnerWrapper>
            )}
            {!txHash && isApproveProcess ? (
              <>
                <ModalTextDiv type="order">
                  To approve Metarun to manage your NFT tokens, you must first
                  complete a free (excluding Gas) transaction. Please confirm
                  this in your wallet and keep this modal open!
                </ModalTextDiv>
                <ModalTextDiv type="order">
                  You’ll never have to do this approval again.
                </ModalTextDiv>
              </>
            ) : !txHash ? (
              <ModalTxText>Await confirmation</ModalTxText>
            ) : (
              <ModalTxLink
                target="_blank"
                href={
                  networks[chainId]
                    ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                    : ''
                }
              >
                TxHash: {shortenAddress(txHash)}
              </ModalTxLink>
            )}
          </>
        ) : (
          <ModalForm>
            <ModalTextDiv type="order">
              To place a sell order, a signature made from your crypto wallet
              will be requested.
            </ModalTextDiv>
            <ModalTextDiv type="order">
              The following wallets are supported: Metamask (browser extensions
              and mobile app), Trust Wallet, SafePal.
            </ModalTextDiv>
            <ModalTextDiv type="order">
              The correctness of the signature from other wallets is not
              guaranteed.
            </ModalTextDiv>
            <ModalInputContainerWrapper>
              <ModalInputContainer>
                <ModalInputLabel>Price</ModalInputLabel>
                <ModalInput
                  maxWidth
                  invalid={price < ethers.utils.formatEther(1) || !price}
                  placeholder={`1.000(MRUN)`}
                  onChange={priceHandler}
                  value={price}
                />
              </ModalInputContainer>
            </ModalInputContainerWrapper>
            <ModalDateInputContainer>
              <DatePickerButtonContainer
                onClick={() => {
                  setCalendarOpen(true)
                }}
              >
                <DatePickerButton
                  src={require('../../images/DatePicker.svg').default}
                  alt="date-picker"
                />
              </DatePickerButtonContainer>
              <ModalDateInputLabel>Deadline</ModalDateInputLabel>
              <ModalDateInput
                invalid={date < Date.now() + 43200}
                value={getFormattedUTCDate(date)}
                readOnly={true}
                onClick={() => {
                  setCalendarOpen(true)
                }}
              ></ModalDateInput>
            </ModalDateInputContainer>
            <ModalTextError>{error}</ModalTextError>
            <Button
              size="extralong"
              variant="contained"
              disabled={
                price < ethers.utils.formatEther(1) ||
                !price ||
                date < Date.now() + 43200 ||
                !isAllowanceLoaded
              }
              onClick={getApprove}
            >
              Place Order
            </Button>
          </ModalForm>
        )}
      </ModalComponent>
      <CalendarModal
        setDate={setDate}
        date={date}
        isCalendarOpen={calendarOpen}
        setCalendarOpen={setCalendarOpen}
        oldDate={oldDate}
        setOldDate={setOldDate}
      />
    </LocalizationProvider>
  )
}
