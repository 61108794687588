import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react'
import { ethers } from 'ethers'
import { Button } from '../../components/Button'
import Spinner from '../Spinner'
import { truncateDecimalDigit } from '../../utils/truncateDecimalDigit'
import { shortenAddress } from '../../utils/shortenAddress'
import { getMetarunToken, getMetarunVault } from '../../api/contracts'
import { getRates, getIngameInfo, placeWithdrawal } from '../../api'
import networks from '../../networks.json'
import {
  EIP712_NAME,
  EIP712_VERSION,
  MAX_AUTH_TOKEN_LIFETIME,
} from '../../constants'
import { UserContext } from '../../contexts/UserContext'
import {
  ModalNameStyled as ModalName,
  ModalImg,
  ModalBalancesStyled as ModalBalances,
} from './BuyMTRunModal.styled'

import {
  Modal,
  ModalContainer,
  ModalForm,
  ModalButtonContainer,
  ModalInputContainer,
  ModalInputLabel,
  ModalError,
  ModalNameWrapper,
  ModalButtonWrapper,
  ModalTextError,
  ModalMenuList,
  ModalMenuItem,
  ModalWrapper,
  ModalBalance,
  ModalLargeText,
  ModalRules,
  ModalWithdrawButtonWrapper,
  ModalTextContainer,
  BorderStyled as Border,
} from '../BuyOpalModal/BuyOpalModal.styled'

import {
  ModalTxText,
  ModalTxLink,
  ModalInput,
  ModalCloseButton,
  ModalTextDiv,
} from '../Modal/Modal.styled'

export default function BuyMTRunModal({
  isOpen,
  onClose,
  forceUpdateBuy,
  mrunAllowance,
  getAllowance,
  withdrawals,
  forceUpdate,
}) {
  const modal = useRef(null)
  const {
    userAddress,
    chainId,
    playfabId,
    mrunBalance,
    mtrunBalance,
  } = useContext(UserContext)

  const [isBuy, setIsBuy] = useState(true)

  const [mtrunPrice, setMrunPrice] = useState(0) // price for 1 MTRUN
  const [mtrunWithdrawPrice, setMrunWithdrawPrice] = useState(0)

  const [mrunAmount, setMrunAmount] = useState(0)
  const [mtrunAmount, setMtrunAmount] = useState(0)
  const [mtrunIsInteger, setMtrunIsInteger] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState()
  const [error, setError] = useState('')
  const [isApproveProcess, setIsApproveProcess] = useState(false)

  const [withdrawAmount, setWithdrawAmount] = useState(0)
  const [withdrawMrunAmount, setWithdrawMrunAmount] = useState(0)
  const [withdrawSuccess, setWithdrawSuccess] = useState(0)
  const [withdrawError, setWithdrawError] = useState()

  const isInteger = (value) => /^[0-9]*$/g.test(value)

  const withdrawAmountHandler = (e) => {
    e.preventDefault()
    const value = e.target.value

    if (isInteger(value)) {
      setWithdrawAmount(value)
      setWithdrawMrunAmount(value * mtrunPrice)
    }
  }

  const maxWithdrawAmountHandler = (e) => {
    e.preventDefault()

    setWithdrawAmount(withdrawals.mtrun.availableAmount)
    setWithdrawMrunAmount(withdrawals.mtrun.availableAmount * mtrunPrice)
  }

  const mrunAmountHandler = (e) => {
    e.preventDefault()
    const value = e.target.value

    if (!isNaN(value)) {
      setMrunAmount(value)
      setMtrunAmount(value / mtrunPrice)
      setMtrunIsInteger(isInteger(value / mtrunPrice))
    }
  }

  const mtrunAmountHandler = (e) => {
    e.preventDefault()
    const value = e.target.value

    if (!isNaN(value)) {
      setMtrunAmount(value)
      setMrunAmount(value * mtrunPrice)
      setMtrunIsInteger(isInteger(value))
    }
  }

  const cancelHandler = (e) => {
    e.preventDefault()
    closeModal()
  }

  function closeModal() {
    setTxHash()
    setError('')
    setIsLoading(false)
    if (withdrawSuccess) {
      forceUpdateBuy()
      forceUpdate()
      setWithdrawSuccess(false)
    }
    if (withdrawError) {
      forceUpdateBuy()
      forceUpdate()
      setWithdrawError()
    }

    onClose()
  }

  async function getApprove(e) {
    e.preventDefault()
    setIsLoading(true)
    setError('')
    const MetarunToken = getMetarunToken(chainId)
    if (
      Number(ethers.utils.parseEther(mrunAmount.toString())) >
      mrunAllowance - 100
    ) {
      setIsApproveProcess(true)
      await MetarunToken.approve(
        networks[chainId].metarunVaultContract.address,
        ethers.constants.MaxUint256,
      )
        .then((tx) => {
          setIsApproveProcess(false)
          setTxHash(tx.hash)
          tx.wait()
            .then(() => {
              getAllowance()
              setIsLoading(false)
              setTxHash()
              buyMTRUN()
            })
            .catch((err) => {
              console.log(err)
              setIsApproveProcess(false)
              setError(
                (err.data && err.data.message) ||
                  err.message ||
                  'Something went wrong',
              )
              setIsLoading(false)
              setTxHash()
            })
        })
        .catch((err) => {
          console.log(err)
          setIsApproveProcess(false)
          setError(
            (err.data && err.data.message) ||
              err.message ||
              'Something went wrong',
          )
          setIsLoading(false)
          setTxHash()
        })
    } else {
      buyMTRUN()
    }
  }

  async function buyMTRUN() {
    setIsLoading(true)
    setError('')
    const MetarunVault = getMetarunVault(chainId)
    MetarunVault.purchaseMTRUN(ethers.utils.parseEther(mrunAmount.toString()))
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then(() => {
            setTimeout(function testTokens() {
              getIngameInfo(playfabId)
                .then(({ VirtualCurrency }) => {
                  if (VirtualCurrency.MT > mtrunBalance) {
                    closeModal()
                    forceUpdateBuy()
                    forceUpdate()
                  } else {
                    setTimeout(testTokens, 2000)
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            }, 1000)
          })
          .catch((err) => {
            console.log(err)
            setError(
              (err.data && err.data.message) ||
                err.message ||
                'Something went wrong',
            )
            setIsLoading(false)
            setTxHash()
          })
      })
      .catch((err) => {
        console.log(err)
        setError(
          (err.data && err.data.message) ||
            err.message ||
            'Something went wrong',
        )
        setIsLoading(false)
        setTxHash()
      })
  }

  async function withdraw() {
    setIsLoading(true)
    const timestamp = Math.floor(Date.now() / 1000 + MAX_AUTH_TOKEN_LIFETIME)
    const typedData = {
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Withdrawal: [
          { name: 'timestamp', type: 'uint256' },
          { name: 'amount', type: 'uint256' },
          { name: 'currency', type: 'string' },
        ],
      },
      primaryType: 'Withdrawal',
      domain: {
        name: EIP712_NAME,
        version: EIP712_VERSION,
        chainId: chainId,
        verifyingContract: networks[chainId].metarunVaultContract.address,
      },
      message: {
        timestamp: timestamp,
        amount: withdrawAmount,
        currency: 'MTRUN',
      },
    }

    const metamaskSignature = await window.ethereum
      .request({
        method: 'eth_signTypedData_v4',
        params: [userAddress, JSON.stringify(typedData)],
      })
      .catch(() => {
        setIsLoading(false)
      })

    if (metamaskSignature) {
      placeWithdrawal(
        metamaskSignature,
        timestamp,
        withdrawAmount,
        'MTRUN',
        userAddress,
      )
        .then(() => {
          setTimeout(function testTokens() {
            getIngameInfo(playfabId)
              .then(({ VirtualCurrency }) => {
                if (VirtualCurrency.MT < mtrunBalance) {
                  setWithdrawSuccess(true)
                  setIsLoading(false)
                } else {
                  setTimeout(testTokens, 2000)
                }
              })
              .catch((e) => {
                console.log(e)
                setWithdrawError('Something went wrong. Try again later')
                setIsLoading(false)
              })
          }, 1000)
        })
        .catch((e) => {
          e.text()
            .then((e) => {
              setWithdrawError(e || 'Something went wrong. Try again later')
            })
            .catch(() =>
              setWithdrawError('Something went wrong. Try again later'),
            )
          setIsLoading(false)
        })
    }
  }

  async function fetchRates() {
    await getRates('MTRUN')
      .then(({ results }) => {
        const price = results[0].depositRate / 1000000000000000000
        const withrawPrice = results[0].withdrawalRate / 1000000000000000000
        setMrunPrice(price)
        setMrunWithdrawPrice(withrawPrice)
        setMrunAmount(price * 1)
        setMtrunAmount(1)
        setWithdrawAmount(1)
        setWithdrawMrunAmount(1 * withrawPrice)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleOverlayClose = useCallback((event) => {
    if (event.target === event.currentTarget) {
      closeModal()
    }
  }, [])

  useEffect(() => {
    if (modal.current) {
      modal.current.addEventListener('click', handleOverlayClose)
    }
    return () => {
      if (modal.current) {
        modal.current.removeEventListener('click', handleOverlayClose)
      }
    }
  }, [handleOverlayClose])

  useEffect(() => {
    fetchRates()
  }, [])

  return (
    <Modal isOpen={isOpen} ref={modal}>
      <ModalContainer>
        <ModalButtonContainer onClick={closeModal}>
          <ModalCloseButton
            src={require('../../images/Close.svg').default}
            alt="close"
            width="14"
            height="14"
          ></ModalCloseButton>
        </ModalButtonContainer>
        <ModalMenuList>
          <ModalMenuItem current={isBuy} onClick={() => setIsBuy(true)}>
            Buy MTRUN
          </ModalMenuItem>
          <ModalMenuItem current={!isBuy} onClick={() => setIsBuy(false)}>
            Withdrawal
          </ModalMenuItem>
        </ModalMenuList>
        {isBuy ? (
          <ModalWrapper>
            {isLoading ? (
              <ModalTextContainer>
                {!isApproveProcess && <Spinner size="80px" />}
                {!txHash && isApproveProcess ? (
                  <>
                    <ModalTextDiv type="buy-mtrun">
                      To approve Metarun to receive payment in MRUN, you must
                      first complete a free (excluding Gas) transaction. Please
                      confirm this in your wallet and keep this modal open!
                    </ModalTextDiv>
                    <ModalTextDiv type="buy-mtrun">
                      You may notice a very large number being requested for
                      approval. This is simply the maximum amount, meaning
                      you’ll never have to do this approval again. It also
                      doesn’t give permission for Metarun to take this amount
                      from you. The price of the MTRUN is all that will be
                      charged.
                    </ModalTextDiv>
                  </>
                ) : !txHash ? (
                  <ModalTxText>Await confirmation</ModalTxText>
                ) : (
                  <ModalTxLink
                    target="_blank"
                    href={
                      networks[chainId]
                        ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                        : ''
                    }
                  >
                    TxHash: {shortenAddress(txHash)}
                  </ModalTxLink>
                )}
              </ModalTextContainer>
            ) : (
              <ModalForm>
                <ModalTextDiv type="buy-mtrun">
                  {1 * mtrunPrice} MRUN = 1 MTRUN
                </ModalTextDiv>
                <ModalInputContainer>
                  <ModalInputLabel>You Get</ModalInputLabel>
                  <ModalInput
                    maxWidth
                    disabled={mtrunPrice === 0}
                    invalid={!mtrunIsInteger}
                    value={
                      mtrunAmount &&
                      truncateDecimalDigit(mtrunAmount.toString())
                    }
                    placeholder="1"
                    onChange={mtrunAmountHandler}
                  />
                  {!mtrunIsInteger && (
                    <ModalError>Value must be an integer</ModalError>
                  )}
                  <ModalNameWrapper>
                    <ModalName
                      invalid={!mtrunIsInteger}
                      disabled={mtrunPrice === 0}
                    >
                      <ModalImg
                        src={require('../../images/MTrun.svg').default}
                        alt="mtrun"
                        variant="mtrun"
                        width="24"
                        height="18"
                      />
                      MTRUN
                    </ModalName>
                  </ModalNameWrapper>
                </ModalInputContainer>
                <ModalInputContainer>
                  <ModalInputLabel>You Send</ModalInputLabel>
                  <ModalInput
                    disabled={mtrunPrice === 0}
                    invalid={
                      Number(mrunAmount) > mrunBalance ||
                      Number(mrunAmount) === 0
                    }
                    value={
                      mrunAmount && truncateDecimalDigit(mrunAmount.toString())
                    }
                    placeholder="1"
                    onChange={mrunAmountHandler}
                  />
                  {Number(mrunAmount) > mrunBalance ? (
                    <ModalError>Not enough MRUN balance</ModalError>
                  ) : (
                    Number(mrunAmount) === 0 && (
                      <ModalError>Value must not be zero</ModalError>
                    )
                  )}
                  <ModalNameWrapper>
                    <ModalName
                      invalid={
                        Number(mrunAmount) > mrunBalance ||
                        Number(mrunAmount) === 0
                      }
                      disabled={mtrunPrice === 0}
                    >
                      <ModalImg
                        src={require('../../images/SmallLogo.svg').default}
                        alt="mrun"
                        width="20"
                        height="14"
                      />
                      MRUN
                    </ModalName>
                  </ModalNameWrapper>
                </ModalInputContainer>
                <ModalTextError>{error}</ModalTextError>
                <ModalButtonWrapper>
                  <Button size="buy" variant="buy" onClick={cancelHandler}>
                    Cancel
                  </Button>
                  <Button
                    size="buy"
                    variant="contained"
                    onClick={getApprove}
                    disabled={
                      !mtrunIsInteger ||
                      Number(mrunAmount) > mrunBalance ||
                      Number(mrunAmount) === 0 ||
                      !networks[chainId] ||
                      !networks[chainId].metarunTokenContract ||
                      !networks[chainId].metarunVaultContract
                    }
                  >
                    Buy
                  </Button>
                </ModalButtonWrapper>
              </ModalForm>
            )}
          </ModalWrapper>
        ) : (
          <ModalWrapper>
            {withdrawSuccess ? (
              <ModalTextContainer>
                <ModalTextDiv type="buy-mtrun">
                  Successfully! Your MRUN token balance will be replenished
                  within 10 minutes
                </ModalTextDiv>
              </ModalTextContainer>
            ) : withdrawError ? (
              <ModalTextContainer>
                <ModalTextDiv type="buy-mtrun">{withdrawError}</ModalTextDiv>
              </ModalTextContainer>
            ) : isLoading ? (
              <ModalTextContainer>
                <Spinner size="80px" />
                {!txHash ? (
                  <ModalTxText>Await confirmation</ModalTxText>
                ) : (
                  <ModalTxLink
                    target="_blank"
                    href={
                      networks[chainId]
                        ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                        : ''
                    }
                  >
                    TxHash: {shortenAddress(txHash)}
                  </ModalTxLink>
                )}
              </ModalTextContainer>
            ) : withdrawals.mtrun ? (
              <ModalForm>
                <ModalBalances>
                  <ModalBalance>
                    Available {withdrawals.mtrun.availableAmount} MTRUN
                  </ModalBalance>
                  <ModalBalance>
                    1 MTRUN ={' '}
                    {mtrunWithdrawPrice !== 0
                      ? 1 * mtrunWithdrawPrice.toFixed(4)
                      : 0}{' '}
                    MRUN
                  </ModalBalance>
                </ModalBalances>

                <ModalInputContainer>
                  <ModalInputLabel>MTRUN</ModalInputLabel>
                  <ModalInput
                    invalid={
                      Number(withdrawAmount) >
                        withdrawals.mtrun.availableAmount ||
                      Number(withdrawAmount) <
                        withdrawals.mtrun.minWithdrawalAmount ||
                      Number(withdrawAmount) === 0
                    }
                    value={
                      withdrawAmount &&
                      truncateDecimalDigit(withdrawAmount.toString())
                    }
                    placeholder="1"
                    onChange={withdrawAmountHandler}
                    disabled={mtrunWithdrawPrice === 0}
                  />
                  {Number(withdrawAmount) >
                  withdrawals.mtrun.availableAmount ? (
                    <ModalError>Limit exceeded</ModalError>
                  ) : Number(withdrawAmount) <
                    withdrawals.mtrun.minWithdrawalAmount ? (
                    <ModalError>
                      Value must not be less than the minimum
                    </ModalError>
                  ) : (
                    Number(withdrawAmount) === 0 && (
                      <ModalError>Value must not be zero</ModalError>
                    )
                  )}
                  <ModalNameWrapper onClick={maxWithdrawAmountHandler} max>
                    <ModalName
                      disabled={mtrunWithdrawPrice === 0}
                      invalid={
                        Number(withdrawAmount) >
                          withdrawals.mtrun.availableAmount ||
                        Number(withdrawAmount) <
                          withdrawals.mtrun.minWithdrawalAmount ||
                        Number(withdrawAmount) === 0
                      }
                    >
                      Max
                    </ModalName>
                  </ModalNameWrapper>
                </ModalInputContainer>
                <ModalLargeText>
                  You Get{' '}
                  {withdrawMrunAmount &&
                    truncateDecimalDigit(withdrawMrunAmount.toString())}{' '}
                  MRUN
                </ModalLargeText>
                <ModalTextError>{error}</ModalTextError>
                <ModalWithdrawButtonWrapper>
                  <Button
                    size="withdraw"
                    variant="contained"
                    onClick={withdraw}
                    disabled={
                      Number(withdrawAmount) >
                        withdrawals.mtrun.availableAmount ||
                      Number(withdrawAmount) <
                        withdrawals.mtrun.minWithdrawalAmount ||
                      Number(withdrawAmount) === 0
                    }
                  >
                    Withdrawal
                  </Button>
                </ModalWithdrawButtonWrapper>
                <Border />
                <ModalRules>
                  *The minimum withdrawal amount is equivalent to 20 dollars
                  which equals {withdrawals.mtrun.minWithdrawalAmount} MTRUN
                  <br /> *The maximum withdrawal amount is equivalent to 250
                  dollars, or {withdrawals.mtrun.dailyLimitAmount} MTRUN per day
                  <br />
                  *Only one transaction per 24 hours is possible
                  <br />
                  *Only MTRUN received more than 24 hours ago are available for
                  withdrawal
                </ModalRules>
              </ModalForm>
            ) : (
              'error'
            )}
          </ModalWrapper>
        )}
      </ModalContainer>
    </Modal>
  )
}
