import React, { useState, useEffect, useContext } from 'react'
import { ethers } from 'ethers'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Button'
import Spinner from '../Spinner'
import ModalComponent from '../Modal'
import { getMetarunExchange, getMetarunToken } from '../../api/contracts'
import { getOwnerCards } from '../../api'
import { shortenAddress } from '../../utils/shortenAddress'
import networks from '../../networks.json'
import { SUPPORTED_CHAINS } from '../../constants'
import { UserContext } from '../../contexts/UserContext'
import {
  ModalMain,
  ModalTextWrapper,
  ModalError,
  BorderStyled as Border,
  ModalBoldText,
  ModalTextContainer,
} from './BuyOrderModal.styled'

import {
  ModalTitle,
  ModalTxText,
  ModalTxLink,
  ModalScrollbar,
  ModalTextDiv,
} from '../Modal/Modal.styled'

export default function BuyOrderModal({
  isOpen,
  onClose,
  orderInformation,
  allTokensBefore,
  token,
}) {
  const navigate = useNavigate()
  const { userAddress, mrunBalance, chainId } = useContext(UserContext)
  const [allowance, setAllowance] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState()

  const [error, setError] = useState('')

  async function closeModal() {
    setTxHash()
    setIsLoading(false)
    setError('')
    onClose()
  }

  function buyRequestFromContract() {
    setIsLoading(true)
    const regenerateObj = {
      amount: orderInformation.amount,
      creationTime: orderInformation.creationTime,
      expirationTime: orderInformation.expirationTime,
      id: orderInformation.id,
      price: orderInformation.price,
      salt: orderInformation.salt,
      seller: orderInformation.ownership.holder,
      tokenId: orderInformation.ownership.kind.contractTokenId,
    }

    const MetarunExchange = getMetarunExchange(chainId)

    MetarunExchange.buy(regenerateObj, orderInformation.signature)
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then((res) => {
            setTimeout(function testTokens() {
              getOwnerCards({ userAddress })
                .then((res) => {
                  if (res.count > allTokensBefore) {
                    setIsLoading(false)
                    setTxHash()
                    navigate('/')
                  } else {
                    setTimeout(testTokens, 2000)
                  }
                })
                .catch((e) => {
                  console.log(e)
                  setIsLoading(false)
                })
            }, 1000)
          })
          .catch((e) => {
            console.log(e)
            setIsLoading(false)
          })
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }

  async function getAllowance(abortController) {
    const MRun = getMetarunToken(chainId)

    await MRun.allowance(
      userAddress,
      networks[chainId].metarunExchangeContract.address,
    )
      .then((allowance) => {
        const aborted = abortController.signal.aborted
        if (!aborted) {
          setAllowance(allowance.toString())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function getApprove() {
    const MRun = getMetarunToken(chainId)
    setIsLoading(true)
    if (Number(orderInformation.price) > Number(allowance)) {
      await MRun.approve(
        networks[chainId].metarunExchangeContract.address,
        ethers.constants.MaxUint256,
      )
        .then((tx) => {
          setTxHash(tx.hash)
          tx.wait()
            .then(() => {
              setIsLoading(false)
              getAllowance()
              buyRequestFromContract()
            })
            .catch((err) => {
              console.log(err)
              setIsLoading(false)
            })
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    } else {
      buyRequestFromContract()
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (
      userAddress &&
      SUPPORTED_CHAINS.includes(chainId) &&
      networks[chainId].metarunTokenContract
    ) {
      getAllowance(abortController)
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [userAddress, chainId])

  return (
    <ModalComponent isOpen={isOpen} onClose={closeModal}>
      <ModalTitle margin>Buy order</ModalTitle>
      <ModalMain>
        {isLoading ? (
          <ModalTextContainer>
            <Spinner size="80px" />
            {!txHash ? (
              <ModalTxText>Await confirmation</ModalTxText>
            ) : (
              <ModalTxLink
                target="_blank"
                href={
                  networks[chainId]
                    ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                    : ''
                }
              >
                TxHash: {shortenAddress(txHash)}
              </ModalTxLink>
            )}
          </ModalTextContainer>
        ) : (
          <ModalTextContainer>
            <ModalTextDiv>
              You are buying a token{' '}
              {token.kind &&
                token.kind.attributes &&
                token.kind.attributes.level && (
                  <>
                    with following characteristics:
                    <ModalBoldText>
                      - {token.kind.attributes.level} level
                    </ModalBoldText>
                    <ModalBoldText>
                      - {token.kind.attributes.actionAbility} action ability
                    </ModalBoldText>
                    <ModalBoldText>
                      - {token.kind.attributes.manaAbility} mana ability
                    </ModalBoldText>
                  </>
                )}
              for {Number(ethers.utils.formatEther(orderInformation.price))}{' '}
              MRUN
            </ModalTextDiv>
            {Number(orderInformation.price) > Number(allowance) && (
              <>
                <Border />
                <ModalScrollbar buy>
                  <ModalTextDiv>
                    To approve Metarun to receive payment in{' '}
                    <ModalTextWrapper> MRUN</ModalTextWrapper>, you must first
                    complete a free (excluding Gas) transaction. Please confirm
                    this in your wallet and keep this modal open!
                  </ModalTextDiv>
                  <ModalTextDiv>
                    You may notice a very large number being requested for
                    approval. This is simply the maximum amount, meaning you’ll
                    never have to do this approval again. It also doesn’t give
                    permission for Metarun to take this amount from you. The
                    price of the order is all that will be charged.
                  </ModalTextDiv>
                </ModalScrollbar>
              </>
            )}
          </ModalTextContainer>
        )}
        <ModalError>{error}</ModalError>
        {!isLoading && (
          <Button
            size="extralong"
            variant="contained"
            onClick={() => {
              getApprove()
            }}
            disabled={
              Number(mrunBalance) <
                Number(ethers.utils.formatEther(orderInformation.price)) ||
              !(
                networks[chainId].metarunExchangeContract ||
                !networks[chainId].metarunTokenContract
              )
            }
          >
            Buy
          </Button>
        )}
      </ModalMain>
    </ModalComponent>
  )
}
