import { Wrapper, Title, IconWrapper, Arrow, Content } from './Accordion.styled'

const Accordion = ({
  children,
  title,
  icon,
  isOpen,
  setIsOpen,
  disabled,
  ...props
}) => {
  const openHandler = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <Wrapper className={isOpen && 'open'} {...props}>
      <Title onClick={openHandler} disabled={disabled}>
        <IconWrapper>{icon}</IconWrapper>
        {title}
        <Arrow
          src={require('../../images/ArrowDown.svg').default}
          alt="arrow"
        />
      </Title>
      {isOpen && <Content>{children}</Content>}
    </Wrapper>
  )
}

export default Accordion
