import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const RunsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const RunsNavigation = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1020px;

  @media (max-width: 1255px) {
    flex-direction: column;
    gap: 16px;
    align-items: start;
  }
`

export const RunsLinks = styled.div``

export const RunsSummary = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  @media (max-width: 1255px) {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    justify-content: space-between;
  }
`

export const SummaryItems = styled.div`
  display: flex;
  gap: 52px;
  align-items: center;
  margin-left: 56px;

  @media (max-width: 768px) {
    gap: 42px;
    margin-left: 30px;
  }
`

export const ItemImg = styled.img`
  position: absolute;
  left: -28px;
`

export const SummaryItem = styled.p`
  position: relative;
  margin: 0;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: 1203px) {
    text-align: center;
  }
`

export const SummaryItemButtonsContainer = styled.div`
  margin: 0;

  border: 1px solid #1e1e35;
  background: rgba(16, 16, 44, 0.7);
  border-radius: 12px;
  backdrop-filter: blur(15px);
  width: max-content;
`

export const SummaryItemButton = styled.button`
  background: transparent;
  background: ${({ active }) => {
    if (active) {
      return 'linear-gradient(157.67deg, rgba(72, 157, 254, 0.63) 0.4%, rgba(34, 7, 90, 0.42) 87.86%);'
    }
    return 'transparent'
  }};
  border: none;
  border-radius: 12px;
  padding: 9px 13px;
  padding: ${({ active }) => {
    if (active) {
      return '9px 16px'
    }
    return ' 9px 13px '
  }};
  color: ${({ active }) => {
    if (active) {
      return 'white'
    }
    return ' #7A97DF '
  }};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-align: right;
  cursor: pointer;
  transition: 1s background;

  &:hover {
    opacity: 0.7;
    opacity: ${({ active }) => {
      if (active) {
        return '1'
      }
      return '0.7'
    }};
  }
`

export const RunsLink = styled(Link)`
  text-decoration: none;
  margin: 0 20px 0 0;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  color: ${({ current }) => {
    if (current === 'true') {
      return 'white'
    }
    return ' #7A97DF '
  }};
  &:hover {
    opacity: ${({ current }) => {
      if (current === 'true') {
        return '1'
      }
      return '0.7'
    }};
  }

  @media (max-width: 950px) {
    font-size: 18px;
  }
`

export const RunsTable = styled.div`
  border: 1px solid rgba(73, 158, 255, 0.3);
  border-radius: 20px;
  max-width: 1020px;
  box-sizing: border-box;
  background: #10102ccc;
  padding: 32px;
  margin-bottom: 20px;

  @media (max-width: 950px) {
    border-radius: 12px;
    padding: 12px 10px;
    margin-bottom: 16px;
  }
`

export const RunsTableContainer = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;
  @media (max-width: 950px) {
    flex-direction: row;
    justify-content: space-around;
    gap: 6px;
  }
`

export const RunsTableHead = styled.div`
  display: grid;
  grid-template-columns: 20% 22% 25% 18% 15%;
  grid-template-rows: repeat(1, 1fr);
  padding: 0 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;

  color: #7a97df;

  margin-bottom: 24px;

  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 40px;
    font-size: 14px;
    text-align: left;
    padding: 12px 0;
    flex-grow: 1;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    display: ${({ hidden }) => {
      if (hidden) {
        return 'none'
      }
      return 'grid'
    }};
  }
`

export const RunsTableRow = styled.div`
  display: grid;
  min-width: 100px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  grid-template-columns: 20% 22% 25% 18% 15%;
  grid-template-rows: repeat(1, 1fr);
  padding: 22px 32px;
  background: ${({ color }) => {
    switch (color) {
      case 'loser':
        return ' rgba(237, 73, 21, 0.1)'
      case 'gold':
        return 'rgba(242, 209, 37, 0.1)'
      case 'silver':
        return 'rgba(196, 239, 249, 0.1)'
      case 'bronze':
        return 'rgba(149, 120, 61, 0.1)'
      default:
        return 'rgba(57, 221, 136, 0.1)'
    }
  }};

  backdrop-filter: blur(38px);
  margin-bottom: 8px;
  border-radius: 20px;
  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 12px 0;
    gap: 40px;
    margin-bottom: 0;
    flex-grow: 1;
    border-radius: 12px;
  }
`
export const RunsTableItem = styled.p`
  margin: 0;
  background: ${({ color }) => {
    switch (color) {
      case 'loser':
        return 'linear-gradient(142.61deg, #E68870 6.77%, #D24724 81.42%) '
      case 'winner':
        return 'linear-gradient(142.61deg, #5CE7B3 6.77%, #12D15A 81.42%)'
      case 'head':
        return '#7a97df'
      case 'gold':
        return '#F2D125'
      case 'silver':
        return '#C4EFF9'
      case 'bronze':
        return '#C8AB71'
      default:
        return 'white'
    }
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  &:first-child {
    text-align: left;
  }
  @media (max-width: 950px) {
    &:first-child {
      text-align: center;
    }
  }
`
export const RunsTableItemLeft = styled.p`
  margin: 0;

  text-align: left;
  position: relative;
  left: -20px;
  @media (max-width: 950px) {
    text-align: center;
    left: 0;
  }
`

export const RunsTableItemContainer = styled.span`
  position: relative;
  background-color: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 30px;
  @media (max-width: 950px) {
    left: 10px;
  }
`

export const RunsTableImg = styled.img`
  position: absolute;
  left: -28px;
  top: -2px;
`

export const RunsTableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-top: 17px;
  box-sizing: border-box;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
  }
`

export const RunsTableFooterItem = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
  @media (max-width: 950px) {
    font-size: 14px;
    line-height: 24px;
  }
`

export const RunsTx = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
`

export const RunsStatus = styled.span`
  position: relative;
  top: 5px;
  left: 0px;
`

export const HiddenTitle = styled.span`
  @media (max-width: 1050px) {
    display: none;
  }
`

export const HiddenText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`

export const MobileText = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`
