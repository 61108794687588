import styled from 'styled-components'

export const ModalAddress = styled.span`
  font-weight: 700;
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalImage = styled.img`
  margin: 0 auto 24px;
`
export const ModalWrapper = styled.div`
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`
