const host = process.env.REACT_APP_API_HOST

export async function getWithdrawal(userAddress) {
  return await fetch(`${host}/withdrawal/?address=${userAddress}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function placeWithdrawal(
  signature,
  timestamp,
  amount,
  currency,
  userAddress,
) {
  return await fetch(`${host}/payment/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      signature,
      timestamp,
      amount,
      currency,
      userAddress,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw response
    })
    .catch((err) => {
      throw err
    })
}
