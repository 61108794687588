import { ethers } from 'ethers'
import networks from '../../networks.json'
import MetarunCollectionAbi from '../../abis/MetarunCollectionAbi.json'
import MetarunExchangeAbi from '../../abis/MetarunExchangeAbi.json'
import MetarunTokenAbi from '../../abis/MetarunTokenAbi.json'
import BUSDAbi from '../../abis/BUSDAbi.json'
import MetarunVaultAbi from '../../abis/MetarunVaultAbi.json'
import MysteryBoxRevealAbi from '../../abis/MysteryBoxRevealAbi.json'
import MetarunSwapAbi from '../../abis/MetarunSwapAbi.json'
import MetarunSaleAbi from '../../abis/MetarunSaleAbi.json'

export function getMetarunCollection(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const MetarunCollection = new ethers.Contract(
    networks[chainId].metarunCollectionContract.address,
    MetarunCollectionAbi,
    signer,
  )
  return MetarunCollection
}

export function getMetarunExchange(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const MetarunExchange = new ethers.Contract(
    networks[chainId].metarunExchangeContract.address,
    MetarunExchangeAbi,
    signer,
  )
  return MetarunExchange
}

export function getMetarunToken(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const MetarunToken = new ethers.Contract(
    networks[chainId].metarunTokenContract.address,
    MetarunTokenAbi,
    signer,
  )
  return MetarunToken
}

export function getBUSD(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const BUSD = new ethers.Contract(
    networks[chainId].busdContract.address,
    BUSDAbi,
    signer,
  )
  return BUSD
}

export function getMetarunVault(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const MetarunVault = new ethers.Contract(
    networks[chainId].metarunVaultContract.address,
    MetarunVaultAbi,
    signer,
  )
  return MetarunVault
}

export function getMysteryBoxReveal(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const MysteryBoxReveal = new ethers.Contract(
    networks[chainId].mysteryBoxRevealContract.address,
    MysteryBoxRevealAbi,
    signer,
  )
  return MysteryBoxReveal
}

export function getMetarunSwap(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const MetarunSwap = new ethers.Contract(
    networks[chainId].metarunSwapContract.address,
    MetarunSwapAbi,
    signer,
  )
  return MetarunSwap
}

export function getMetarunSale(chainId) {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const MetarunSale = new ethers.Contract(
    networks[chainId].metarunSaleContract.address,
    MetarunSaleAbi,
    signer,
  )
  return MetarunSale
}
