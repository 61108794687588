import styled from 'styled-components'

export const ModalForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalButton = styled.a`
  border: 1px solid #5f51ff;
  border-radius: 20px;
  backdrop-filter: blur(38px);

  background: transparent url(${require(`../../images/Pancake.svg`).default})
    13px 50%/38px no-repeat;
  text-decoration: none;
  color: white;
  padding: 20px 20px 20px 60px;
  margin-top: 24px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;

  &:hover {
    opacity: 0.6;
  }
`

export const ModalButtonText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
`
