import { useContext } from 'react'
import networks from '../../networks.json'
import { SUPPORTED_CHAINS } from '../../constants'
import { UserContext } from '../../contexts/UserContext'

import {
  NetworkContainer,
  NetworkText,
  NetworkImg,
} from './NetworkIndicator.styled'
import { getNetworkIcon } from '../../utils'

export default function NetworkIndicator({ network, ...args }) {
  const { userAddress } = useContext(UserContext)

  if (SUPPORTED_CHAINS.includes(network) && userAddress) {
    return (
      <NetworkContainer {...args}>
        <NetworkImg
          src={getNetworkIcon(+network)}
          alt="bnb"
          width="32"
          height="32"
        ></NetworkImg>
        <NetworkText>{networks[network].name}</NetworkText>
      </NetworkContainer>
    )
  }
  return <></>
}
