import styled from 'styled-components'

export const CardsGrid = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: repeat(auto-fill, 324px);
  grid-template-rows: repeat(1, 1fr);
  justify-content: center;

  @media (max-width: 1052px) {
    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: repeat(auto-fill, 230px);
  }

  @media (max-width: 768px) {
    column-gap: 12px;
    row-gap: 12px;
    grid-template-columns: repeat(auto-fill, 162px);
  }
`

export const SortingPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  @media (max-width: 1290px) {
    align-items: start;
    flex-direction: column;
    padding-bottom: 4px;
    gap: 8px;
  }
`

export const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 900px;
  @media (max-width: 768px) {
    flex-basis: 500px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`

export const ButtonImg = styled.img`
  position: absolute;
  left: 25px;
  top: 14px;
  width: 18px;
  height: 18px;
`

export const SortingButton = styled.button`
  font-family: OpenSans;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  background: #0f0e2c;
  display: block;
  padding: 12px 60px;
  border-radius: 20px;

  color: white;

  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 12px;
    width: 100%;
    margin-bottom: 8px;
    text-align: left;
    font-family: OpenSans;
    font-size: 14px;
    letter-spacing: 0px;
  }
`

export const SortingWrapper = styled.div`
  position: relative;
  margin-left: 12px;
  @media (max-width: 1290px) {
    margin-left: 0;
  }
`

export const PanelTextContainer = styled.p`
  margin: 0;
  text-transform: uppercase;
  display: flex;
  gap: 16px;
  color: white;

  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: left;
  border-bottom: 1px solid rgba(122, 151, 223, 0.3);
  @media (max-width: 1050px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 5px;
  }
`

export const PanelText = styled.span`
  color: ${({ active }) => {
    if (active) {
      return '#9fa1a6'
    }
    return '#7a97df'
  }};
`

export const PanelMainText = styled.span`
  color: ${({ active }) => {
    if (active) {
      return 'white'
    }
    return '#7a97df'
  }};
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    height: ${({ active }) => {
      if (active) {
        return '1px'
      }
      return '0'
    }};
    z-index: 1;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(270deg, #5e51ff 1.49%, #489dfe 100.89%);
  }
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 0 auto;
  padding: 15px 20px;
  flex-grow: 1;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: initial;
    padding: 12px;
  }
`

export const HiddenText = styled.span`
  @media (max-width: 880px) {
    display: none;
  }
`

export const SortingList = styled.div`
  position: absolute;
  z-index: 2;
  border-radius: 20px;
  width: 100%;
  background: #10102ce5;
  top: 52px;
  flex-direction: column;
  display: ${({ show }) => {
    if (show) {
      return 'flex'
    }
    return 'none'
  }};
`
export const SortingListItem = styled.p`
  margin: 0;
  color: white;
  position: relative;
  padding: 15px 20px;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  &:first-child {
    border-bottom: 1px solid #ffffff29;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const GalleryMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  flex-grow: 1;
  box-sizing: border-box;
  margin-top: 200px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`

export const GalleryMainText = styled.p`
  margin: 0;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  color: white;
`
