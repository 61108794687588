import styled from 'styled-components'

export const SettingsContainer = styled.div`
  width: 100%;
  max-width: 1020px;
  backdrop-filter: blur(10px);
  padding: 0px;
  position: relative;
`

export const SettingsTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0 0 16px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
  }
`

export const SettingsFormContainer = styled.div`
  background-color: rgba(16, 16, 44, 0.7);
  border: 1px solid rgba(73, 158, 255, 0.3);
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 40px 40px;
  @media (max-width: 768px) {
    padding: 24px;
  }
`

export const SettingsForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const SettingsInputContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
  position: relative;
  &:first-of-type {
    margin-right: 32px;
  }

  @media (max-width: 768px) {
    &:first-of-type {
      margin-bottom: 24px;
      margin-right: 0px;
    }
    &:last-of-type {
      margin-bottom: 32px;
    }
  }
`
export const SettingsInputLabel = styled.label`
  box-sizing: border-box;
  display: block;
  color: #a3a4a9;
  margin-bottom: 10px;
  position: absolute;
  top: 18px;
  left: 23px;
  height: 100%;
  padding: 1px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;

  font-size: 12px;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;

  @media (max-width: 768px) {
    line-height: 19px;
    top: 18.5px;
    left: 23px;
  }
`
export const SettingsInput = styled.input`
  font-family: 'OpenSans';
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 24px;

  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.5px;
  padding: 36px 24px 20px;
  color: #a3a4a9;

  background-color: #1d1f2a;
  background-color: ${({ invalid }) => {
    if (invalid) {
      return '#ED49151A '
    }
    return 'inherit'
  }};
  background-clip: padding-box;
  border: 1px solid #2f313b;
  border: ${({ invalid }) => {
    if (invalid) {
      return ' 1px solid #322328'
    }
    return ' 1px solid #2f313b'
  }};

  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: white;
  }

  &:focus {
    color: white;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(158, 158, 158, 0.25);
  }

  &:disabled {
    background-color: #1d1f2a;
    color: #a3a4a9;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 41.5px 24px 28px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
