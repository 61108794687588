import styled from 'styled-components'

export const ReferralContainer = styled.div`
  width: 100%;
  max-width: 1020px;
  backdrop-filter: blur(10px);
  padding: 0px;
  position: relative;
`

export const ReferralTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0 0 16px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const ReferralFormContainer = styled.div`
  background-color: rgba(16, 16, 44, 0.7);
  border: 1px solid rgba(73, 158, 255, 0.3);
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 40px 40px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    padding: 24px;
  }
`

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ReferralInputContainer = styled.div`
  box-sizing: border-box;
  width: 100%;

  position: relative;
`
export const ReferralInputLabel = styled.label`
  box-sizing: border-box;
  display: block;
  color: #a3a4a9;
  margin-bottom: 10px;
  position: absolute;
  top: 18px;
  left: 23px;
  height: 100%;
  padding: 1px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;

  font-size: 12px;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 19px;
    top: 8px;
    left: 15px;
  }
`
export const ReferralInput = styled.input`
  font-family: 'OpenSans';
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 24px;

  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.5px;
  padding: 40px 172px 26px 24px;
  color: #9fa1a6;

  background-color: rgba(16, 16, 44, 0.7);

  background-clip: padding-box;
  border: 1px solid #2f313b;

  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: 0;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    border-radius: 12px;

    padding: 24px 105px 10px 16px;
  }
`

export const ButtonContainer = styled.div`
  position: absolute;
  right: 25px;
  top: 27px;
  @media (max-width: 768px) {
    right: 18px;
    top: 14px;
  }
`
export const RewardsTable = styled.div`
  border: 1px solid rgba(73, 158, 255, 0.3);
  border-radius: 20px;
  max-width: 1020px;
  box-sizing: border-box;
  background: #10102ccc;
  padding: 32px;
  margin-bottom: 20px;

  @media (max-width: 950px) {
    border-radius: 12px;
    padding: 12px 10px;
    margin-bottom: 16px;
    padding: 0;
    border: none;
    background: transparent;
  }
`

export const RewardsTableContainer = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;
  @media (max-width: 950px) {
    justify-content: space-around;
    gap: 8px;
  }
`

export const RewardsTableHead = styled.div`
  display: grid;
  grid-template-columns: 30% 28% 20% 22%;
  grid-template-rows: repeat(1, 1fr);
  padding: 0 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;

  color: #7a97df;

  margin-bottom: 24px;

  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 8px;
    font-size: 14px;
    text-align: left;
    padding: 12px 0;
    flex-grow: 1;
    margin-bottom: 0;
    display: none;
  }
`

export const RewardsTableItem = styled.div`
  margin: 0;
  margin-right: ${({ margin }) => {
    if (margin) {
      return '25px'
    }
    return '0'
  }};
  color: ${({ color }) => {
    switch (color) {
      case 'head':
        return '#7a97df'
      default:
        return 'white'
    }
  }};

  text-align: center;

  &:first-child {
    text-align: left;
  }
  @media (max-width: 950px) {
    &:first-child {
      text-align: center;
    }
    display: flex;
    justify-content: space-between;
    background: ${({ color }) => {
      switch (color) {
        case 'odd':
          return 'transparent'
        default:
          return 'rgba(196, 239, 249, 0.1)'
      }
    }};
    border-radius: 6px;
    padding: 4px 12px;
  }
`

export const RewardsTableItemLeft = styled.p`
  margin: 0;

  text-align: left;
  position: relative;

  @media (max-width: 950px) {
    text-align: center;
    left: 0;
  }
`

export const RewardsTableRow = styled.div`
  display: grid;
  min-width: 100px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  grid-template-columns: 30% 28% 20% 22%;
  grid-template-rows: repeat(1, 1fr);
  padding: 22px 32px;
  background: ${({ color }) => {
    switch (color) {
      case 'odd':
        return 'transparent'
      default:
        return 'rgba(196, 239, 249, 0.1)'
    }
  }};

  backdrop-filter: blur(38px);
  margin-bottom: 8px;
  border-radius: 20px;
  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 12px 0;
    gap: 8px;
    margin-bottom: 0;
    flex-grow: 1;
    border-radius: 12px;
    border: 1px solid rgba(73, 158, 255, 0.3);
    padding: 10px 6px;
    background: #10102ccc;
  }
`

export const RewardsTableItemText = styled.p`
  margin: 0;
  display: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #7a97df;

  @media (max-width: 950px) {
    display: block;
  }
`

export const RewardsTableItemValue = styled.p`
  margin: 0;
  overflow: hidden;
  max-width: 300px;
  @media (max-width: 950px) {
    max-width: 250px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: right;
  }
`
