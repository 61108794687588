import React, { useState, useEffect, useContext } from 'react'
import validator from 'validator'
import { setUserInfo } from '../../api'
import { Button } from '../../components/Button'
import { UserContext } from '../../contexts/UserContext'

import {
  SettingsContainer,
  SettingsFormContainer,
  SettingsForm,
  Container,
  SettingsInput,
  SettingsInputContainer,
  SettingsInputLabel,
  ButtonContainer,
  SettingsTitle,
} from './Settings.styled'

export default function Settings() {
  const { userAddress, username, email, accessToken, getUserInfo } = useContext(
    UserContext,
  )
  const [newUsername, setNewUsername] = useState()

  const handleNewUsername = (e) => {
    setNewUsername(e.target.value)
  }

  async function setInfo(e) {
    e.preventDefault()

    await setUserInfo(newUsername, userAddress, accessToken)
      .then(() => {
        getUserInfo()
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    setNewUsername(username)
  }, [username])

  return (
    <SettingsContainer>
      <SettingsTitle>Set username</SettingsTitle>
      <SettingsFormContainer>
        <SettingsForm onSubmit={setInfo}>
          <Container>
            <SettingsInputContainer>
              <SettingsInputLabel>Username</SettingsInputLabel>
              <SettingsInput
                invalid={
                  newUsername === '' ||
                  (newUsername && !validator.isAlphanumeric(newUsername))
                }
                onChange={handleNewUsername}
                value={newUsername ?? username ?? ''}
              />
            </SettingsInputContainer>
            <SettingsInputContainer>
              <SettingsInputLabel>Email</SettingsInputLabel>
              <SettingsInput value={email ?? ''} type="email" disabled />
            </SettingsInputContainer>
          </Container>
          <ButtonContainer>
            <Button
              size="accountSettings"
              type="submit"
              variant="accountSettings"
              disabled={
                username === newUsername ||
                newUsername === '' ||
                (newUsername && !validator.isAlphanumeric(newUsername))
              }
            >
              Save
            </Button>
          </ButtonContainer>
        </SettingsForm>
      </SettingsFormContainer>
    </SettingsContainer>
  )
}
