import {
  StyledCard,
  CardImage,
  InfoHeadContainer,
  ItemsContainer,
  ItemsImage,
  CharacterKinds,
  CharacterKindContainer,
  CharacterKind,
  CharacterKindText,
} from './Characters.styled'

export default function CharactersCard({ cardData }) {
  return (
    <>
      {cardData ? (
        <StyledCard>
          <InfoHeadContainer>
            <CharacterKinds>
              <CharacterKindContainer type={cardData.kind.tokenKind}>
                <CharacterKind>
                  <CharacterKindText type={cardData.kind.tokenKind}>
                    {cardData.kind.tokenKind}
                  </CharacterKindText>
                </CharacterKind>
              </CharacterKindContainer>
              <CharacterKindContainer type={cardData.kind.tokenRarity}>
                <CharacterKind>
                  <CharacterKindText type={cardData.kind.tokenRarity}>
                    {cardData.kind.tokenRarity}
                  </CharacterKindText>
                </CharacterKind>
              </CharacterKindContainer>
            </CharacterKinds>
            <ItemsContainer>
              <ItemsImage
                src={require('../../images/Items.svg').default}
                alt="items"
                data-testid="custom-element"
                width="14"
                height="18"
              />
              {cardData.balance}
            </ItemsContainer>
          </InfoHeadContainer>
          <CardImage
            src={cardData.kind && cardData.kind.imageUri}
            alt="token"
            width="321"
            height="363"
          />
        </StyledCard>
      ) : (
        ''
      )}
    </>
  )
}
