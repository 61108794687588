import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ButtonLinkWrapper = styled.div`
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
`

export const StyledCard = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  max-width: 324px;
  min-width: 162px;
  background: linear-gradient(
    184.67deg,
    rgba(72, 157, 254, 0.63) 1.89%,
    rgba(34, 7, 90, 0.42) 96.23%
  );
  border: ${({ border }) => {
    if (border) {
      return ' 1px solid  #3970FE'
    }
    return 'none'
  }};
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  padding: 12px 0;
  @media (max-width: 768px) {
    padding: 6px 0;
    border-radius: 12px;
  }
`

export const StyledBorder = styled.div`
  border-radius: 12px;
  color: #122dff;
  display: inline-block;
  font-size: 20px;
  padding: 1px;
  text-decoration: none;
  background: ${({ border }) => {
    if (border) {
      return ' linear-gradient(167.65deg, #5F59FF 0%, #5F59FF 95.41%)'
    }
    return ' #262a35'
  }};
`
export const CardImage = styled.img`
  min-height: 340px;
  max-height: 350px;
  min-width: 321px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 1052px) {
    min-height: 250px;
    min-width: 230px;
    max-height: 250px;
    width: 100%;
    max-width: 230px;
  }
  @media (max-width: 768px) {
    min-height: 172px;
    min-width: 160px;
    max-height: 172px;
    width: 100%;
    max-width: 167px;
  }
`

export const CardContent = styled.div`
  margin-top: auto;
`

export const InfoContainer = styled.div`
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    padding: 0 8px;
  }
`

export const InfoHeadContainer = styled.div`
  min-height: 52px;
  margin-bottom: 8px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1052px) {
    padding: 0 12px;
    min-height: 30px;
    margin-bottom: 13px;
  }
  @media (max-width: 768px) {
    padding: 0 8px;
    margin-bottom: 4px;
    min-height: 0;
  }
`
export const KindContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-size: 16px;

  font-weight: 700;
  font-style: italic;
  line-height: 26px;
  letter-spacing: 0.5px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0px;
    align-items: center;
  }
`

export const KindImageContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin-right: 8px;
  background: ${({ tokenKind }) => {
    switch (tokenKind) {
      case 'Character':
        return '#65b905'
      case 'Ticket':
        return '#499eff'
      case 'Mystery Box':
        return '#4e037c'
    }
    return '#E58857'
  }};
  border-radius: 12px;
  @media (max-width: 768px) {
    height: 18px;
    width: 18px;
    border-radius: 5px;
  }
`
export const KindImage = styled.img`
  height: 20px;
  width: 20px;
  color: green;

  @media (max-width: 768px) {
    height: 10px;
    width: 10px;
  }
`
export const KindInfo = styled.b`
  margin: 0;
  padding: 0;
  color: #9cacfe;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.5px;
  align-self: center;
  font-style: italic;
  font-weight: 700;
  position: relative;
  top: -16px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 5px;
    letter-spacing: 0px;
    top: -7px;
  }
`
export const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  color: #a4bad7;
  font-size: 16px;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 140px;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0px;
    max-width: 50px;
  }
`

export const ItemsImage = styled.img`
  height: 18px;
  width: 14px;
  margin-right: 7px;
  margin-top: 4px;
  align-self: flex-start;
  @media (max-width: 768px) {
    height: 12px;
    width: 9px;
    align-self: center;
    margin-right: 4px;
    margin-top: 1px;
  }
`
export const PriceContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #7a97df;
  overflow: hidden;
  max-width: 160px;

  @media (max-width: 1052px) {
    max-width: 100px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    max-width: 65px;
    line-height: 15px;
    letter-spacing: 0px;
  }
`
export const PriceWrapper = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-style: italic;
  line-height: 38px;
  letter-spacing: 0.5px;
  color: #ffffff;
  @media (max-width: 1052px) {
    font-size: 13px;
  }
  @media (max-width: 768px) {
    font-size: 11px;

    line-height: 22px;
    letter-spacing: 0.5px;
  }
`

export const PriceImage = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
  @media (max-width: 768px) {
    height: 16px;
    width: 16px;
  }
`
export const Border = styled.div`
  height: 1px;
  margin: 0 16px;
  background: rgba(73, 158, 255, 0.35);
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );
  @media (max-width: 768px) {
    margin: 0 8px;
  }
`

export const OwnerContainer = styled.div`
  padding: 16px 0 12px;
  display: flex;
  margin: 0 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #a4bad7;

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 8px 0 6px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.5px;
    margin: 0 8px;
  }
`

export const OwnerImage = styled.img`
  height: 48px;
  width: 48px;
  margin: auto 0;
  margin-right: 8px;

  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`

export const OwnerAddress = styled.div`
  font-size: 16px;
  color: #7a97df;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 10px;

    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.5px;
  }
`
export const OwnerAddressItem = styled.p`
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`

export const HiddenText = styled.span`
  @media (max-width: 1052px) {
    display: none;
  }
`

export const CharacterKinds = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    gap: 2px;
    max-width: 140px;
  }
`
export const CharacterKindContainer = styled.li`
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  border-radius: 6px;
  transform: skew(-13deg, 0);
  padding: 1px;
`

export const CharacterKind = styled.div`
  padding: 3px;
  border-radius: 6px;
  min-width: 104px;
  box-sizing: border-box;
  background: #182f52;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1052px) {
    min-width: 70px;
    padding: 2px 3px;
  }
  @media (max-width: 768px) {
    min-width: 55px;
    padding: 0 3px;
  }
`
export const CharacterKindText = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 1052px) {
    font-size: 12px;
    line-height: 20px;
  }
  @media (max-width: 768px) {
    font-size: 9px;
    line-height: 18px;
  }
`
