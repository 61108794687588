import React, { useState, useEffect, useContext, useReducer } from 'react'
import BuyOpalModal from '../BuyOpalModal'
import BuyMTRunModal from '../BuyMTRunModal'
import BuyMRUNModal from '../BuyMRUNModal'
import SwitchNetworkModal from '../SwitchNetworkModal'
import Close from '../../images/WhiteClose.svg'
import networks from '../../networks.json'
import { EIP712_CHAIN_ID, SUPPORTED_CHAINS } from '../../constants'
import { getMetarunToken } from '../../api/contracts'
import { getWithdrawal } from '../../api'
import { UserContext } from '../../contexts/UserContext'
import {
  MainContainer,
  BalancesContainer,
  InfoContainer,
  InfoContainerCenter,
  InfoBalance,
  InfoImg,
  ImgBorder,
  InfoBalanceContainer,
  InfoBalanceDescription,
  InfoImgContainer,
  PromptInfo,
  PromptLayout,
  PromptImg,
  PromptText,
  PromptTitle,
  CloseButtonContainer,
  CloseButton,
  PromptContainer,
  AddButton,
  PromptRouterLink,
  Tooltip,
  TooltipWrapper,
} from './MainMenu.styled'

function MainMenu({ forceUpdateBuy }) {
  const {
    userAddress,
    mrunBalance,
    opalBalance,
    mtrunBalance,
    chainId,
    playfabId,
    accessToken,
    email
  } = useContext(UserContext)
  const [buyOpalIsOpen, setbuyOpalIsOpen] = useState(false)
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const [buyMTRunIsOpen, setbuyMTRunIsOpen] = useState(false)
  const [buyMRunIsOpen, setbuyMRunIsOpen] = useState(false)
  const [mrunAllowance, setMrunAllowance] = useState(0)
  const [withdrawals, setWithdrawals] = useState({})

  // const [homePromptIsOpen, setHomePromptIsOpen] = useState(false)
  const [swtichNetworkModalOpen, setSwitchtNetworkModalOpen] = useState(false)

  const [forced, forceUpdate] = useReducer((x) => x + 1, 0)

  const homePrompt = (
    <PromptInfo>
      <PromptImg
        src={require('../../images/Metarun_3D.png')}
        alt="prompt"
        width="166"
        height="157"
        type="mrun"
      />
      <PromptContainer>
        <PromptTitle>How cool!</PromptTitle>
        <PromptText>
          Now when you bring a friend to Metarun you can get rewards! 🎉 🎉 🎉
          <br />
          Find out more here:{' '}
          <PromptRouterLink to="/profile/rewards">
            marketplace.metarun.game/profile/rewards
          </PromptRouterLink>
        </PromptText>
      </PromptContainer>
      <CloseButtonContainer
      // onClick={handleCloseHomePrompt}
      >
        <CloseButton src={Close} alt="close"></CloseButton>
      </CloseButtonContainer>
    </PromptInfo>
  )

  function handleBuyOpalOpen() {
    if (Number(chainId) === EIP712_CHAIN_ID) {
      setbuyOpalIsOpen(true)
    } else {
      setSwitchtNetworkModalOpen(true)
    }
  }

  function handleBuyOpalClose() {
    setbuyOpalIsOpen(false)
  }

  function handleBuyMTRunOpen() {
    setbuyMTRunIsOpen(true)
  }

  function handleBuyMTRunClose() {
    setbuyMTRunIsOpen(false)
  }

  function handleBuyMRunOpen() {
    setbuyMRunIsOpen(true)
  }

  function handleBuyMRunClose() {
    setbuyMRunIsOpen(false)
  }

  // function handleCloseHomePrompt() {
  //   window.localStorage.setItem('CloseHomePrompt', true)
  //   setHomePromptIsOpen(false)
  // }

  async function getAllowance(abortController) {
    const MetarunToken = getMetarunToken(chainId)

    await MetarunToken.allowance(
      userAddress,
      networks[chainId].metarunVaultContract.address,
    )
      .then((allowance) => {
        const aborted = abortController.signal.aborted
        if (!aborted) {
          setMrunAllowance(allowance.toString())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function fetchWithdrawals() {
    await getWithdrawal(userAddress)
      .then(({ results }) => {
        const res = results[0].currencies
        setWithdrawals(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (userAddress) {
      fetchWithdrawals()
    }
  }, [userAddress, forced])

  useEffect(() => {
    const abortController = new AbortController()
    if (
      userAddress &&
      SUPPORTED_CHAINS.includes(chainId) &&
      networks[chainId].metarunTokenContract
    ) {
      getAllowance(abortController)
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [userAddress, chainId])

  // useEffect(() => {
  //   setHomePromptIsOpen(!window.localStorage.getItem('CloseHomePrompt'))
  // }, [accessToken, email])

  useEffect(() => {
    const tooltipTimeout = setTimeout(() => {
      setTooltipIsOpen(mrunBalance <= 0)
    }, 1000)

    return () => {
      clearInterval(tooltipTimeout)
    }
  }, [userAddress, mrunBalance])

  return (
    <>
      <MainContainer>
        <BalancesContainer>
          <InfoContainer variant="opal">
            <ImgBorder>
              <InfoImgContainer>
                <InfoImg
                  src={require('../../images/WhiteOpal.svg').default}
                  alt="opal"
                  width="24"
                  height="24"
                />
              </InfoImgContainer>
            </ImgBorder>
            <InfoBalanceContainer>
              <InfoBalance>{opalBalance} OPAL</InfoBalance>
              <InfoBalanceDescription>Balance</InfoBalanceDescription>
            </InfoBalanceContainer>
            <AddButton
              onClick={handleBuyOpalOpen}
              // disabled={!playfabId || !accessToken || !email}
              // type={playfabId && accessToken && email ? 'opal' : 'arrows'}
              disabled={true}
              type={'arrows'}
            >
              <InfoImg
                src={require('../../images/Arrows.svg').default}
                alt="plus"
                variant="arrows"
                width="32"
                height="32"
              />
            </AddButton>
          </InfoContainer>
          <InfoContainerCenter>
            <ImgBorder variant="mtrun">
              <InfoImgContainer variant="mtrun">
                <InfoImg
                  src={require('../../images/MTrun.svg').default}
                  alt="mtrun"
                  variant="mtrun"
                  width="38"
                  height="32"
                />
              </InfoImgContainer>
            </ImgBorder>
            <InfoBalanceContainer>
              <InfoBalance>{mtrunBalance} MTRUN</InfoBalance>
              <InfoBalanceDescription>Balance</InfoBalanceDescription>
            </InfoBalanceContainer>
            <AddButton
              onClick={handleBuyMTRunOpen}
              // disabled={!playfabId || !accessToken || !email}
              disabled={true}
              // type={playfabId && accessToken && email ? 'mtrun' : 'arrows'}
              type={'arrows'}
            >
              <InfoImg
                src={require('../../images/Arrows.svg').default}
                alt="plus"
                variant="arrows"
                width="32"
                height="32"
              />
            </AddButton>
          </InfoContainerCenter>
          <InfoContainer>
            <ImgBorder variant="violet">
              <InfoImgContainer variant="mrun">
                <InfoImg
                  src={require('../../images/SmallLogo.svg').default}
                  alt="mrun"
                  variant="mrun"
                  width="34"
                  height="24"
                />
              </InfoImgContainer>
            </ImgBorder>
            <InfoBalanceContainer>
              <InfoBalance>{Number(mrunBalance).toFixed(2)} MRUN</InfoBalance>
              <InfoBalanceDescription>Token Balance</InfoBalanceDescription>
            </InfoBalanceContainer>
            <TooltipWrapper>
              <Tooltip className={tooltipIsOpen ? 'opened' : 'hidden'}>
                First purchase!
              </Tooltip>
              <AddButton onClick={handleBuyMRunOpen} type="mrun">
                <InfoImg
                  src={require('../../images/Plus.svg').default}
                  alt="plus"
                  variant="plus"
                  width="23"
                  height="23"
                />
              </AddButton>
            </TooltipWrapper>
          </InfoContainer>
        </BalancesContainer>
        <PromptLayout promptIsOpen={false}>{homePrompt}</PromptLayout>
      </MainContainer>
      <BuyOpalModal
        isOpen={buyOpalIsOpen}
        onClose={handleBuyOpalClose}
        forceUpdateBuy={forceUpdateBuy}
        mrunAllowance={mrunAllowance}
        getAllowance={getAllowance}
        withdrawals={withdrawals}
        forceUpdate={forceUpdate}
      />
      <BuyMTRunModal
        isOpen={buyMTRunIsOpen}
        onClose={handleBuyMTRunClose}
        forceUpdateBuy={forceUpdateBuy}
        mrunAllowance={mrunAllowance}
        getAllowance={getAllowance}
        withdrawals={withdrawals}
        forceUpdate={forceUpdate}
      />
      <BuyMRUNModal isOpen={buyMRunIsOpen} onClose={handleBuyMRunClose} />
      <SwitchNetworkModal
        isOpen={swtichNetworkModalOpen}
        onClose={() => setSwitchtNetworkModalOpen(false)}
        info={`Please switch to ${networks[EIP712_CHAIN_ID]?.name} and login to buy/withdraw opal`}
      />
    </>
  )
}

export default MainMenu
