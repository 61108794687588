import ModalComponent from '../Modal'
import { ModalForm, ModalButton, ModalButtonText } from './BuyMRUNModal.styled'

import { ModalTitle, ModalTextDiv } from '../Modal/Modal.styled'

export default function BuyMRUNModal({ isOpen, onClose }) {
  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Buy MRUN</ModalTitle>
      <ModalForm>
        <ModalTextDiv type="buy-mrun">
          {' '}
          Go to market(opens a new window):
        </ModalTextDiv>
        <ModalButton
          href="https://pancakeswap.finance/swap?outputCurrency=0xaC6ec101DDcB953774d103bA4A82fA257138459f"
          target="_blank"
        >
          PancakeSwap
          <ModalButtonText>
            Simple, decentralized, directly in your wallet
          </ModalButtonText>
        </ModalButton>
      </ModalForm>
    </ModalComponent>
  )
}
