import { useState } from 'react'
import { utils } from 'ethers'
import { getMetarunToken } from '../api/contracts'
import { getIngameInfo } from '../api'

const useUserBalances = ({ userAddress, chainId }) => {
  const [userBalance, setUserBalance] = useState('0.00')
  const [mrunBalance, setMrunBalance] = useState('0.00')
  const [opalBalance, setOpalBalance] = useState('0')
  const [mtrunBalance, setMtrunBalance] = useState('0')

  function getUserBalance() {
    window.ethereum
      .request({ method: 'eth_getBalance', params: [userAddress, 'latest'] })
      .then((balance) => {
        const num = Number(utils.formatEther(balance))
        setUserBalance(num)
      })
      .catch((e) => console.log(e))
  }

  function getMrunBalance() {
    const MetarunToken = getMetarunToken(chainId)
    MetarunToken.balanceOf(userAddress)
      .then((res) => {
        const mrun = Number(utils.formatEther(res))
        setMrunBalance(mrun)
      })
      .catch((e) => console.log(e))
  }

  function getGameBalances(playfabId) {
    getIngameInfo(playfabId)
      .then(({ VirtualCurrency }) => {
        setOpalBalance(VirtualCurrency.OP)
        setMtrunBalance(VirtualCurrency.MT)
      })
      .catch((e) => console.log(e))
  }

  function resetBalances() {
    setUserBalance('0.00')
    setMrunBalance('0.00')
  }

  function resetGameBalances() {
    setOpalBalance('0')
    setMtrunBalance('0')
  }

  return {
    userBalance,
    getUserBalance,
    mrunBalance,
    getMrunBalance,
    resetBalances,
    opalBalance,
    mtrunBalance,
    getGameBalances,
    resetGameBalances,
  }
}

export default useUserBalances
