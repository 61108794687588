import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import PageContainer from '../../components/PageContainer'
import Header from '../../components/Header'
import Settings from '../../components/Settings'
import { shortenAddress } from '../../utils/shortenAddress'
import Runs from '../../components/Runs'
import { useCopyToClipboard } from '../../hooks'
import { UserContext } from '../../contexts/UserContext'
import { getSummary } from '../../api'
import {
  SideMenuContainer,
  SideMenuItem,
  ProfileContainer,
  SideMenuInfo,
  SideMenuAvatar,
  SideMenuAvatarContainer,
  SideMenuAvatarImg,
  SideMenuNickname,
  SideMenuAddress,
  SideMenuButton,
  SideMenuIcon,
  HiddenInput,
  SideMenuList,
  SideMenuInfoWrapper,
  SideMenuItemContainer,
  BackButton,
  SideMenuWrapper,
} from './Profile.styled'
import Referral from '../../components/Referral'
import Characters from '../../components/Characters'

function Profile() {
  const { userAddress, username } = useContext(UserContext)
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState({
    runs: false,
    settings: false,
    rewards: false,
    characters: false,
  })
  const [successCopy, setSuccessCopy] = useState(false)
  const [copyOnClick, ref] = useCopyToClipboard()
  const [showMenu, setShowMenu] = useState(false)

  const [summary, setSummary] = useState({})
  const [summaryIsLoading, setSummaryIsLoading] = useState(false)

  const menu = useRef(null)

  function closeMenu() {
    setShowMenu(false)
  }

  async function fetchSummary() {
    setSummaryIsLoading(true)
    await getSummary(userAddress)
      .then((summary) => {
        if (summary.results.length > 0) {
          setSummary(summary.results[0])
        }
        setSummaryIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setSummaryIsLoading(false)
      })
  }

  useEffect(() => {
    if (userAddress) {
      fetchSummary()
    }
  }, [userAddress])

  useEffect(() => {
    if (location.pathname.includes('/profile/runs')) {
      setCurrentLocation({
        runs: true,
        settings: false,
        rewards: false,
        characters: false,
      })
    } else if (location.pathname === '/profile/settings') {
      setCurrentLocation({
        runs: false,
        settings: true,
        rewards: false,
        characters: false,
      })
    } else if (location.pathname === '/profile/rewards') {
      setCurrentLocation({
        runs: false,
        settings: false,
        rewards: true,
        characters: false,
      })
    } else if (location.pathname === '/profile/characters') {
      setCurrentLocation({
        runs: false,
        settings: false,
        rewards: false,
        characters: true,
      })
    } else {
      setCurrentLocation({
        runs: false,
        settings: false,
        rewards: false,
        characters: false,
      })
    }
  }, [location])

  const handleCloseMenu = useCallback((event) => {
    if (
      menu.current &&
      !Array.from(menu.current.getElementsByTagName('*')).includes(event.target)
    ) {
      closeMenu()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('click', handleCloseMenu)
    return () => {
      window.removeEventListener('click', handleCloseMenu)
    }
  }, [handleCloseMenu])

  return (
    <>
      <Header />
      <PageContainer>
        <ProfileContainer>
          <SideMenuWrapper>
            <BackButton to={'/'}>
              <img
                src={require('../../images/back-arrow.svg').default}
                alt="back"
              />
              <span>Back to main</span>
            </BackButton>
            <SideMenuContainer
              rightBottomBorderRadius={showMenu ? 'square' : ''}
            >
              <SideMenuInfo>
                <SideMenuAvatarContainer>
                  <SideMenuAvatar>
                    <SideMenuAvatarImg
                      width="83"
                      height="83"
                      src={require('../../images/Avatar.svg').default}
                      alt="avatar"
                    ></SideMenuAvatarImg>
                  </SideMenuAvatar>
                </SideMenuAvatarContainer>
                <SideMenuInfoWrapper>
                  <SideMenuNickname>{username || 'User'}</SideMenuNickname>
                  <SideMenuAddress>
                    {(userAddress && shortenAddress(userAddress)) || ''}
                    <SideMenuButton
                      onClick={() => {
                        copyOnClick()
                        setSuccessCopy(true)
                      }}
                    >
                      {successCopy ? (
                        <img
                          width="16"
                          height="16"
                          src={require('../../images/SuccessCopy.svg').default}
                          alt="copy"
                        ></img>
                      ) : (
                        <img
                          width="16"
                          height="16"
                          src={require('../../images/Copy.svg').default}
                          alt="copy"
                        ></img>
                      )}

                      <HiddenInput value={userAddress} ref={ref} readOnly />
                    </SideMenuButton>
                  </SideMenuAddress>
                </SideMenuInfoWrapper>
              </SideMenuInfo>
              <SideMenuList>
                <SideMenuItem
                  to="/profile/settings"
                  current={currentLocation.settings.toString()}
                  onClick={closeMenu}
                >
                  <SideMenuItemContainer
                    current={currentLocation.settings.toString()}
                  >
                    <SideMenuIcon>
                      {currentLocation.settings ? (
                        <img
                          width="20"
                          height="20"
                          src={require('../../images/Settings.svg').default}
                          alt="settings"
                        ></img>
                      ) : (
                        <img
                          width="20"
                          height="20"
                          src={
                            require('../../images/VioletSettings.svg').default
                          }
                          alt="settings"
                        ></img>
                      )}
                    </SideMenuIcon>
                    Account settings
                  </SideMenuItemContainer>
                </SideMenuItem>
              </SideMenuList>
            </SideMenuContainer>
          </SideMenuWrapper>
          <Routes>
            <Route exact path="/settings" element={<Settings />} />
            <Route
              exact
              path="/runs/*"
              element={
                <Runs summary={summary} summaryIsLoading={summaryIsLoading} />
              }
            />
            <Route exact path="/rewards" element={<Referral />} />
            <Route
              exact
              path="/characters"
              element={
                <Characters
                  summary={summary}
                  summaryIsLoading={summaryIsLoading}
                />
              }
            />
            <Route path="*" element={<h3>Please select page</h3>} />
          </Routes>
        </ProfileContainer>
      </PageContainer>
    </>
  )
}

export default Profile
