import { utils } from 'ethers'
import { Button } from '../Button'

import {
  StyledCard,
  CardContent,
  CardImage,
  InfoContainer,
  InfoHeadContainer,
  PriceContainer,
  PriceWrapper,
  ButtonContainer,
  Border,
  CharacterKinds,
  CharacterKindContainer,
  CharacterKind,
  CharacterKindText,
  CardImageWrapper,
  CardDetails,
  ButtonLinkWrapper,
  StyledLink,
  CardSmallImage,
} from './BuyCharacterCard.styled'

export default function BuyCharacterCard({
  attributes,
  display,
  halloween,
  christmas,
  activeOrdersNumber,
}) {
  const detailLink = halloween
    ? (
        attributes.name +
        '-' +
        attributes.kind +
        '-' +
        attributes.rarity +
        '-halloween'
      ).toLowerCase()
    : christmas
    ? (
        attributes.name +
        '-' +
        attributes.kind +
        '-' +
        attributes.rarity +
        '-christmas'
      ).toLowerCase()
    : (
        attributes.name +
        '-' +
        attributes.kind +
        '-' +
        attributes.rarity
      ).toLowerCase()

  const show =
    halloween || christmas
      ? activeOrdersNumber[detailLink]
        ? activeOrdersNumber[detailLink].numberOrders > 0
        : false
      : true

  return (
    <StyledCard show={display && show}>
      <InfoHeadContainer>
        <CharacterKinds>
          <CharacterKindContainer type={attributes.kind}>
            <CharacterKind>
              <CharacterKindText type={attributes.kind}>
                {attributes.kind}
              </CharacterKindText>
            </CharacterKind>
          </CharacterKindContainer>
          <CharacterKindContainer type={attributes.rarity}>
            <CharacterKind>
              <CharacterKindText type={attributes.rarity}>
                {attributes.rarity}
              </CharacterKindText>
            </CharacterKind>
          </CharacterKindContainer>
        </CharacterKinds>
      </InfoHeadContainer>
      <CardImageWrapper>
        {halloween && (
          <CardSmallImage
            src={require('../../images/Pumpkin.png')}
            alt="pumpkin"
            width="40"
            height="40"
          />
        )}
        {christmas && (
          <CardSmallImage
            src={require('../../images/Christmas.png')}
            alt="snow"
            width="40"
            height="40"
          />
        )}
        <CardImage
          src={attributes.imageUri}
          alt="token"
          width="321"
          height="363"
        />
      </CardImageWrapper>
      <CardContent>
        <InfoContainer>
          <PriceContainer>Last Price:</PriceContainer>
          <PriceWrapper>
            {activeOrdersNumber &&
            activeOrdersNumber[detailLink] &&
            activeOrdersNumber[detailLink].lastPrice !== '0'
              ? `${utils.formatEther(
                  activeOrdersNumber[detailLink].lastPrice,
                )} MRUN`
              : `$${attributes.discountPrice}`}
          </PriceWrapper>
        </InfoContainer>
        <Border />
        <ButtonContainer>
          <ButtonLinkWrapper>
            <StyledLink to={`buy/${detailLink}`}>
              <Button variant="contained">Buy Now</Button>
            </StyledLink>
          </ButtonLinkWrapper>
        </ButtonContainer>
      </CardContent>
      <CardDetails className="skin-details">
        <table>
          <tbody>
            <tr>
              <td>
                <b>earing per hour, max</b>
              </td>
              <td>
                <b>{attributes.earingPerHour}</b>
              </td>
            </tr>
            <tr>
              <td>character level, max</td>
              <td>{attributes.maxLevel}</td>
            </tr>
            <tr>
              <td>ability level, max</td>
              <td>{attributes.maxAbility}</td>
            </tr>
          </tbody>
        </table>
      </CardDetails>
    </StyledCard>
  )
}
