const host = process.env.REACT_APP_API_HOST

export async function getIngameInfo(playfabId) {
  return await fetch(`${host}/ingame/${playfabId}`)
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
