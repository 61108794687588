import styled from 'styled-components'
import { Border } from '../Modal/Modal.styled'

export const ModalMain = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalTextWrapper = styled.span`
  font-weight: 700;
`

export const ModalBoldText = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin: 5px 0;
`

export const ModalError = styled.p`
  color: red;
  margin: 0 auto 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 100%;
  overflow: hidden;
`

export const BorderStyled = styled(Border)`
  margin: 0 16px;
  width: 100%;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    margin: 0 8px 8px;
  }
`

export const ModalTextContainer = styled.div`
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
