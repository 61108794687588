import styled from 'styled-components'
import { ModalName, ModalBalances } from '../BuyOpalModal/BuyOpalModal.styled'

export const ModalNameStyled = styled(ModalName)`
  min-width: 115px;
`

export const ModalImg = styled.img`
  position: relative;
  left: -2px;
  top: ${({ variant }) => {
    if (variant === 'mtrun') {
      return '5px'
    }
    return '2px'
  }};
  transform: none;
  margin-right: ${({ variant }) => {
    if (variant === 'mtrun') {
      return '0'
    }
    return '2px'
  }};

  @media (max-width: 768px) {
    height: ${({ variant }) => {
      if (variant === 'mtrun') {
        return '16px'
      }
      return '12px'
    }};
  }
  left: ${({ variant }) => {
    if (variant === 'mtrun') {
      return '0'
    }
    return '-2px'
  }};
  top: ${({ variant }) => {
    if (variant === 'mtrun') {
      return '5px'
    }
    return '1px'
  }};
`

export const ModalBalancesStyled = styled(ModalBalances)`
  &:first-child {
    margin-bottom: 12px;
  }
`
