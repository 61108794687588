import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const PagesMenuContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0 20px 20px;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #40407eb3;
    backdrop-filter: blur(10px);
  }
  @media (max-width: 768px) {
    margin: 0 12px 20px;
    flex-direction: column-reverse;
    align-items: end;
    gap: 8px;
  }
`

export const PagesMenuList = styled.div`
  display: flex;
  gap: 8px;
  margin-right: auto;
  max-width: 750px;
  @media (max-width: 768px) {
    gap: 0;
  }
`

export const PagesMenuItem = styled(Link)`
  text-transform: uppercase;
  display: ${({ hidden }) => {
    if (hidden) {
      return 'none'
    }
    return 'flex'
  }};
  align-items: center;
  position: relative;
  text-decoration: none;
  color: white;
  color: ${({ current }) => {
    if (current === 'true') {
      return 'white'
    }
    return ' #7A97DF '
  }};
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  cursor: pointer;

  padding: 13px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: ${({ current }) => {
      if (current === 'true') {
        return '1px'
      }
      return '0'
    }};
    z-index: 1;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(270deg, #5e51ff 1.49%, #489dfe 100.89%);
  }

  &:active {
    background: ${({ current }) => {
      if (current === 'true') {
        return '#489dfe1a'
      }
      return 'transparent'
    }};
  }
  @media (max-width: 768px) {
    text-transform: none;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
`

export const PagesMenuItemLink = styled.a`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  color: white;
  color: #7a97df;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  cursor: pointer;

  padding: 13px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 1;
    background: rgba(73, 158, 255, 0.35);
    background: linear-gradient(270deg, #5e51ff 1.49%, #489dfe 100.89%);
  }

  &:active {
    background: transparent;
  }
  @media (max-width: 768px) {
    text-transform: none;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
`

export const PagesMenuItemTextMobile = styled.span`
  display: none;
  @media (max-width: 920px) {
    display: inline;
  }
`
export const PagesMenuItemTextDesktop = styled.span`
  @media (max-width: 920px) {
    display: none;
  }
`
