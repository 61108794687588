import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react'
import validator from 'validator'
import {
  registerExistingEmail,
  registerNewEmail,
  registerVerify,
} from '../../api'
import { Button } from '../Button'
import Spinner from '../Spinner'
import { UserContext } from '../../contexts/UserContext'

import {
  Modal,
  ModalContainer,
  ModalForm,
  ModalWrapper,
  ModalButtonContainer,
  ModalInputContainer,
  ModalInputLabel,
  ModalInputError,
  StyledButtonContainer,
  StyledButton,
  ModalAddress,
  ModalLinkButton,
} from './Register.styled'

import {
  ModalTitle,
  ModalCloseButton,
  ModalInput,
  ModalText,
} from '../Modal/Modal.styled'

export default function Register({
  isOpen,
  onClose,
  openLink,
  registerType,
  setRegisterType,
}) {
  const { addAccessToken } = useContext(UserContext)
  const [email, setEmail] = useState('')
  const [emailIsSending, setEmailIsSending] = useState(false)
  const [emailVerify, setEmailVerify] = useState('')
  const [code, setCode] = useState('')
  const [codeInvalid, setCodeInvalid] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [emailSend, setEmailSend] = useState(false)
  const [timerEnabled, setTimerEnabled] = useState(false)
  const [seconds, setSeconds] = useState(60)

  const modal = useRef(null)

  const handleEmail = (e) => {
    setEmailInvalid(false)
    setEmail(e.target.value)
  }

  const handleCode = (e) => {
    setCodeInvalid(false)
    setCode(e.target.value)
  }

  const onChangeModal = () => {
    setEmailSend(false)
    setEmailVerify(false)
    setCode('')
    setEmail('')
    setEmailInvalid(false)
  }

  const sendNewEmail = (e) => {
    e.preventDefault()
    setEmailIsSending(true)
    registerNewEmail(email)
      .then(() => {
        setTimerEnabled(true)
        setEmailSend(true)
        setEmailVerify(email)
        setEmailIsSending(false)
      })
      .catch(() => {
        setEmailInvalid(true)
        setEmailIsSending(false)
      })
  }
  const sendExistingEmail = (e) => {
    e.preventDefault()
    setEmailIsSending(true)
    registerExistingEmail(email)
      .then(() => {
        setTimerEnabled(true)
        setEmailSend(true)
        setEmailVerify(email)
        setEmailIsSending(false)
      })
      .catch(() => {
        setEmailIsSending(false)

        setEmailInvalid(true)
      })
  }

  const sendCode = (e) => {
    e.preventDefault()
    const invite = window.localStorage.getItem('Invite')
    registerVerify(emailVerify, code, invite)
      .then((res) => {
        addAccessToken(res.token)
        onClose()
        onChangeModal()
        openLink()
      })
      .catch((err) => {
        setCodeInvalid(true)
        console.log(err)
      })
  }

  const handleOverlayClose = useCallback((event) => {
    if (event.target === event.currentTarget) {
      onClose()
      onChangeModal()
    }
  }, [])

  useEffect(() => {
    if (modal.current) {
      modal.current.addEventListener('click', handleOverlayClose)
    }
    return () => {
      if (modal.current) {
        modal.current.removeEventListener('click', handleOverlayClose)
      }
    }
  }, [handleOverlayClose])

  useEffect(() => {
    if (!timerEnabled) {
      return
    }
    const timerId = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      } else {
        setTimerEnabled(false)
        setSeconds(60)
      }
    }, 1000)
    return () => {
      clearInterval(timerId)
    }
  }, [timerEnabled, seconds])

  return (
    <Modal isOpen={isOpen} ref={modal}>
      <ModalContainer>
        <ModalWrapper>
          <ModalButtonContainer
            onClick={() => {
              onClose()
              onChangeModal()
            }}
          >
            <ModalCloseButton
              src={require('../../images/Close.svg').default}
              alt="close"
              width="14"
              height="14"
            ></ModalCloseButton>
          </ModalButtonContainer>
          <ModalTitle margin>
            {registerType === 'create'
              ? 'CREATE GAME ACCOUNT'
              : 'LINK GAME ACCOUNT'}
          </ModalTitle>
          <ModalForm onSubmit={sendCode}>
            <ModalInputContainer>
              <ModalInputLabel>Email</ModalInputLabel>
              {emailInvalid && (
                <ModalInputError>
                  {registerType === 'create'
                    ? 'This email is invalid or already connected to another wallet'
                    : 'This email is invalid or does not exist yet'}
                </ModalInputError>
              )}
              <ModalInput
                value={email || ''}
                onChange={handleEmail}
                type="email"
                invalid={(email && !validator.isEmail(email)) || emailInvalid}
                placeholder="Your e-mail"
              />
            </ModalInputContainer>
            <ModalInputContainer margin="24px">
              <ModalInputLabel>Enter code</ModalInputLabel>
              {codeInvalid && (
                <ModalInputError>This code is invalid</ModalInputError>
              )}
              <ModalInput
                value={code || ''}
                onChange={handleCode}
                type="text"
                disabled={!emailSend}
                invalid={(code && !validator.isInt(code)) || codeInvalid}
                placeholder="— — — — — —"
              />
              <StyledButtonContainer
                disabled={
                  email === '' ||
                  (email && !validator.isEmail(email)) ||
                  timerEnabled ||
                  emailInvalid
                }
              >
                {registerType === 'create' ? (
                  <StyledButton
                    onClick={sendNewEmail}
                    disabled={
                      email === '' ||
                      (email && !validator.isEmail(email)) ||
                      timerEnabled ||
                      emailInvalid ||
                      emailIsSending
                    }
                  >
                    {timerEnabled ? (
                      seconds + ' sec'
                    ) : emailIsSending ? (
                      <Spinner size={'18px'} />
                    ) : (
                      'Send code'
                    )}
                  </StyledButton>
                ) : (
                  <StyledButton
                    onClick={sendExistingEmail}
                    disabled={
                      email === '' ||
                      (email && !validator.isEmail(email)) ||
                      timerEnabled ||
                      emailInvalid ||
                      emailIsSending
                    }
                  >
                    {timerEnabled ? (
                      seconds + ' sec'
                    ) : emailIsSending ? (
                      <Spinner size={'18px'} />
                    ) : (
                      'Send code'
                    )}
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </ModalInputContainer>
            <ModalText type="register" mb="10px">
              By continuing, you agree to{' '}
              <ModalAddress>Metarun Terms of Service</ModalAddress> and confirm
              that you have read{' '}
              <ModalAddress>Metarun Privacy Policy</ModalAddress>
            </ModalText>
            {registerType === 'create' ? (
              <ModalText type="register" mb="10px">
                You already have an account?{' '}
                <ModalLinkButton
                  type="button"
                  onClick={() => {
                    setRegisterType('add')
                    onChangeModal()
                  }}
                >
                  Connect now
                </ModalLinkButton>
              </ModalText>
            ) : (
              <ModalText type="register" mb="10px">
                Don’t have an account?{' '}
                <ModalLinkButton
                  type="button"
                  onClick={() => {
                    setRegisterType('create')
                    onChangeModal()
                  }}
                >
                  Create now
                </ModalLinkButton>
              </ModalText>
            )}
            <Button
              size="registerModal"
              type="submit"
              variant="registerModal"
              disabled={
                !emailSend ||
                code === '' ||
                (code && !validator.isInt(code)) ||
                codeInvalid
              }
            >
              {registerType === 'create' ? 'CREATE ACCOUNT' : 'LINK ACCOUNT'}
            </Button>
          </ModalForm>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  )
}
