import React, { useState, useEffect, useContext } from 'react'
import { ethers } from 'ethers'
import { Button } from '../Button'
import Spinner from '../Spinner'
import ModalComponent from '../Modal'
import { getMetarunSale, getBUSD } from '../../api/contracts'
import { getOwnerCards, setReferral } from '../../api'
import { shortenAddress } from '../../utils/shortenAddress'
import networks from '../../networks.json'
import { SUPPORTED_CHAINS, EIP712_CHAIN_ID } from '../../constants'
import { UserContext } from '../../contexts/UserContext'
import {
  ModalMain,
  ModalTextWrapper,
  ModalError,
  BorderStyled as Border,
  ModalBoldText,
  ModalButtonItem,
} from './BuyCharacterModal.styled'

import {
  ModalTitle,
  ModalTxText,
  ModalTxLink,
  ModalScrollbar,
  ModalTextDiv,
} from '../Modal/Modal.styled'

export default function BuyCharacterModal({
  isOpen,
  onClose,
  busdPrice,
  kind,
  tokensBefore,
}) {
  const { userAddress, chainId } = useContext(UserContext) || []
  const [allowance, setAllowance] = useState(0)
  const [allowanceIsLoading, setAllowanceIsLoading] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [txHash, setTxHash] = useState()
  const [error, setError] = useState('')

  const price = ethers.utils.parseEther(busdPrice)

  async function closeModal() {
    setTxHash()
    setError('')
    onClose()
  }

  async function getAllowance(abortController) {
    const BUSD = getBUSD(chainId)

    await BUSD.allowance(
      userAddress,
      networks[chainId].metarunSaleContract.address,
    )
      .then((allowance) => {
        const aborted = abortController.signal.aborted
        if (!aborted) {
          setAllowanceIsLoading(false)
          setAllowance(allowance.toString())
        }
      })
      .catch((err) => {
        console.log(err)
        setAllowanceIsLoading(false)
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (
      userAddress &&
      SUPPORTED_CHAINS.includes(chainId) &&
      networks[chainId].busdContract
    ) {
      setAllowanceIsLoading(true)
      getAllowance(abortController)
    }
    return function cleanup() {
      abortController.abort(abortController)
    }
  }, [userAddress, chainId])

  async function getApprove(e) {
    e.preventDefault()
    setIsLoading(true)
    setError('')
    const BUSD = getBUSD(chainId)
    if (Number(price) > allowance) {
      await BUSD.approve(
        networks[chainId].metarunSaleContract.address,
        ethers.constants.MaxUint256,
      )
        .then((tx) => {
          setTxHash(tx.hash)
          tx.wait()
            .then(() => {
              getAllowance()
              setIsLoading(false)
              setTxHash()
              buyCharacter()
            })
            .catch((err) => {
              console.log(err)
              setError(
                (err.data && err.data.message) ||
                  err.message ||
                  'Something went wrong',
              )
              setIsLoading(false)
              setTxHash()
            })
        })
        .catch((err) => {
          console.log(err)
          setError(
            (err.data && err.data.message) ||
              err.message ||
              'Something went wrong',
          )
          setIsLoading(false)
          setTxHash()
        })
    } else {
      buyCharacter()
    }
  }

  async function buyCharacter() {
    setIsLoading(true)
    setError('')
    const MetarunSale = getMetarunSale(chainId)
    MetarunSale.buy(kind)
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then((res) => {
            setTimeout(function testTokens() {
              getOwnerCards({ userAddress })
                .then((res) => {
                  if (res.count > tokensBefore) {
                    const referral = window.localStorage.getItem(
                      'Mysteryreferral',
                    )
                    if (referral) {
                      setReferral(referral, userAddress, 1)
                    }
                    onClose()
                    setIsLoading(false)
                    setTxHash()
                  } else {
                    setTimeout(testTokens, 2000)
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            }, 1000)
          })
          .catch((err) => {
            console.log(err)
            setError(
              (err.data && err.data.message) ||
                err.message ||
                'Something went wrong',
            )
            setIsLoading(false)
            setTxHash()
          })
      })
      .catch((err) => {
        console.log(err)
        setError(
          (err.data && err.data.message) ||
            err.message ||
            'Something went wrong',
        )
        setIsLoading(false)
        setTxHash()
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={closeModal}>
      <ModalTitle margin>
        Buy {kind === '0x00000406' ? 'Mystery Box' : 'Character'}
      </ModalTitle>
      <ModalMain>
        {isLoading ||
        allowanceIsLoading ||
        Number(chainId) !== EIP712_CHAIN_ID ? (
          <>
            <Spinner size="80px" />
            {!txHash ? (
              <ModalTxText>
                {allowanceIsLoading || Number(chainId) !== EIP712_CHAIN_ID
                  ? ''
                  : 'Await confirmation'}
              </ModalTxText>
            ) : (
              <ModalTxLink
                target="_blank"
                href={
                  networks[chainId]
                    ? `${networks[chainId].params.blockExplorerUrls}tx/${txHash}`
                    : ''
                }
              >
                TxHash: {shortenAddress(txHash)}
              </ModalTxLink>
            )}
          </>
        ) : (
          <ModalScrollbar buy>
            <ModalTextDiv type="buy-character">
              This purchase is made in BUSD. The{' '}
              {kind === '0x00000406' ? 'Mystery Box' : 'character'} price in
              BUSD is equivalent to the price in dollars and is equal to:
              <ModalBoldText>{busdPrice} BUSD</ModalBoldText>
            </ModalTextDiv>
            {Number(price) > allowance && (
              <>
                <Border />
                <ModalTextDiv type="buy-character">
                  To approve Metarun to receive payment in{' '}
                  <ModalTextWrapper> BUSD</ModalTextWrapper>, you must first
                  complete a free (excluding Gas) transaction. Please confirm
                  this in your wallet and keep this modal open!
                </ModalTextDiv>
                <ModalTextDiv type="buy-character">
                  You may notice a very large number being requested for
                  approval. This is simply the maximum amount, meaning you’ll
                  never have to do this approval again. It also doesn’t give
                  permission for Metarun to take this amount from you. The price
                  of the Character is all that will be charged.
                </ModalTextDiv>
              </>
            )}
            <ModalButtonItem>
              <Button
                size="extralong"
                variant="contained"
                onClick={getApprove}
                disabled={price === 0}
              >
                Buy
              </Button>
            </ModalButtonItem>
          </ModalScrollbar>
        )}
        <ModalError>{error}</ModalError>
      </ModalMain>
    </ModalComponent>
  )
}
