import React, { useState, useEffect, useContext } from 'react'
import { utils } from 'ethers'
import { Button } from '../Button'
import { shortenAddress } from '../../utils/shortenAddress'
import { getKindImage } from '../../utils'
import { UserContext } from '../../contexts/UserContext'
import { EIP712_CHAIN_ID } from '../../constants'

import {
  ButtonLinkWrapper,
  StyledLink,
  KindInfo,
  StyledCard,
  CardContent,
  CardImage,
  InfoContainer,
  InfoHeadContainer,
  KindContainer,
  ItemsContainer,
  KindImage,
  ItemsImage,
  PriceContainer,
  PriceWrapper,
  PriceImage,
  OwnerContainer,
  OwnerImage,
  OwnerAddress,
  HiddenText,
  KindImageContainer,
  Border,
  OwnerAddressItem,
  CharacterKinds,
  CharacterKindContainer,
  CharacterKind,
  CharacterKindText,
} from './Card.styled'
import CardNetworkIcon from '../CardNetworkIcon'

export default function ActionAreaCard({ cardData, ownersCard }) {
  const { userAddress } = useContext(UserContext)
  const [isUserHolder, setIsUserHolder] = useState(false)
  const [kindImg, setKindImg] = useState()

  useEffect(() => {
    if (cardData && cardData.kind) {
      setKindImg(getKindImage(cardData.kind.contractTokenName))
    }
  }, [cardData])

  useEffect(() => {
    if (cardData && userAddress) {
      setIsUserHolder(cardData.holder === userAddress)
    }
  }, [cardData, userAddress])

  return (
    <>
      {cardData ? (
        <StyledCard border={isUserHolder}>
          <InfoHeadContainer>
            {cardData.kind && cardData.kind.tokenType === 'Character' ? (
              <CharacterKinds>
                <CharacterKindContainer type={cardData.kind.tokenKind}>
                  <CharacterKind>
                    <CharacterKindText type={cardData.kind.tokenKind}>
                      {cardData.kind.tokenKind}
                    </CharacterKindText>
                  </CharacterKind>
                </CharacterKindContainer>
                <CharacterKindContainer type={cardData.kind.tokenRarity}>
                  <CharacterKind>
                    <CharacterKindText type={cardData.kind.tokenRarity}>
                      {cardData.kind.tokenRarity}
                    </CharacterKindText>
                  </CharacterKind>
                </CharacterKindContainer>
              </CharacterKinds>
            ) : (
              <KindContainer>
                <KindImageContainer
                  tokenKind={
                    cardData.kind && cardData.kind.tokenType
                      ? cardData.kind.tokenType
                      : cardData.kind && cardData.kind.contractTokenName
                      ? cardData.kind.contractTokenName
                      : ''
                  }
                >
                  <KindImage src={kindImg} width="20" height="20" alt="kind" />
                </KindImageContainer>
                {cardData.kind && cardData.kind.attributes
                  ? cardData.kind.attributes.characterClass
                    ? cardData.kind.attributes.characterClass
                    : cardData.kind.contractTokenName
                  : ''}
              </KindContainer>
            )}

            <ItemsContainer>
              <ItemsImage
                src={require('../../images/Items.svg').default}
                alt="items"
                data-testid="custom-element"
                width="14"
                height="18"
              />
              {cardData.balance}
            </ItemsContainer>
          </InfoHeadContainer>
          <StyledLink to={`detail/${cardData.id}`}>
            <CardImage
              src={cardData.kind && cardData.kind.imageUri}
              alt="token"
              width="321"
              height="363"
            />
            <CardNetworkIcon chain={cardData.kind.chain} />
          </StyledLink>

          <KindInfo>#{cardData.kind && cardData.kind.contractTokenId}</KindInfo>

          <CardContent>
            <InfoContainer>
              <PriceContainer>
                Order Price
                <PriceWrapper>
                  <PriceImage
                    src={require('../../images/SmallLogo.svg').default}
                    alt="bsc"
                    width="24"
                    height="24"
                  />
                  {cardData.orderPrice
                    ? Number(utils.formatEther(cardData.orderPrice)).toFixed(2)
                    : '0'}
                </PriceWrapper>
              </PriceContainer>
              {cardData.kind &&
              cardData.kind.contractTokenName === 'Mystery Box' &&
              ownersCard ? (
                <ButtonLinkWrapper disabled={!isUserHolder}>
                  <StyledLink
                    to={`detail/${cardData.id}/reveal`}
                    disabled={!isUserHolder}
                  >
                    <Button disabled={!isUserHolder}>Reveal</Button>{' '}
                  </StyledLink>
                </ButtonLinkWrapper>
              ) : cardData.kind &&
                cardData.kind.contractTokenName === 'Ticket' &&
                ownersCard ? (
                <ButtonLinkWrapper disabled={!isUserHolder}>
                  <StyledLink
                    to={`detail/${cardData.id}/?swap=true`}
                    disabled={!isUserHolder}
                  >
                    <Button disabled={!isUserHolder}>Swap</Button>{' '}
                  </StyledLink>
                </ButtonLinkWrapper>
              ) : isUserHolder ? (
                <ButtonLinkWrapper disabled={!userAddress}>
                  <StyledLink
                    to={`detail/${cardData.id}`}
                    disabled={!userAddress}
                  >
                    <Button disabled={!userAddress}>
                      {cardData.kind.chain === EIP712_CHAIN_ID ? (
                        <>
                          Sell
                          <HiddenText> Now</HiddenText>
                        </>
                      ) : (
                        'Details'
                      )}
                    </Button>
                  </StyledLink>
                </ButtonLinkWrapper>
              ) : (
                <ButtonLinkWrapper disabled={!userAddress}>
                  <StyledLink
                    to={`detail/${cardData.id}#orders`}
                    disabled={!userAddress}
                  >
                    <Button variant="contained" disabled={!userAddress}>
                      Buy<HiddenText> Now</HiddenText>
                    </Button>
                  </StyledLink>
                </ButtonLinkWrapper>
              )}
            </InfoContainer>
            <Border />
            <OwnerContainer>
              <OwnerImage
                src={require('../../images/Owner.png')}
                alt="owner"
                width="48"
                height="48"
              />
              <OwnerAddress>
                Owner:
                <OwnerAddressItem>
                  {cardData.holder && shortenAddress(cardData.holder)}
                </OwnerAddressItem>
              </OwnerAddress>
            </OwnerContainer>
          </CardContent>
        </StyledCard>
      ) : (
        <></>
      )}
    </>
  )
}
