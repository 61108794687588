import React, { useState, useEffect, useReducer, useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import AccordionDetails from '@mui/material/AccordionDetails'
import { getOrders, getOwnerCards } from '../../api'
import { utils } from 'ethers'
import Accordion from '../../components/Accordion/Accordion'
import { getAttributeName, getOrderStatus, getKindImage } from '../../utils'
import { getTimeFromTimeStamp } from '../../utils/formatDate'
import { shortenAddress } from '../../utils/shortenAddress'
import BuyOrderButton from './buttons/BuyOrderButton'
import { DeleteOrderButton } from './buttons/DeleteOrderButton'
import { RevealBoxButton } from './buttons/RevealBoxButton'
import { SwapTicketButton } from './buttons/SwapTicketButton'
import networks from '../../networks.json'
import { EXCHANGE_SUPPORTS, SUPPORTED_CHAINS } from '../../constants'
import { UserContext } from '../../contexts/UserContext'

import {
  TokenHeadCount,
  TokenHead,
  TokenBody,
  TokenBodyCard,
  InfoLabel,
  TokenBodyCardLast,
  TokenBodyCardLastData,
  TokenBodyCardGrid,
  TokenBodyCardInfo,
  TokenBodyCardInfoBody,
  TokenBodyCardInfoOwnerImg,
  TokenBodyCardInfoText,
  OrderStatusWrapper,
  BuyButton,
  SaleOrderButton,
  AttributeContainer,
  AttributeName,
  AttributeValue,
  OrdersContainer,
  OrderContainer,
  OrderName,
  OrderValue,
  StyledLink,
  InfoText,
} from './CardDetail.styled'
import CardNetworkIcon from '../CardNetworkIcon'

export default function CardDetailBody({
  token,
  handleOpenModalWindow,
  orderIsAdded,
  setSwitchtNetworkModalOpen,
  setSwitchtNetworkModalText,
}) {
  const { userAddress, accessToken, chainId, email } = useContext(UserContext)

  const [allTokensBefore, setAllTokensBefore] = useState(0)
  const [, setKindImg] = useState()
  const [orders, setOrders] = useState([])
  const [ordersBefore, setOrdersBefore] = useState(0)
  const [inactiveOrders, setInactiveOrders] = useState([])
  const [isOrdersAccordionOpen, setIsOrdersAccordionOpen] = useState(true)
  const [isItemsAccordionOpen, setIsItemsAccordionOpen] = useState(false)
  const [isAttributesAccordionOpen, setIsAttributesAccordionOpen] = useState(
    false,
  )
  const [isUserHolder, setIsUserHolder] = useState(false)

  const { tokenId } = useParams()
  const [searchParams] = useSearchParams()
  const swapOpen = searchParams.get('swap')

  const [swapModalOpen, setSwapModalOpen] = useState(false)

  const [forced, forceUpdate] = useReducer((x) => x + 1, 0)

  async function fetchOrders() {
    await getOrders(tokenId, [0])
      .then((res) => {
        setOrdersBefore(res.count)
        if (res.count > 0) {
          const arr = []
          arr.push(res.results[0])
          setOrders(arr)
        } else {
          setOrders([])
        }
      })
      .catch((err) => console.log(err))
  }

  async function fetchInactiveOrders() {
    await getOrders(tokenId, [1, 2, 3, 4])
      .then((res) => {
        const total = res.results.length
        if (total < res.count) {
          setInactiveOrders([])
          const totalPages = total / 20 + 1
          Promise.all(
            Array.from(Array(totalPages), (_, i) =>
              getOrders(tokenId, [1, 2, 3, 4], i + 1),
            ),
          ).then((res) => {
            for (let i = 0; i < totalPages; i++) {
              setInactiveOrders((prevValue) => {
                return prevValue.concat(res[i].results)
              })
            }
          })
        } else {
          setInactiveOrders(res.results)
        }
      })
      .catch((err) => console.log(err))
  }

  function getAllTokensCount() {
    getOwnerCards({ userAddress })
      .then((res) => {
        setAllTokensBefore(res.count)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchOrders()
    fetchInactiveOrders()
  }, [orderIsAdded, forced])

  useEffect(() => {
    if (token && token.kind) {
      setKindImg(getKindImage(token.kind.contractTokenName))
    }
  }, [token])

  useEffect(() => {
    if (token && userAddress) {
      setIsUserHolder(token.holder === userAddress)
    }
  }, [token, userAddress])

  useEffect(() => {
    if (swapOpen) {
      setSwapModalOpen(true)
    }
  }, [swapOpen])

  useEffect(() => {
    getAllTokensCount()
  }, [])

  return (
    <TokenBody>
      <TokenBodyCard>
        <TokenBodyCardLast>
          <TokenHead>
            <InfoLabel>Order price</InfoLabel>
            <TokenHeadCount>
              <img
                src={require('../../images/Items.svg').default}
                alt="items"
              />
              <span>{token.balance}</span>
            </TokenHeadCount>
          </TokenHead>
          <TokenBodyCardLastData>
            <span className={'price'}>
              {token.orderPrice ? utils.formatEther(token.orderPrice) : '0'}
            </span>

            <img src={require('../../images/SmallLogo.svg').default} />
          </TokenBodyCardLastData>
        </TokenBodyCardLast>
        <TokenBodyCardGrid>
          <TokenBodyCardInfo>
            <CardNetworkIcon
              chain={token.kind.chain}
              style={{ position: 'unset' }}
            />

            <TokenBodyCardInfoBody>
              <InfoLabel>
                {token.kind.tokenType === 'Character' ? 'Name' : 'Kind'}
              </InfoLabel>

              <TokenBodyCardInfoText>
                {token.kind.attributes.characterClass
                  ? token.kind.attributes.characterClass
                  : token.kind.contractTokenName}
              </TokenBodyCardInfoText>
            </TokenBodyCardInfoBody>
          </TokenBodyCardInfo>
          <TokenBodyCardInfo>
            <TokenBodyCardInfoOwnerImg
              src={require('../../images/DetailOwner.png')}
              alt={'Owner'}
              width="48"
              height="48"
            />
            <TokenBodyCardInfoBody>
              <InfoLabel>Owner:</InfoLabel>
              <TokenBodyCardInfoText>
                {token.holder && shortenAddress(token.holder)}
              </TokenBodyCardInfoText>
            </TokenBodyCardInfoBody>
          </TokenBodyCardInfo>
        </TokenBodyCardGrid>
        {EXCHANGE_SUPPORTS.includes(token.kind.chain) ? (
          <OrderStatusWrapper>
            {isUserHolder ?? token ? (
              <SaleOrderButton
                disabled={
                  orders.length > 0 ||
                  !accessToken ||
                  !email ||
                  !SUPPORTED_CHAINS.includes(chainId)
                }
                onClick={() => {
                  handleOpenModalWindow()
                }}
              >
                <img
                  src={require('../../images/badge-icon.svg').default}
                  alt="badge"
                />
                {'Place sell order'}
              </SaleOrderButton>
            ) : (
              <BuyButton
                disabled={!userAddress || orders.length === 0}
                onClick={() => {
                  setIsOrdersAccordionOpen(true)
                  location.href = '#orders'
                }}
              >
                <img src={require('../../images/wallet-icon.svg').default} />
                {'Buy in sell orders'}
              </BuyButton>
            )}

            {isUserHolder && token.kind.contractTokenName === 'Ticket' && (
              <SwapTicketButton
                tokenId={token.kind.contractTokenId}
                swapModalOpen={swapModalOpen}
                setSwapModalOpen={setSwapModalOpen}
              />
            )}
          </OrderStatusWrapper>
        ) : (
          token.kind.contractTokenName !== 'Mystery Box' && (
            <InfoText>
              Exchange of tokens on the polygon network is not available
            </InfoText>
          )
        )}
        {isUserHolder && token.kind.contractTokenName === 'Mystery Box' && (
          <RevealBoxButton
            style={{ marginTop: '12px' }}
            chain={token.kind.chain}
          />
        )}
      </TokenBodyCard>
      {EXCHANGE_SUPPORTS.includes(token.kind.chain) && (
        <Accordion
          id="orders"
          title={'Sell orders'}
          icon={<img src={require('../../images/badge-icon.svg').default} />}
          disabled={orders && orders.length === 0}
          isOpen={isOrdersAccordionOpen}
          setIsOpen={setIsOrdersAccordionOpen}
        >
          {orders &&
            orders.map((orderInformation) => {
              return (
                <OrdersContainer key={orderInformation.price + Math.random()}>
                  <OrderContainer>
                    <OrderName>Price:</OrderName>
                    <OrderValue>
                      {utils.formatEther(orderInformation.price)} MRUN
                    </OrderValue>
                  </OrderContainer>
                  <OrderContainer>
                    <OrderName>Seller:</OrderName>
                    <OrderValue>
                      {shortenAddress(orderInformation.ownership.holder)}
                    </OrderValue>
                  </OrderContainer>
                  <OrderContainer>
                    <OrderName>Deadline:</OrderName>
                    <OrderValue>
                      {getTimeFromTimeStamp(orderInformation.expirationTime)}
                    </OrderValue>
                  </OrderContainer>
                  {isUserHolder ? (
                    <DeleteOrderButton
                      orderInformation={orderInformation}
                      forceUpdate={forceUpdate}
                      ordersBefore={ordersBefore}
                      tokenId={tokenId}
                      tokenChain={token.kind.chain}
                      setSwitchtNetworkModalOpen={setSwitchtNetworkModalOpen}
                      setSwitchtNetworkModalText={setSwitchtNetworkModalText}
                    />
                  ) : (
                    <BuyOrderButton
                      orderInformation={orderInformation}
                      userAddress={userAddress}
                      allTokensBefore={allTokensBefore}
                      token={token}
                      setSwitchtNetworkModalOpen={setSwitchtNetworkModalOpen}
                      setSwitchtNetworkModalText={setSwitchtNetworkModalText}
                    />
                  )}
                </OrdersContainer>
              )
            })}
        </Accordion>
      )}

      {token.kind.attributes && Object.keys(token.kind.attributes).length > 0 && (
        <Accordion
          title={'Attributes'}
          icon={<img src={require('../../images/Attributes.svg').default} />}
          isOpen={isAttributesAccordionOpen}
          setIsOpen={setIsAttributesAccordionOpen}
        >
          <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
            {Object.keys(token.kind.attributes).map((attribute) => {
              return (
                <AttributeContainer key={attribute + Math.random()}>
                  <AttributeName>{getAttributeName(attribute)}:</AttributeName>
                  <AttributeValue>
                    {token.kind.attributes[attribute]}
                  </AttributeValue>
                </AttributeContainer>
              )
            })}
          </AccordionDetails>
        </Accordion>
      )}
      {EXCHANGE_SUPPORTS.includes(token.kind.chain) && (
        <Accordion
          title={'Item Activity'}
          icon={<img src={require('../../images/ItemsActive.svg').default} />}
          isOpen={isItemsAccordionOpen}
          setIsOpen={setIsItemsAccordionOpen}
          disabled={inactiveOrders && inactiveOrders.length === 0}
        >
          {inactiveOrders &&
            inactiveOrders.map((orderInformation) => {
              return (
                <OrdersContainer key={orderInformation.price + Math.random()}>
                  <OrderContainer>
                    <OrderName>Status:</OrderName>
                    <OrderValue>
                      {getOrderStatus(orderInformation.status)}
                    </OrderValue>
                  </OrderContainer>
                  <OrderContainer>
                    <OrderName>Creation time:</OrderName>
                    <OrderValue>
                      {getTimeFromTimeStamp(orderInformation.creationTime)}
                    </OrderValue>
                  </OrderContainer>
                  <OrderContainer>
                    <OrderName>Execution time:</OrderName>
                    <OrderValue>
                      {orderInformation.executionTime
                        ? getTimeFromTimeStamp(orderInformation.executionTime)
                        : '-'}
                    </OrderValue>
                  </OrderContainer>
                  <OrderContainer variant="withoutMargin">
                    <OrderName>Price:</OrderName>
                    <OrderValue>
                      {utils.formatEther(orderInformation.price)} MRUN
                    </OrderValue>
                  </OrderContainer>
                  {orderInformation.transactionHash ? (
                    <OrderContainer variant="withoutMargin">
                      <OrderName>TX Hash:</OrderName>
                      <OrderValue>
                        <StyledLink
                          color="#fff"
                          target="_blank"
                          href={
                            networks[token.kind.chain]
                              ? `${
                                  networks[token.kind.chain].params
                                    .blockExplorerUrls
                                }tx/${orderInformation.transactionHash}`
                              : ''
                          }
                        >
                          {shortenAddress(orderInformation.transactionHash)}
                        </StyledLink>
                      </OrderValue>
                    </OrderContainer>
                  ) : (
                    <></>
                  )}
                </OrdersContainer>
              )
            })}
        </Accordion>
      )}
    </TokenBody>
  )
}
