import { formatReferralDate } from '../utils/formatDate'
const referralHost = 'https://hooks.zapier.com/hooks/catch/1955480/b0mmaki/'

export async function setReferral(referral, userAddress, amount) {
  return await fetch(`${referralHost}`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referral,
      address: userAddress,
      amount,
      datetime: formatReferralDate(new Date()),
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}
