import styled from 'styled-components'
import { getNetworkIcon } from '../../utils'

const Icon = styled.img`
  position: absolute;
  right: 15px;
  top: 70px;
  width: 40px;
  height: 40px;
  @media (max-width: 1053px) {
    top: 50px;
    width: 35px;
    height: 35px;
  }
  @media (max-width: 768px) {
    right: 8px;
    top: 35px;
    width: 25px;
    height: 25px;
    z-index: 2;
  }
`

const CardNetworkIcon = ({ chain, style }) => {
  return <Icon src={getNetworkIcon(chain)} style={style} alt="networkicon" />
}

export default CardNetworkIcon
