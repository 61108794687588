import styled from 'styled-components'
import { Link as RecatLink } from 'react-router-dom'

const vars = {
  media: '768px',
}

export const ButtonWrapper = styled.div`
  display: flex;
  max-width: 1046px;
  margin: 0px auto;
  padding: 0 200px 0px;
  @media (max-width: ${(props) => vars.media}) {
    padding: 0 20px 30px;
  }
`

export const NotFoundWrapper = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NotFoundTitle = styled.h2`
  color: white;
  font-weight: bold;
  font-size: 120px;
  font-family: 'OpenSans';
  margin: 0px;
  margin-top: 100px;
  @media (max-width: ${(props) => vars.media}) {
    font-size: 80px;
  }
`

export const NotFoundText = styled.p`
  color: white;
  font-size: 20px;
  font-family: 'OpenSans';
  margin: 0px;
`

export const BackButton = styled(RecatLink)`
  display: inline-flex;
  align-items: center;
  background: #10102cb2;
  gap: 8px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  line-height: 1;
  box-sizing: border-box;
  border-radius: 20px;
  height: 56px;
  font-size: 16px;
  padding: 7px 26px;

  @media (max-width: ${(props) => vars.media}) {
    border-radius: 12px;
    height: 36px;
    font-size: 14px;
    padding: 5px 14px;
  }
`
