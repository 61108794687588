import { useNavigate } from 'react-router-dom'
import PageContainer from '../../components/PageContainer'
import { Button } from '../../components/Button'
import {
  InfoContainer,
  MainInfo,
  InfoTitle,
  InfoText,
  InfolImage,
  UnsubscribeText,
  ButtonWrapper,
} from './Unsubscribe.styled'

function Unsubscribe() {
  const navigate = useNavigate()
  return (
    <PageContainer>
      <InfoContainer>
        <MainInfo>
          <InfoTitle>You&apos;ve been unsubscribed</InfoTitle>
          <InfolImage
            src={require('../../images/Unsubscribe.png')}
            alt="paper"
            width="80"
            height="80"
          />
          <InfoText>We won&apos;t send you any more emails.</InfoText>
        </MainInfo>
        <UnsubscribeText>Unsubscribe by accident?</UnsubscribeText>
        <ButtonWrapper>
          <Button
            size="unsubscribe"
            variant="unsubscribe"
            onClick={() => navigate('/')}
          >
            Re-Subscribe
          </Button>
        </ButtonWrapper>
      </InfoContainer>
    </PageContainer>
  )
}

export default Unsubscribe
