import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Button } from '../Button'
import {
  Modal,
  ModalCloseButtonContainer,
  ModalContainer,
  ModalMenuList,
  ModalMenuItem,
  ModalMain,
  ModalTitle,
  ModalQRcontainer,
  ModalQRwrapper,
  ModalQRtext,
} from './StartGameModal.styled'

import { ModalCloseButton } from '../Modal/Modal.styled'

export default function StartGameModal({ isOpen, onClose }) {
  const modal = useRef(null)

  const [isAndroid, setIsAndroid] = useState(true)

  const handleClose = useCallback((event) => {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }, [])

  useEffect(() => {
    if (modal.current) {
      modal.current.addEventListener('click', handleClose)
    }
    return () => {
      if (modal.current) {
        modal.current.removeEventListener('click', handleClose)
      }
    }
  }, [handleClose])

  return (
    <Modal isOpen={isOpen} ref={modal}>
      <ModalContainer>
        <ModalCloseButtonContainer onClick={onClose}>
          <ModalCloseButton
            src={require('../../images/Close.svg').default}
            alt="close"
            width="14"
            height="14"
          ></ModalCloseButton>
        </ModalCloseButtonContainer>
        <ModalMenuList>
          <ModalMenuItem current={isAndroid} onClick={() => setIsAndroid(true)}>
            For Android
          </ModalMenuItem>
          <ModalMenuItem
            current={!isAndroid}
            onClick={() => setIsAndroid(false)}
          >
            For Ios
          </ModalMenuItem>
        </ModalMenuList>
        {isAndroid ? (
          <ModalMain>
            <ModalTitle marginTop>Download Metarun via the link</ModalTitle>
            <ModalQRcontainer marginBottom>
              <ModalQRwrapper>
                <img
                  src={require('../../images/QRcodeGoogle.png')}
                  width="200px"
                  alt="qr"
                />
                <ModalQRtext>or</ModalQRtext>
                <a
                  href="https://play.google.com/store/apps/details?id=com.metarun.game"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button size="startGameModal" variant="contained">
                    Metarun App
                  </Button>
                </a>
              </ModalQRwrapper>
            </ModalQRcontainer>
          </ModalMain>
        ) : (
          <ModalMain>
            <ModalTitle marginBottom>Step 1. Download Testflight</ModalTitle>
            <ModalQRcontainer marginBottom>
              <ModalQRwrapper>
                <img
                  src={require('../../images/QRcodeTestflight.png')}
                  width="200px"
                  alt="qr"
                />
                <ModalQRtext>or</ModalQRtext>
                <a
                  href="https://apps.apple.com/app/testflight/id899247664"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button size="startGameModal" variant="contained">
                    TestFlight App
                  </Button>
                </a>
              </ModalQRwrapper>
            </ModalQRcontainer>
            <ModalTitle marginBottom>
              Step 2. Download Metarun via the link
            </ModalTitle>
            <ModalQRcontainer>
              <ModalQRwrapper>
                <img
                  src={require('../../images/QRcodeMetarun.png')}
                  width="200px"
                  alt="qr"
                />
                <ModalQRtext>or</ModalQRtext>
                <a
                  href="https://testflight.apple.com/join/SVLT3kjv"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button size="startGameModal" variant="contained">
                    Metarun App
                  </Button>
                </a>
              </ModalQRwrapper>
            </ModalQRcontainer>
          </ModalMain>
        )}
      </ModalContainer>
    </Modal>
  )
}
