const host = process.env.REACT_APP_API_HOST

export async function quickRegister(answers, referral) {
  return await fetch(`${host}/promo/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: answers.name,
      email: answers.email,
      age: answers.age,
      country: answers.country,
      referral,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}
