import React, { useState, useContext, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from '../Spinner'
import { getRewards } from '../../api'
import { useCopyToClipboard } from '../../hooks'
import { formatDate } from '../../utils/formatDate'
import { Button } from '../Button'
import { UserContext } from '../../contexts/UserContext'

import {
  ReferralContainer,
  ReferralFormContainer,
  Container,
  ReferralInput,
  ReferralInputContainer,
  ReferralInputLabel,
  ButtonContainer,
  ReferralTitle,
  RewardsTable,
  RewardsTableContainer,
  RewardsTableHead,
  RewardsTableItem,
  RewardsTableItemLeft,
  RewardsTableRow,
  RewardsTableItemText,
  RewardsTableItemValue,
} from './Referral.styled'

export default function Referral() {
  const { userAddress, referralCode } = useContext(UserContext)
  const [rewards, setRewards] = useState([])
  const [rewardsLoading, setRewardsLoading] = useState(false)
  const [hasMoreRewards, setHasMoreRewards] = useState(true)
  const [totalAmountOfRewards, setTotalAmountOfRewards] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [successCopy, setSuccessCopy] = useState(false)
  const [copyOnClick, ref] = useCopyToClipboard()

  async function fetchRewards() {
    setRewardsLoading(true)
    await getRewards({ userAddress })
      .then((rewards) => {
        setRewards(rewards.results)
        setTotalAmountOfRewards(rewards.count)
        setRewardsLoading(false)
        setHasMoreRewards(rewards.count > 100)
      })
      .catch((err) => {
        console.log(err)
        setRewardsLoading(false)
        setHasMoreRewards(false)
      })
  }

  const fetchMoreRewards = () => {
    if (rewards.length >= totalAmountOfRewards) {
      setHasMoreRewards(false)
      return
    }

    getRewards({
      page: currentPage + 1,
      userAddress,
    }).then((rewards) => {
      setCurrentPage((prev) => prev + 1)
      setRewards((prevValue) => {
        return prevValue.concat(rewards.results)
      })
    })
  }

  useEffect(() => {
    if (userAddress) {
      setCurrentPage(1)
      fetchRewards()
    }
  }, [userAddress])

  return (
    <ReferralContainer>
      <ReferralTitle>My Referral Link</ReferralTitle>
      <ReferralFormContainer>
        <Container>
          <ReferralInputContainer>
            <ReferralInputLabel>Link</ReferralInputLabel>
            <ReferralInput
              value={`https://marketplace.metarun.game/?invite=${referralCode ||
                ''}`}
              ref={ref}
              readOnly
            />
          </ReferralInputContainer>
          <ButtonContainer>
            <Button
              size="accountReferral"
              variant={successCopy ? 'contained' : 'accountReferral'}
              onClick={() => {
                copyOnClick()
                setSuccessCopy(true)
              }}
            >
              Copy
            </Button>
          </ButtonContainer>
        </Container>
      </ReferralFormContainer>
      <ReferralTitle>My Rewards</ReferralTitle>
      {!rewardsLoading ? (
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={rewards.length}
          next={fetchMoreRewards}
          hasMore={hasMoreRewards}
          loader={<Spinner />}
        >
          {rewards.length > 0 ? (
            <RewardsTable>
              <RewardsTableContainer>
                <RewardsTableHead>
                  <RewardsTableItemLeft>Email</RewardsTableItemLeft>
                  <RewardsTableItem color="head" margin>
                    Action
                  </RewardsTableItem>
                  <RewardsTableItem color="head">Your Reward</RewardsTableItem>
                  <RewardsTableItem color="head">Date</RewardsTableItem>
                </RewardsTableHead>{' '}
                {rewards.map((data, index) => {
                  return (
                    <RewardsTableRow
                      key={index}
                      color={index % 2 === 0 ? '' : 'odd'}
                    >
                      <RewardsTableItem>
                        <RewardsTableItemText>Email</RewardsTableItemText>
                        <RewardsTableItemValue>
                          {data.invited || ''}
                        </RewardsTableItemValue>
                      </RewardsTableItem>
                      <RewardsTableItem color="odd">
                        <RewardsTableItemText>Action</RewardsTableItemText>
                        <RewardsTableItemValue>
                          {data.action}
                        </RewardsTableItemValue>
                      </RewardsTableItem>
                      <RewardsTableItem>
                        <RewardsTableItemText>Your Reward</RewardsTableItemText>
                        <RewardsTableItemValue>
                          {data.amount} opal
                        </RewardsTableItemValue>
                      </RewardsTableItem>
                      <RewardsTableItem color="odd">
                        <RewardsTableItemText>Date</RewardsTableItemText>
                        <RewardsTableItemValue>
                          {formatDate(data.creationTime, true)}
                        </RewardsTableItemValue>
                      </RewardsTableItem>
                    </RewardsTableRow>
                  )
                })}
              </RewardsTableContainer>
            </RewardsTable>
          ) : (
            <>You don&apos;t have any rewards yet</>
          )}{' '}
        </InfiniteScroll>
      ) : (
        <Spinner />
      )}
    </ReferralContainer>
  )
}
