import styled from 'styled-components'
import { Border } from '../Modal/Modal.styled'

export const ModalMain = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalError = styled.p`
  color: red;
  margin: 0 auto 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 100%;
  overflow: hidden;
`

export const BorderStyled = styled(Border)`
  margin: 0 16px;
  width: 100%;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    margin: 0 8px 8px;
  }
`

export const ModalList = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  list-style: circle;
`

export const ModalItem = styled.li`
  margin-bottom: 5px;
  padding: 0;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
`
