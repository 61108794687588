import styled from 'styled-components'

export const ModalForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalInputContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 25px;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`

export const ModalInputContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const ModalInputLabel = styled.label`
  box-sizing: border-box;
  display: block;
  color: #a3a4a9;
  position: absolute;
  top: 18px;
  left: 23px;
  padding: 1px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;

  font-size: 12px;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;

  @media (max-width: 768px) {
    font-size: 10px;

    line-height: 15px;
    letter-spacing: 0.5px;
    top: 10px;
    left: 16px;
  }
`

export const ModalDateInputContainer = styled.div`
  box-sizing: border-box;
  margin-bottom: 60px;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`

export const ModalDateInputLabel = styled.label`
  box-sizing: border-box;
  display: block;
  color: #a3a4a9;
  position: absolute;
  top: 18px;
  left: 23px;
  padding: 1px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;

  font-size: 12px;

  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;

  @media (max-width: 768px) {
    font-size: 10px;

    line-height: 15px;
    letter-spacing: 0.5px;
    top: 10px;
    left: 16px;
  }
`
export const ModalDateInput = styled.input`
  font-family: 'OpenSans';
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 24px;
  cursor: pointer;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.5px;
  padding: 36px 24px 20px;
  color: white;

  background-color: #1d1f2a;
  background-color: ${({ invalid }) => {
    if (invalid) {
      return '#ED49151A'
    }
    return 'inherit'
  }};
  background-clip: padding-box;
  border: 1px solid #2f313b;
  border-color: ${({ invalid }) => {
    if (invalid) {
      return '#322328'
    }
    return '#2f313b'
  }};
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: #a3a4a9;

    opacity: 0.4;
  }

  &:focus {
    color: white;

    outline: 0;
    box-shadow: 0 0 0 1px rgba(158, 158, 158, 0.25);
  }

  &:disabled {
    background-color: #262a35;
    color: #a3a4a9;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 310px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 26px 16px 10px;
    margin-bottom: 30px;
  }
`

export const DatePickerButtonContainer = styled.div`
  position: absolute;
  background-color: #262a35;
  top: 22px;
  right: 20px;
  border-radius: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    top: 9px;
    right: 8px;
    border-radius: 12px;
    width: 42px;
    height: 42px;
  }
`

export const DatePickerButton = styled.img`
  width: 18px;
  height: 18px;
`

export const ModalTextError = styled.p`
  color: red;
  margin: 0 auto 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  overflow: hidden;
  max-width: 100%;
`

export const ModalSpinnerWrapper = styled.div`
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 48px;
  }
`
