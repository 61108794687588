import { useState, useEffect } from 'react'
import { utils } from 'ethers'
import networks from '../networks.json'
import { EIP712_CHAIN_ID } from '../constants'

const useConnection = () => {
  const [userAddress, setUserAddress] = useState('')
  const [chainId, setChainId] = useState('')
  const [accessToken, setAccessToken] = useState()

  function getChain() {
    window.ethereum
      .request({ method: 'eth_chainId' })
      .then((chain) => setChainId(parseInt(chain, 16).toString()))
      .catch((e) => console.log(e))
  }

  async function getUserAddress() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: 'eth_accounts',
      })

      if (accounts.length > 0) {
        const account = accounts[0]
        setUserAddress(utils.getAddress(account))
      }
    }
  }

  async function connectWallet() {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    })

    setUserAddress(utils.getAddress(accounts[0]))
  }

  async function connectToBlockchain(chainIdConnect = EIP712_CHAIN_ID) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networks[chainIdConnect].params.chainId }],
      })
      return true
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networks[chainIdConnect].params],
          })
          return true
        } catch (error) {
          console.error(error)
          return false
        }
      } else {
        console.error(error)
        return false
      }
    }
  }

  function getAccessToken() {
    setAccessToken(window.localStorage.getItem('AccessToken'))
  }

  function addAccessToken(token) {
    window.localStorage.setItem('AccessToken', token)
    setAccessToken(token)
  }

  function resetAccessToken() {
    window.localStorage.removeItem('AccessToken')
    setAccessToken()
  }

  useEffect(() => {
    getUserAddress()
    getAccessToken()
  }, [])

  useEffect(() => {
    if (userAddress) {
      getChain()
    }
  }, [userAddress])

  return {
    userAddress,
    chainId,
    getChain,
    getUserAddress,
    connectWallet,
    connectToBlockchain,
    accessToken,
    addAccessToken,
    resetAccessToken,
  }
}

export default useConnection
