import React, { useEffect, useCallback, useRef } from 'react'

import { Button } from '../../components/Button'

import {
  Modal,
  ModalContainer,
  ModalButtonContainer,
  ModalButtons,
} from './AccountModal.styled'

import { ModalTitle, ModalCloseButton, ModalText } from '../Modal/Modal.styled'

export default function AccountModal({
  isOpen,
  onClose,
  handleOpenRegister,
  setRegisterType,
}) {
  const modal = useRef(null)

  const handleOverlayClose = useCallback((event) => {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }, [])

  useEffect(() => {
    if (modal.current) {
      modal.current.addEventListener('click', handleOverlayClose)
    }
    return () => {
      if (modal.current) {
        modal.current.removeEventListener('click', handleOverlayClose)
      }
    }
  }, [handleOverlayClose])

  return (
    <Modal isOpen={isOpen} ref={modal}>
      <ModalContainer>
        <ModalButtonContainer onClick={onClose}>
          <ModalCloseButton
            src={require('../../images/Close.svg').default}
            alt="close"
            width="14"
            height="14"
          ></ModalCloseButton>
        </ModalButtonContainer>
        <ModalTitle>You are almost there!</ModalTitle>

        <ModalText color="#499EFF" type="account" mb="12px">
          Reward for new users! For linking a wallet address you will receive 25
          OPAL
        </ModalText>
        <ModalText type="account" mb="12px">
          Connect your game account to continue on marketplace
        </ModalText>
        <ModalButtons>
          <Button
            size="account-modal"
            variant="contained"
            onClick={() => {
              setRegisterType('add')
              onClose()
              handleOpenRegister()
            }}
          >
            link to existing game account
          </Button>
          <Button
            size="account-modal"
            onClick={() => {
              setRegisterType('create')
              onClose()
              handleOpenRegister()
            }}
          >
            Create new game account
          </Button>
        </ModalButtons>
      </ModalContainer>
    </Modal>
  )
}
