import React, { useEffect, useCallback, useRef } from 'react'
import {
  Modal,
  ModalContainer,
  ModalCloseButton,
  ModalButtonContainer,
} from './Modal.styled'

export default function ModalComponent({ isOpen, onClose, width, children }) {
  const modal = useRef(null)

  const handleOverlayClose = useCallback((event) => {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }, [])

  useEffect(() => {
    if (modal.current) {
      modal.current.addEventListener('click', handleOverlayClose)
    }
    return () => {
      if (modal.current) {
        modal.current.removeEventListener('click', handleOverlayClose)
      }
    }
  }, [handleOverlayClose])

  return (
    <Modal isOpen={isOpen} ref={modal}>
      <ModalContainer width={width}>
        <ModalButtonContainer onClick={onClose}>
          <ModalCloseButton
            src={require('../../images/Close.svg').default}
            alt="close"
            width="14"
            height="14"
          ></ModalCloseButton>
        </ModalButtonContainer>
        {children}
      </ModalContainer>
    </Modal>
  )
}
