import React, { useEffect, useState, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from '../Spinner'
import { getCompetitiveRuns } from '../../api'
import { shortenAddress } from '../../utils/shortenAddress'
import networks from '../../networks.json'
import { getTransactionImage } from '../../utils'
import { getFormattedUTCDateFromString } from '../../utils/formatDate'
import { UserContext } from '../../contexts/UserContext'

import {
  RunsContainer,
  RunsTable,
  RunsTableHead,
  RunsTableRow,
  RunsTableItem,
  RunsTableItemLeft,
  RunsTableItemContainer,
  RunsTableContainer,
  RunsTableFooter,
  RunsTableFooterItem,
  RunsTx,
  RunsStatus,
  RunsTableImg,
  HiddenTitle,
  MobileText,
} from './Runs.styled'

export default function CompetitiveRuns({ today }) {
  const { userAddress, chainId } = useContext(UserContext)
  const [runs, setRuns] = useState([])
  const [runsLoading, setRunsLoading] = useState(false)
  const [hasMoreRuns, setHasMoreRuns] = useState(true)
  const [totalAmountOfRuns, setTotalAmountOfRuns] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  async function fetchRuns() {
    setRunsLoading(true)
    await getCompetitiveRuns({ userAddress, today })
      .then((runs) => {
        setRuns(runs.results)
        setTotalAmountOfRuns(runs.count)
        setRunsLoading(false)
        setHasMoreRuns(runs.count > 20)
      })
      .catch((err) => {
        console.log(err)
        setRunsLoading(false)
        setHasMoreRuns(false)
      })
  }

  const fetchMoreRuns = () => {
    if (runs.length >= totalAmountOfRuns) {
      setHasMoreRuns(false)
      return
    }

    getCompetitiveRuns({
      page: currentPage + 1,
      userAddress,
      today,
    }).then((runs) => {
      setCurrentPage((prev) => prev + 1)
      setRuns((prevValue) => {
        return prevValue.concat(runs.results)
      })
    })
  }

  useEffect(() => {
    if (userAddress) {
      setCurrentPage(1)
      fetchRuns()
    }
  }, [userAddress, today])

  return (
    <RunsContainer>
      {!runsLoading ? (
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={runs.length}
          next={fetchMoreRuns}
          hasMore={hasMoreRuns}
          loader={<Spinner />}
        >
          {runs && runs.length > 0 ? (
            runs.map((run, index) => (
              <RunsTable key={index}>
                <RunsTableContainer>
                  <RunsTableHead hidden>
                    <RunsTableItemLeft></RunsTableItemLeft>
                    <RunsTableItem color="head">Address</RunsTableItem>
                    <RunsTableItem color="head">Character</RunsTableItem>
                    <RunsTableItem color="head">Opal</RunsTableItem>
                    <RunsTableItem color="head">Experience</RunsTableItem>
                  </RunsTableHead>
                  <RunsTableRow color="gold">
                    <RunsTableItem color="gold">
                      <RunsTableItemContainer>
                        <RunsTableImg
                          src={require('../../images/GoldMedal.svg').default}
                          alt="medal"
                          width="23px"
                          height="28px"
                        />
                        1st
                        <HiddenTitle> Place</HiddenTitle>
                      </RunsTableItemContainer>
                    </RunsTableItem>
                    <RunsTableItem>
                      {run.winnerUser && shortenAddress(run.winnerUser)}
                    </RunsTableItem>
                    <RunsTableItem>{run.winnerCharacter}</RunsTableItem>
                    <RunsTableItem>
                      {run.winnerOpal}
                      <MobileText> Opal</MobileText>
                    </RunsTableItem>
                    <RunsTableItem>{run.winnerExperience || 0}</RunsTableItem>
                  </RunsTableRow>
                  <RunsTableRow color="silver">
                    <RunsTableItem color="silver">
                      <RunsTableItemContainer>
                        <RunsTableImg
                          src={require('../../images/SilverMedal.svg').default}
                          alt="medal"
                          width="23px"
                          height="28px"
                        />
                        2nd
                        <HiddenTitle> Place</HiddenTitle>
                      </RunsTableItemContainer>
                    </RunsTableItem>
                    <RunsTableItem>
                      {run.secondPlaceUser &&
                        shortenAddress(run.secondPlaceUser)}
                    </RunsTableItem>
                    <RunsTableItem>{run.secondPlaceCharacter}</RunsTableItem>
                    <RunsTableItem>
                      {run.secondPlaceOpal}
                      <MobileText> Opal</MobileText>
                    </RunsTableItem>
                    <RunsTableItem>
                      {run.secondPlaceExperience || 0}
                    </RunsTableItem>
                  </RunsTableRow>
                  <RunsTableRow color="bronze">
                    <RunsTableItem color="bronze">
                      <RunsTableItemContainer>
                        <RunsTableImg
                          src={require('../../images/BronzeMedal.svg').default}
                          alt="medal"
                          width="23px"
                          height="28px"
                        />
                        3rd
                        <HiddenTitle> Place</HiddenTitle>
                      </RunsTableItemContainer>
                    </RunsTableItem>
                    <RunsTableItem>
                      {run.loserUser && shortenAddress(run.loserUser)}
                    </RunsTableItem>
                    <RunsTableItem>{run.loserCharacter}</RunsTableItem>
                    <RunsTableItem>
                      {run.loserOpal}
                      <MobileText> Opal</MobileText>
                    </RunsTableItem>
                    <RunsTableItem>0</RunsTableItem>
                  </RunsTableRow>
                </RunsTableContainer>
                <RunsTableFooter>
                  <RunsTableFooterItem>
                    {getFormattedUTCDateFromString(run.creationTime)}
                  </RunsTableFooterItem>
                  <RunsTableFooterItem>
                    Transaction:{' '}
                    {run.txHash && (
                      <RunsTx
                        target="_blank"
                        href={
                          networks[chainId]
                            ? `${networks[chainId].params.blockExplorerUrls}tx/${run.txHash}`
                            : ''
                        }
                      >
                        {shortenAddress(run.txHash)}{' '}
                      </RunsTx>
                    )}
                    <RunsStatus>
                      <img src={getTransactionImage(run.status)} />
                    </RunsStatus>
                  </RunsTableFooterItem>
                </RunsTableFooter>
              </RunsTable>
            ))
          ) : (
            <>You don&apos;t have any runs yet</>
          )}
        </InfiniteScroll>
      ) : (
        <Spinner />
      )}
    </RunsContainer>
  )
}
