import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { Link as RecatLink } from 'react-router-dom'

export const RevealWrapper = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
`

export const RevealContainer = styled.div`
  width: 100%;
  max-width: 1034px;
  overflow: hidden;
  height: 711px;
  max-height: 711px;
  border: 1px solid rgba(73, 158, 255, 0.3);
  background-color: rgb(29, 28, 57);
  backdrop-filter: blur(10px);
  margin: 80px 24px 40px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin: 40px 12px 20px;
    border-radius: 12px;
    height: 600px;
    max-width: 600px;
  }
`

export const RevealTitleWrapper = styled.div`
  z-index: 10;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 36px 32px 0px 42px;
  @media (max-width: 768px) {
    padding: 24px;
  }
`
export const RevealTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
export const RevealMain = styled.div`
  margin-top: ${({ type }) => {
    if (type === 'image') {
      return '40px'
    } else if (type === 'approval') {
      return '140px'
    }
    return '230px'
  }};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-top: ${({ type }) => {
      if (type === 'spinner') {
        return '180px'
      } else if (type === 'image') {
        return '40px'
      } else if (type === 'approval') {
        return '30px'
      }
      return '130px'
    }};
  }
`

export const RevealText = styled.div`
  width: 100%;
  max-width: 620px;
  color: white;
  margin-bottom: 20px;
  letter-spacing: 0.4px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  @media (max-width: 768px) {
    max-width: 350px;
    padding: 0 12px;
    box-sizing: border-box;
  }
`

export const RevealTxText = styled.p`
  width: max-content;
  color: white;

  margin: 24px auto 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`

export const RevealTxLink = styled.a`
  width: max-content;
  color: white;

  margin: 24px auto 20px;
  display: block;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`

export const RevealError = styled.p`
  color: red;
  margin: 0 auto 4px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 100%;
  overflow: hidden;
  min-height: 20px;
  padding: 0 50px;
  box-sizing: border-box;
  max-height: 100px;
  @media (max-width: 768px) {
    line-height: 20px;
    padding: 0 12px;
    margin-bottom: 4px;
  }
`

export const RevealVideo = styled(ReactPlayer)`
  width: 100% !important;
  z-index: -1;
  height: auto !important;
  position: absolute;
  top: -150px;

  @media (max-width: 1000px) {
    top: -0px;
  }

  @media (max-width: 700px) {
    top: 50px;
  }
  @media (max-width: 500px) {
    top: 70px;
  }
  @media (max-width: 400px) {
    top: 110px;
  }
`

export const RevealImage = styled.img`
  width: 100%;
  height: 364px;
  object-fit: cover;
  @media (max-width: 768px) {
    height: 230px;
  }
`

export const RevealImageContainer = styled.div`
  margin: auto;
  margin-bottom: 24px;
  background: linear-gradient(
    184.67deg,
    rgba(72, 157, 254, 0.63) 1.89%,
    rgba(34, 7, 90, 0.42) 96.23%
  );
  backdrop-filter: blur(5px);
  border-radius: 20px;
  z-index: -1;
  padding: 21px 0 24px;
  max-width: 324px;
  @media (max-width: 768px) {
    max-width: 190px;
    padding: 12px 0;
    margin-bottom: 12px;
  }
`

export const BackButton = styled(RecatLink)`
  display: inline-flex;
  align-items: center;
  background: rgb(29, 28, 57);
  border: 1px solid rgba(73, 158, 255, 0.3);
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 20px;
  height: 48px;
  font-size: 16px;
  padding: 5px 22px;

  font-style: italic;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;

  @media (max-width: 768px) {
    border-radius: 12px;
    height: 36px;
    font-size: 14px;
    padding: 5px 14px;
  }
`

export const RevealImageHead = styled.ul`
  padding: 0 16px;
  list-style: none;
  display: flex;
  gap: 10px;
  margin: 0 0 8px;
  @media (max-width: 768px) {
    gap: 5px;
    padding: 0 8px;
    margin: 0;
  }
`

export const RevealKindContainer = styled.li`
  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  border-radius: 6px;
  transform: skew(-13deg, 0);
  padding: 1px;
`

export const RevealKind = styled.div`
  padding: 3px;
  border-radius: 6px;
  min-width: 104px;
  box-sizing: border-box;

  background: #182f52;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    min-width: 40px;
    padding: 0 5px;
  }
`
export const RevealKindText = styled.span`
  text-transform: uppercase;
  font-size: 16px;

  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;

  background: ${({ type }) => {
    switch (type) {
      case 'Common':
        return 'linear-gradient(274.55deg, #00D37B 18.46%, #00C537 49.25%, #00D37B 79.09%);'
      case 'Rare':
        return 'linear-gradient(112.38deg, #37FBEF 0%, #17B8C2 50%, #37FBEF 100%);'
      case 'Mythical':
        return 'linear-gradient(124.58deg, #6D38FF 24.86%, #920FFF 51.93%, #6D38FF 76.84%);'
      case 'Classic':
        return 'linear-gradient(124.58deg, #006DED 24.86%, #00B2FF 51.93%, #006DED 76.84%);'
      case 'Epic':
        return 'linear-gradient(112.38deg, #FB3766 0%, #FF006B 50%, #FB3766 100%);'
      case 'Legendary':
        return 'linear-gradient(112.38deg, #FDD502 0%, #B58425 51%, #FDD502 100%);'
    }
    return 'linear-gradient(124.58deg, #595959 24.86%, #878787 51.93%, #595959 76.84%);'
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`

export const RevealSubtitle = styled.h3`
  bottom: 40px;
  margin: 0;

  max-width: 620px;
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 330px;
    font-size: 18px;
    box-sizing: border-box;
  }
`

export const RevealSubtitleSpan = styled.span`
  text-transform: uppercase;
  color: #7a97df;
`

export const RevealHiddenContainer = styled.div`
  transition: visibility 8s cubic-bezier(1, 0.01, 1, -0.72),
    opacity 8s cubic-bezier(1, 0.01, 1, -0.72),
    transform 8s cubic-bezier(1, 0.01, 1, -0.72);
  visibility: ${({ display }) => {
    if (display) {
      return 'visible'
    }
    return 'hidden'
  }};

  opacity: ${({ display }) => {
    if (display) {
      return '1'
    }
    return '0'
  }};

  transform: ${({ display }) => {
    if (display) {
      return 'scale(1)'
    }
    return 'scale(0)'
  }};
`
