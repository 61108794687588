import React, { useState, useContext } from 'react'
import { utils } from 'ethers'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Button } from '../../components/Button'
import { SUPPORTED_CHAINS } from '../../constants'
import { getNetworkIcon } from '../../utils'
import { UserContext } from '../../contexts/UserContext'

import {
  BuyTableItem,
  BuyTableRow,
  AccordionButton,
  BuyTableRowWrapper,
  BuyTableRowMore,
  Border,
  MoreTitle,
  MoreList,
  MoreContainer,
  MoreItem,
  MoreItemDescription,
  MoreItemValue,
  StyledLink,
  ButtonLinkWrapper,
  BuyTableItemDescription,
  MoreDetails,
  LessDetails,
  MobileBorder,
  Icon,
} from './Buy.styled'

export default function OrderBuyTableRowItem({ order, info }) {
  const { userAddress, chainId } = useContext(UserContext)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  return (
    <>
      <BuyTableRowWrapper>
        <BuyTableRow show={isDetailsOpen}>
          <BuyTableItem>
            <BuyTableItemDescription>Chain</BuyTableItemDescription>
            <Icon
              src={getNetworkIcon(order.ownership.kind.chain)}
              alt="networkicon"
            />
          </BuyTableItem>
          <BuyTableItem>
            <BuyTableItemDescription>Price</BuyTableItemDescription>
            <span>
              {order.price ? utils.formatEther(order.price) : '0'} MRUN
            </span>
          </BuyTableItem>
          <BuyTableItem>
            <BuyTableItemDescription>Seller</BuyTableItemDescription>
            <span style={{ color: '#4188FF' }}>
              {order.ownership ? order.ownership.holderName : '-'}
            </span>
          </BuyTableItem>
          <BuyTableItem>
            <BuyTableItemDescription>Token ID</BuyTableItemDescription>
            <span>
              {order.ownership ? order.ownership.kind.contractTokenId : '-'}
            </span>
          </BuyTableItem>
          <MobileBorder />
          <BuyTableItem button>
            <ButtonLinkWrapper
              disabled={!userAddress || !SUPPORTED_CHAINS.includes(chainId)}
            >
              <StyledLink
                to={`/detail/${order.ownership.pk}`}
                disabled={!userAddress || !SUPPORTED_CHAINS.includes(chainId)}
              >
                <Button
                  variant="contained"
                  size="buypage"
                  disabled={!userAddress || !SUPPORTED_CHAINS.includes(chainId)}
                >
                  Buy
                </Button>
              </StyledLink>
            </ButtonLinkWrapper>
            {info.name !== 'MYSTERY BOX' && (
              <AccordionButton onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
                {isDetailsOpen ? (
                  <ExpandLessIcon
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{
                      color: 'white',
                    }}
                  />
                )}
              </AccordionButton>
            )}
          </BuyTableItem>
          {info.name !== 'MYSTERY BOX' && (
            <MoreDetails
              show={isDetailsOpen}
              onClick={() => setIsDetailsOpen(true)}
            >
              See Details{' '}
              <ExpandMoreIcon
                sx={{
                  color: '#FFFFFF8F',
                  position: 'relative',
                  top: '6px',
                }}
              />
            </MoreDetails>
          )}
        </BuyTableRow>
        <BuyTableRowMore show={isDetailsOpen}>
          <Border />
          <MoreContainer>
            <MoreTitle>Attributes</MoreTitle>
            <MoreList>
              <MoreItem>
                <MoreItemDescription>Action Ability:</MoreItemDescription>
                <MoreItemValue>
                  {order.ownership
                    ? order.ownership.kind.attributes.actionAbility
                    : '0'}
                </MoreItemValue>
              </MoreItem>
              <MoreItem>
                <MoreItemDescription>Mana Ability:</MoreItemDescription>
                <MoreItemValue>
                  {order.ownership
                    ? order.ownership.kind.attributes.manaAbility
                    : '0'}
                </MoreItemValue>
              </MoreItem>
            </MoreList>
            <MoreList>
              <MoreItem>
                <MoreItemDescription>Level: </MoreItemDescription>
                <MoreItemValue>
                  {order.ownership
                    ? order.ownership.kind.attributes.level
                    : '0'}
                </MoreItemValue>
              </MoreItem>
            </MoreList>
          </MoreContainer>
          {info.name !== 'MYSTERY BOX' && (
            <LessDetails
              show={isDetailsOpen}
              onClick={() => setIsDetailsOpen(false)}
            >
              See Details{' '}
              <ExpandLessIcon
                sx={{
                  color: '#FFFFFF8F',
                  position: 'relative',
                  top: '6px',
                }}
              />
            </LessDetails>
          )}
        </BuyTableRowMore>
      </BuyTableRowWrapper>
    </>
  )
}
