const host = process.env.REACT_APP_API_HOST

export async function setUserInfo(username, address, token) {
  return await fetch(`${host}/users/${address}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      username,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getUser(address) {
  return await fetch(`${host}/users/${address}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
