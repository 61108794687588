import React, { useCallback, useEffect, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './MultiRangeSlider.css'

const MultiRangeSlider = ({
  min,
  max,
  minVal,
  maxVal,
  setMaxVal,
  setMinVal,
  setLevelFilters,
  levelFilters,
}) => {
  const minValRef = useRef(null)
  const maxValRef = useRef(null)
  const range = useRef(null)

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  )

  function resetRange() {
    setMinVal(min)
    setMaxVal(max)
    setLevelFilters()
  }

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal)
      const maxPercent = getPercent(+maxValRef.current.value) // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxVal)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxVal, getPercent])

  return (
    <>
      <div className="speed__container">
        <div className="speed__wrapper">
          <img
            className="speed__image"
            src={require('../../images/Star.svg').default}
            alt="speed"
            width="20"
            height="20"
          />
          <h4 className="speed__title">Level</h4>
        </div>
        <button className="speed__button" onClick={resetRange}>
          Reset
        </button>
      </div>

      <div className="container">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          ref={minValRef}
          onChange={(event) => {
            const value = Math.min(+event.target.value, maxVal - 1)
            setMinVal(value)
            event.target.value = value.toString()
          }}
          onMouseUp={(event) => {
            const value = Math.min(+event.target.value, maxVal - 1)
            setMinVal(value)
            setLevelFilters({
              min: value,
              max: levelFilters ? levelFilters.max : max,
            })
            event.target.value = value.toString()
          }}
          onTouchEnd={(event) => {
            const value = Math.min(+event.target.value, maxVal - 1)
            setMinVal(value)
            setLevelFilters({
              min: value,
              max: levelFilters ? levelFilters.max : max,
            })
            event.target.value = value.toString()
          }}
          className={classnames('thumb thumb--zindex-3', {
            'thumb--zindex-5': minVal > max - 100,
          })}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          ref={maxValRef}
          onChange={(event) => {
            const value = Math.max(+event.target.value, minVal + 1)
            setMaxVal(value)
            event.target.value = value.toString()
          }}
          onMouseUp={(event) => {
            const value = Math.max(+event.target.value, minVal + 1)
            setMaxVal(value)
            setLevelFilters({
              min: levelFilters ? levelFilters.min : min,
              max: value,
            })
            event.target.value = value.toString()
          }}
          onTouchEnd={(event) => {
            const value = Math.max(+event.target.value, minVal + 1)
            setMaxVal(value)
            setLevelFilters({
              min: levelFilters ? levelFilters.min : min,
              max: value,
            })
            event.target.value = value.toString()
          }}
          className="thumb thumb--zindex-4"
        />

        <div className="slider">
          <div className="slider__track" />
          <div ref={range} className="slider__range" />

          <div>
            <input
              className="slider__left-value"
              min={min}
              max={max}
              value={minVal}
              readOnly
            />
          </div>
          <div className="slider__divider">-</div>
          <div>
            <input
              className="slider__right-value"
              min={min}
              max={max}
              value={maxVal}
              readOnly
            />
          </div>
        </div>
      </div>
    </>
  )
}

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default MultiRangeSlider
