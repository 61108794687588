import { BuyButton } from '../CardDetail.styled'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'

import SwapTicketModal from '../../../components/SwapTicketModal'

export function SwapTicketButton({ tokenId, swapModalOpen, setSwapModalOpen }) {
  const handleOpenSwapModal = () => setSwapModalOpen(true)

  const handleCloseSwapModal = () => setSwapModalOpen(false)

  return (
    <>
      <BuyButton onClick={handleOpenSwapModal}>
        <CompareArrowsIcon />
        {'Swap to character'}
      </BuyButton>
      <SwapTicketModal
        isOpen={swapModalOpen}
        onClose={handleCloseSwapModal}
        tokenId={tokenId}
      />
    </>
  )
}
