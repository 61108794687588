import { StyledButton, StyledBorder } from './Button.styled'

export function Button({
  children,
  disabled,
  size,
  variant,
  onClick,
  info,
  invalid,
}) {
  return (
    <StyledBorder disabled={disabled} variant={variant} size={size}>
      <StyledButton
        disabled={disabled}
        onClick={onClick}
        size={size}
        variant={variant}
        info={info}
        invalid={invalid}
      >
        {children}
      </StyledButton>
    </StyledBorder>
  )
}
