import styled from 'styled-components'

export const InfoContainer = styled.div`
  margin: 0 20px;
  padding: 120px 0;

  @media (max-width: 768px) {
    padding: 100px 0;
    margin: 0 12px;
  }
`

export const MainInfo = styled.div`
  width: 100%;
  margin: 0 auto 43px;
  max-width: 520px;
  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(73, 158, 255, 0.6);
  padding: 40px 40px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin-bottom: 32px;
    border-radius: 12px;
    padding: 24px;
  }
`

export const InfoTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0 0 40px;
  text-transform: uppercase;
`

export const InfolImage = styled.img`
  margin: 0 auto 16px;
  display: block;
`

export const InfoText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;
  color: white;
  margin: 0;
  @media (max-width: 768px) {
    max-width: 200px;
    margin: 0 auto;
  }
`

export const UnsubscribeText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;
  color: white;
  margin: 0 0 16px;
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: max-content;
`
