import styled from 'styled-components'

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 16px;
`

export const ModalTerms = styled.div`
  max-height: 395px;
  margin-bottom: 24px;
  margin-right: 24px;
`

export const ModalSubtitle = styled.h3`
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #7a97df;
`

export const ModalCheckboxLabel = styled.label`
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: left;
  color: white;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
`

export const ModalButtonItem = styled.div`
  width: 47%;
`
