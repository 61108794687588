import styled from 'styled-components'

export const StyledBorder = styled.span`
  background: linear-gradient(
    88.82deg,
    #489dfe 11.9%,
    #3970fe 45.06%,
    #5e51ff 76.01%
  );
  width: ${({ size }) => {
    switch (size) {
      case 'terms-modal':
        return '100%'
      case 'buy':
        return '100%'

      case 'withdraw':
        return '100%'
    }
    return ' auto'
  }};
  border-radius: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return '18px'
      case 'linkModal':
        return '6px'
      case 'successModal':
        return '6px'
      case 'registerModal':
        return '6px'
      case 'accountSettings':
        return '6px'
    }
    return '8px'
  }};
  box-shadow: 0;

  transform: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return 'none'
      case 'startGameModal':
        return 'none'
      case 'buy':
        return 'skew(-13deg, 0)'
      case 'buypage':
        return 'skew(-13deg, 0)'
      case 'reveal':
        return 'skew(-13deg, 0)'
    }
    return 'skew(-16deg, 0)'
  }};
  color: #122dff;
  display: inline-block;
  font-size: 20px;
  padding: 1px;
  text-decoration: none;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;

  max-width: ${({ size }) => {
    switch (size) {
      case 'buy':
        return '175px'
    }
    return 'auto'
  }};

  &:disabled,
  &[disabled] {
    box-shadow: none;
    opacity: 0.7;
    background: ${({ size }) => {
      switch (size) {
        case 'linkModal':
          return 'transparent'
        case 'registerModal':
          return 'transparent'
        case 'accountSettings':
          return 'transparent'
      }
    }};
  }
  &:active {
    transform: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return 'scale(0.9)'
        case 'startGameModal':
          return 'scale(0.9)'
        case 'buy':
          return 'skew(-13deg, 0) scale(0.9)'
        case 'buypage':
          return 'skew(-13deg, 0) scale(0.9)'
        case 'reveal':
          return 'skew(-13deg, 0) scale(0.9)'
      }
      return 'skew(-16deg, 0) scale(0.9)'
    }};
    opacity: 0.9;
    box-shadow: 0 2px 25px #489dfe9c;
  }

  &:disabled&:active,
  &[disabled]&:active {
    transform: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return 'none'
        case 'startGameModal':
          return 'none'
        case 'buy':
          return 'skew(-13deg, 0)'
        case 'buypage':
          return 'skew(-13deg, 0)'
        case 'reveal':
          return 'skew(-13deg, 0)'
      }
      return 'skew(-16deg, 0)'
    }};
    opacity: 0.7;
    box-shadow: none;
  }
  @media (max-width: 768px) {
    border-radius: 8px;
    width: ${({ size }) => {
      switch (size) {
        case 'extralong':
          return '100%'
        case 'account-modal':
          return '100%'
        case 'terms-modal':
          return '100%'
        case 'accountSettings':
          return '100%'
        case 'startGameModal':
          return '220px'
        case 'buy':
          return '100%'
        case 'buypage':
          return '100%'
        case 'withdraw':
          return '100%'
      }
      return ' auto'
    }};
    max-width: ${({ size }) => {
      switch (size) {
        case 'accountSettings':
          return '288px'
        case 'buy':
          return '280px'
        case 'buypage':
          return '290px'
      }
      return 'auto'
    }};
  }
`

export const StyledButton = styled.button`
  position: relative;
  border: none;
  width: ${({ size }) => {
    switch (size) {
      case 'account-modal':
        return '100%'
      case 'terms-modal':
        return '100%'
      case 'linkModal':
        return '221px'
      case 'successModal':
        return '221px'
      case 'registerModal':
        return '269px'
      case 'accountSettings':
        return '221px'
      case 'buy':
        return '100%'
      case 'buypage':
        return '100%'
      case 'withdraw':
        return '100%'
    }
    return ' auto'
  }};
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-style: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return 'italic'
    }
    return ' normal'
  }};
  font-weight: 700;
  line-height: ${({ size }) => {
    switch (size) {
      case 'linkModal':
        return '25.6px'
      case 'successModal':
        return '26px'
      case 'registerModal':
        return '26px'
      case 'accountSettings':
        return '26px'
    }
    return ' 26px'
  }};
  letter-spacing: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return '0.4px'
      case 'linkModal':
        return '0.4px'
      case 'successModal':
        return '0.4px'
      case 'registerModal':
        return '0.46px'
      case 'accountSettings':
        return '0.4px'
    }
    return ' 1px'
  }};
  text-align: center;
  text-transform: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return 'none'
    }
    return ' uppercase'
  }};
  background: ${({ variant, invalid }) => {
    switch (variant) {
      case 'contained':
        return 'linear-gradient(88.69deg, #489DFE 7.92%, #3970FE 44.55%, #5F59FF 78.73%);'
      case 'unsubscribe':
        return '#0f0e2c'
      case 'buy':
        return 'rgb(16,16,44)'
      case 'modal':
        if (invalid) {
          return '#2a1a2a'
        } else {
          return 'rgb(16, 16, 44)'
        }
      case 'linkModal':
        return 'linear-gradient(89.43deg, #489DFE 12.7%, #3970FE 28.69%, #5E51FF 43.63%);'
      case 'successModal':
        return 'linear-gradient(89.53deg, #489DFE 24.93%, #3970FE 38.16%, #5E51FF 50.5%);'
      case 'registerModal':
        return 'linear-gradient(89.53deg, #489DFE 24.93%, #3970FE 38.16%, #5E51FF 50.5%);'
      case 'accountSettings':
        return 'linear-gradient(89.43deg, #489DFE 12.7%, #3970FE 28.69%, #5E51FF 43.63%);'
      case 'accountReferral':
        return 'rgb(16,16,44);'
    }
    return '#1b1a4e'
  }};
  display: block;
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '8px 20px'
      case 'long':
        return '6px 49.5px'
      case 'extralong':
        return '13px 120px'
      case 'rounded':
        return '14px 24px'
      case 'linkModal':
        return '14px 73px'
      case 'successModal':
        return '14px 31px'
      case 'registerModal':
        return '14px 33px'
      case 'accountSettings':
        return '14px 73px'
      case 'unsubscribe':
        return '12px 42px'
      case 'install':
        return '12px 30px'
      case 'reveal':
        return '11px 70px'
      case 'accountReferral':
        return ' 12px 40px'
      case 'withdraw':
        return '13px 120px'
    }
    return ' 12px 20px'
  }};

  }};
  border-radius: ${({ size }) => {
    switch (size) {
      case 'rounded':
        return '18px'
      case 'linkModal':
        return '6px'
      case 'successModal':
        return '6px'
      case 'registerModal':
        return '6px'
    }
    return '8px'
  }};

  min-height: 0;
  min-width: ${({ size }) => {
    switch (size) {
      case 'startGameModal':
        return '189px'
    }
    return 'auto'
  }};
  color: white;
  transition: opacity 0.5s, background 1s;
  cursor: pointer;
  line-height: 24px;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color:rgba(255, 255, 255, 0.3);
    padding: ${({ size }) => {
      switch (size) {
        case 'linkModal':
          return '14px 20px'
      }
    }};
    background: ${({ size }) => {
      switch (size) {
        case 'linkModal':
          return 'rgba(72, 157, 254, 0.1);'
        case 'registerModal':
          return 'rgba(72, 157, 254, 0.1);'
      }
    }};
  }

  &:disabled:hover&:after,
  &[disabled]:hover&:after {
    display:none;
  }

  &:hover&:after {
    content: '';
    position: absolute;
    border-radius: ${({ size }) => {
      switch (size) {
        case 'rounded':
          return '18px'
      }
      return '8px'
    }};
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity:0.1;
  }

  @media (max-width: 768px) {
    min-height:0;
    font-size: ${({ size }) => {
      switch (size) {
        case 'extralong':
          return '16px'
        case 'accountSettings':
          return '16px'
        case 'startGameModal':
          return '16px'
        case 'unsubscribe':
          return '16px'
        case 'buy':
          return '16px'
        case 'buypage':
          return '16px'
        case 'reveal':
          return '16px'
        case 'withdraw':
          return '16px'
      }
      return ' 13px'
    }};
    border-radius:8px;
    min-width: ${({ size }) => {
      switch (size) {
        case 'startGameModal':
          return '120px'
      }
      return 'auto'
    }};
    line-height: 24px;

    padding: ${({ size }) => {
      switch (size) {
        case 'long':
          return '6px 49.5px'
        case 'extralong':
          return '13px 50px'
        case 'rounded':
          return '6px 12px'
        case 'modal':
          return '8px 24px'
        case 'account-modal':
          return '8px 10px'
        case 'accountSettings':
          return '12px 11px'
        case 'startGameModal':
          return '12px 14px'
        case 'unsubscribe':
          return '12px 100px'
        case 'buy':
          return '12px 16px'
        case 'buypage':
          return '12px 16px'
        case 'reveal':
          return '11px 50px'
        case 'accountReferral':
          return ' 3px 12px'
        case 'withdraw':
          return '13px 50px'
      }
      return ' 5px 14px'
    }};

    width: ${({ size }) => {
      switch (size) {
        case 'extralong':
          return '100%'
        case 'filter':
          return '100%'
        case 'account-modal':
          return '100%'
        case 'modal':
          return '100%'
        case 'terms-modal':
          return '100%'
        case 'linkModal':
          return '150px'
        case 'successModal':
          return '150px'
        case 'registerModal':
          return '150px'
        case 'accountSettings':
          return '100%'
        case 'startGameModal':
          return '100%'
        case 'buy':
          return '100%'
        case 'buypage':
          return '100%'
        case 'withdraw':
          return '100%'
      }
      return 'auto'
    }};

    &:hover&:after {

      border-radius:8px;

    }
  }      
`
