import styled from 'styled-components'

export const ModalLink = styled.a`
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  max-width: 350px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    max-width: 250px;
  }
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalImage = styled.img`
  margin: 0 auto 16px;
`
export const ModalWrapper = styled.div`
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`
