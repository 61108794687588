import { utils } from 'ethers'
import { Button } from '../Button'

import {
  StyledCard,
  CardContent,
  CardImage,
  InfoContainer,
  InfoHeadContainer,
  KindContainer,
  KindImage,
  PriceContainer,
  PriceWrapper,
  ButtonContainer,
  KindImageContainer,
  Border,
  ButtonLinkWrapper,
  StyledLink,
} from './BuyBoxCard.styled'

export default function BuyBoxCard({ display, activeOrdersNumber }) {
  return (
    <StyledCard display={display ? display.toString() : undefined}>
      <InfoHeadContainer>
        <KindContainer>
          <KindImageContainer>
            <KindImage
              width="20"
              height="20"
              alt="kind"
              src={require('../../images/MysteryBox.svg').default}
            />
          </KindImageContainer>{' '}
          Mystery Box
        </KindContainer>
      </InfoHeadContainer>
      <CardImage
        src={'https://app.metarun.game/images/mystery-box.png'}
        alt="token"
        width="321"
        height="363"
      />
      <CardContent>
        <InfoContainer>
          <PriceContainer>Last Price:</PriceContainer>
          <PriceWrapper>
            {' '}
            {activeOrdersNumber &&
            activeOrdersNumber['mystery-box'] &&
            activeOrdersNumber['mystery-box'].lastPrice !== '0'
              ? `${utils.formatEther(
                  activeOrdersNumber['mystery-box'].lastPrice,
                )} MRUN`
              : `$99`}
          </PriceWrapper>
        </InfoContainer>
        <Border />
        <ButtonContainer>
          <ButtonLinkWrapper>
            <StyledLink to={`buy/mystery-box`}>
              <Button variant="contained">Buy Now</Button>
            </StyledLink>
          </ButtonLinkWrapper>
        </ButtonContainer>
      </CardContent>
    </StyledCard>
  )
}
