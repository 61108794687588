import {
  ExpandFilterLessIcon,
  ExpandFilterMoreIcon,
  FilterItem,
  FilterItemList,
  FilterItemTitle,
  FilterPropertyItem,
  FilterPropertyItemList,
  FilterPropertyItemWrapper,
} from './Filter.styled'

const ChainFilter = ({
  isFilterItemsOpen,
  setIsFilterItemsOpen,
  setKindFilters,
  kindFilters,
}) => {
  return (
    <FilterItem>
      <FilterItemTitle
        onClick={() => {
          setIsFilterItemsOpen((prevState) => {
            return {
              ...prevState,
              Chains: !isFilterItemsOpen.Chains,
            }
          })
        }}
      >
        Chains
        {isFilterItemsOpen.Chains ? (
          <ExpandFilterLessIcon />
        ) : (
          <ExpandFilterMoreIcon />
        )}
      </FilterItemTitle>

      <FilterItemList show={isFilterItemsOpen.Chains}>
        <FilterPropertyItemList>
          <FilterPropertyItemWrapper
            onClick={() => {
              setKindFilters((prevState) => {
                return {
                  ...prevState,
                  BSC: !kindFilters.BSC,
                }
              })
            }}
          >
            <FilterPropertyItem
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: '3px',
              }}
              isChecked={kindFilters && kindFilters.BSC}
            >
              <img src={require('../../images/BNB.svg').default} />
              <p style={{ marginLeft: '7px' }}>BSC</p>
            </FilterPropertyItem>
          </FilterPropertyItemWrapper>
          <FilterPropertyItemWrapper
            onClick={() => {
              setKindFilters((prevState) => {
                return {
                  ...prevState,
                  Polygon: !kindFilters.Polygon,
                }
              })
            }}
          >
            <FilterPropertyItem
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: '3px',
              }}
              isChecked={kindFilters && kindFilters.Polygon}
            >
              <img
                src={require('../../images/polygon-icon.svg').default}
                width="35"
              />
              <p style={{ marginLeft: '7px' }}>Polygon</p>
            </FilterPropertyItem>
          </FilterPropertyItemWrapper>
        </FilterPropertyItemList>
      </FilterItemList>
    </FilterItem>
  )
}

export default ChainFilter
