import Box from '@mui/material/Box'

export default function PageContainer({ children }) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ flexGrow: 1, m: '0 auto', pb: '5px' }} maxWidth="1400px">
        {children}
      </Box>
    </Box>
  )
}
