export function truncateHarvestDecimalDigit(str) {
  if (str.includes('.')) {
    const parts = str.split('.')

    if (parts[1] === '0') {
      return `+ ${parts[0]}`
    }

    if (parts[1].length < 7) {
      return `+ ${str}`
    }

    return '~' + Number(Number(str).toFixed(6))
  }

  return `+ ${str}`
}

export function truncateDecimalDigit(str) {
  if (str.includes('.')) {
    const parts = str.split('.')

    if (parts[1] === '0') {
      return parts[0]
    }

    if (parts[1].length < 7) {
      return str
    }

    return Number(Number(str).toFixed(6))
  }

  return str
}
