import React, { useContext, useState } from 'react'
import { registerAddress } from '../../api'
import networks from '../../networks.json'
import {
  MAX_AUTH_TOKEN_LIFETIME,
  EIP712_NAME,
  EIP712_TYPE,
  EIP712_VERSION,
  EIP712_CHAIN_ID,
} from '../../constants'
import { UserContext } from '../../contexts/UserContext'
import { shortenAddress } from '../../utils/shortenAddress'
import { Button } from '../../components/Button'
import ModalComponent from '../Modal'

import {
  ModalAddress,
  ModalButtonWrapper,
  ModalTextError,
} from './LinkModal.styled'

import { ModalTitle, ModalText } from '../Modal/Modal.styled'

export default function LinkModal({
  isOpen,
  onClose,
  handleOpenSuccess,
  registerType,
}) {
  const {
    userAddress,
    accessToken,
    resetAccessToken,
    chainId,
    getUserInfo,
  } = useContext(UserContext)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isLinkedError, setIsLinkedError] = useState(false)

  async function sendAddress(e) {
    setIsProcessing(true)
    e.preventDefault()
    const timestamp = Math.floor(Date.now() / 1000 + MAX_AUTH_TOKEN_LIFETIME)
    const typedData = {
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        AuthToken: [{ name: 'timestamp', type: 'uint256' }],
      },
      primaryType: EIP712_TYPE,
      domain: {
        name: EIP712_NAME,
        version: EIP712_VERSION,
        chainId: chainId,
        verifyingContract:
          networks[EIP712_CHAIN_ID.toString()].metarunExchangeContract.address,
      },
      message: {
        timestamp: timestamp,
      },
    }

    const metamaskSignature = await window.ethereum
      .request({
        method: 'eth_signTypedData_v4',
        params: [userAddress, JSON.stringify(typedData)],
      })
      .catch(() => {
        setIsProcessing(false)
      })

    registerAddress(
      metamaskSignature,
      timestamp,
      accessToken,
      chainId,
      userAddress,
    )
      .then(() => {
        onClose()
        handleOpenSuccess()
        setIsProcessing(false)
        getUserInfo()
      })
      .catch((err) => {
        console.log(err)
        setIsProcessing(false)
        setIsLinkedError(true)
      })
  }

  async function closeModal(e) {
    e.preventDefault()
    resetAccessToken()
    setIsLinkedError(false)
    setIsProcessing(false)
    onClose()
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={closeModal}>
      <ModalTitle margin>Link Wallet Address</ModalTitle>

      <ModalText mb="24px">
        {registerType === 'create'
          ? 'You account has been created. '
          : 'You account has been verified. '}
        Link you adress wallet to you account now to be get started
      </ModalText>
      <ModalText mb="24px">
        Your wallet:{' '}
        <ModalAddress>
          {userAddress && shortenAddress(userAddress)}
        </ModalAddress>
      </ModalText>
      {isLinkedError ? (
        <>
          <ModalTextError>
            This account has linked to an address already
          </ModalTextError>
          <ModalButtonWrapper>
            <Button
              size="linkModal"
              type="submit"
              variant="linkModal"
              onClick={closeModal}
            >
              Close
            </Button>
          </ModalButtonWrapper>
        </>
      ) : (
        <ModalButtonWrapper>
          <Button
            size="linkModal"
            type="submit"
            variant="linkModal"
            onClick={sendAddress}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Link'}
          </Button>
        </ModalButtonWrapper>
      )}
    </ModalComponent>
  )
}
