import styled from 'styled-components'

export const ModalTextError = styled.p`
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  color: red;
  background: rgba(237, 73, 21, 0.1);
  border-radius: 12px;
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`

export const ModalAddress = styled.span`
  font-weight: 700;
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`
