import { useState, useEffect, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getOwnerCards } from '../../api'
import {
  CharactersContainer,
  CharactersMainText,
  RewardInfoContainer,
  TicketContainer,
  TicketCardContainer,
  TicketInfoContainer,
  RewardItem,
  RewardIconContainer,
  RewardIcon,
  RewardText,
  InfoMainText,
  RunsInfoContainer,
  RunsItem,
  RunsText,
  SpinnerContainer,
} from './Characters.styled'
import CharactersCard from './CharactersCard'
import Spinner from '../Spinner'
import { UserContext } from '../../contexts/UserContext'

export default function Characters({ summary, summaryIsLoading }) {
  const { userAddress } = useContext(UserContext)
  const [charactersData, setCharactersData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasMoreCharacters, setHasMoreCharacters] = useState(true)
  const [totalAmountOfCharacters, setTotalAmountOfCharacters] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  function fetchCharacters() {
    setIsLoading(true)
    getOwnerCards({
      userAddress,
      characterFilter: true,
    })
      .then((character) => {
        setCharactersData(character.results)
        setTotalAmountOfCharacters(character.count)
        setHasMoreCharacters(character.count > 20)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const fetchMoreCharacters = () => {
    if (charactersData.length >= totalAmountOfCharacters) {
      setHasMoreCharacters(false)
      return
    }

    getOwnerCards({
      page: currentPage + 1,
      characterFilter: true,
      userAddress,
    }).then((runs) => {
      setCurrentPage((prev) => prev + 1)
      setCharactersData((prevValue) => {
        return prevValue.concat(runs.results)
      })
    })
  }

  useEffect(() => {
    if (userAddress) {
      setCurrentPage(1)
      fetchCharacters()
    }
  }, [userAddress])

  return (
    <CharactersContainer>
      <CharactersMainText>Characters</CharactersMainText>
      {!isLoading ? (
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={charactersData.length}
          next={fetchMoreCharacters}
          hasMore={hasMoreCharacters}
          loader={<Spinner />}
        >
          {charactersData && charactersData.length > 0 ? (
            charactersData.map((character, index) => {
              return (
                <TicketContainer item key={index}>
                  <TicketCardContainer>
                    <CharactersCard cardData={charactersData[index]} />
                  </TicketCardContainer>
                  <TicketInfoContainer>
                    <RewardInfoContainer>
                      <RewardItem variant="win">
                        <RewardIconContainer variant="win">
                          <RewardIcon
                            src={
                              require('../../images/WinRewardIcon.svg').default
                            }
                            alt="items"
                            width="24"
                            height="28"
                            variant="win"
                          />
                        </RewardIconContainer>
                        <RewardText variant="win">
                          Win Reward Per Run
                        </RewardText>
                        <InfoMainText>
                          {character.kind.winReward} OPAL
                        </InfoMainText>
                      </RewardItem>
                      <RewardItem variant="lose">
                        <RewardIconContainer variant="lose">
                          <RewardIcon
                            src={
                              require('../../images/LoseRewardIcon.svg').default
                            }
                            alt="items"
                            width="24"
                            height="24"
                            variant="lose"
                          />
                        </RewardIconContainer>
                        <RewardText variant="lose">
                          Lose Reward Per Run
                        </RewardText>
                        <InfoMainText>
                          {character.kind.lossReward} OPAL
                        </InfoMainText>
                      </RewardItem>
                    </RewardInfoContainer>
                    <RunsInfoContainer>
                      <RunsItem>
                        <InfoMainText>
                          {character.kind.attributes.dailyRuns}
                        </InfoMainText>
                        <RunsText>Today Runs</RunsText>
                      </RunsItem>
                      <RunsItem>
                        <InfoMainText>{character.kind.todayWins}</InfoMainText>
                        <RunsText>Wins Today</RunsText>
                      </RunsItem>
                      <RunsItem>
                        <InfoMainText>
                          {character.kind.todayLosses}
                        </InfoMainText>
                        <RunsText>Losses Today</RunsText>
                      </RunsItem>
                    </RunsInfoContainer>
                    <RunsInfoContainer>
                      <RunsItem>
                        <InfoMainText>
                          {character.kind.attributes.totalRuns}
                        </InfoMainText>
                        <RunsText>Total Runs</RunsText>
                      </RunsItem>
                      <RunsItem>
                        <InfoMainText>{character.kind.totalWins}</InfoMainText>
                        <RunsText>Wins Total</RunsText>
                      </RunsItem>
                      <RunsItem>
                        <InfoMainText>
                          {character.kind.totalLosses}
                        </InfoMainText>
                        <RunsText>Losses Total</RunsText>
                      </RunsItem>
                    </RunsInfoContainer>
                  </TicketInfoContainer>
                </TicketContainer>
              )
            })
          ) : (
            <>You don&apos;t have any characters yet</>
          )}
        </InfiniteScroll>
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </CharactersContainer>
  )
}
