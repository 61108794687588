import styled, { css } from 'styled-components'

export const Modal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(29, 31, 42, 0.9);

  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;

  visibility: ${({ isOpen }) => {
    if (isOpen) {
      return 'visible'
    }
    return 'hidden'
  }};

  opacity: ${({ isOpen }) => {
    if (isOpen) {
      return '1'
    }
    return '0'
  }};
`

export const ModalContainer = styled.div`
  width: 100%;
  max-width: ${({ width }) => {
    if (width) {
      return width
    }
    return '477px'
  }};
  background-color: rgba(16, 16, 44, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(73, 158, 255, 0.3);
  padding: 40px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 336px;
    border-radius: 12px;
    padding: 24px;
  }
`

export const ModalButtonContainer = styled.div`
  position: absolute;
  background-color: rgba(16, 16, 44, 0.7);
  border: 1px solid rgba(73, 158, 255, 0.3);
  top: -1px;
  right: -70px;
  border-radius: 20px;
  width: 57px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    top: -65px;
    right: 0px;
    border-radius: 12px;
    width: 52px;
    height: 52px;
  }
`

export const ModalCloseButton = styled.img`
  width: 14px;
  height: 14px;
`

export const ModalTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: left;

  margin: ${({ success }) => (success ? '0 0 40px' : '0 0 16px')};

  text-transform: uppercase;

  ${(props) => {
    if (props.margin) {
      return css`
        @media (max-width: 768px) {
          margin-bottom: 24px;
        }
      `
    }
  }}
`

export const ModalTxText = styled.p`
  width: max-content;
  color: white;
  margin: 24px auto 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`

export const ModalTxLink = styled.a`
  width: max-content;
  color: white;
  margin: 24px auto 20px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`

export const ModalInput = styled.input`
  font-family: 'OpenSans';
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 24px;

  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.5px;
  padding: 42px 24px 24px;
  color: #a3a4a9;

  background-color: ${({ invalid }) => {
    if (invalid) {
      return '#ED49151A '
    }
    return 'inherit'
  }};
  background-clip: padding-box;
  border: 1px solid #2f313b;
  border-color: ${({ invalid }) => {
    if (invalid) {
      return '#322328'
    }
    return '#2f313b'
  }};

  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: #a3a4a9;
    opacity: 0.4;
    line-height: 36px;
  }

  &:focus {
    color: white;

    outline: 0;
    box-shadow: 0 0 0 1px rgba(158, 158, 158, 0.25);
  }

  &:disabled {
    background-color: #262a35;
    color: #a3a4a9;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    max-width: ${({ maxWidth }) => (maxWidth ? '310px' : 'unset')};
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 26px 16px 10px;
  }
`

const baseScrollbar = `
  position: relative;
  scrollbar-color: rgba(73, 158, 255, 1) rgba(255, 255, 255, 0.1);
  scrollbar-width: thin;
  &:active {
    scrollbar-color: rgba(73, 158, 255, 1) rgba(255, 255, 255, 0.1);
  }
  &:hover {
    scrollbar-color: rgba(73, 158, 255, 1) rgba(255, 255, 255, 0.1);
  }
  &:before {
    content: '';
    position: absolute;
    right: 12px;
    bottom: 0px;
    left: 0px;
    height: 20px;
    z-index: 1;
  }
`

export const ModalScrollbar = styled.div`
  ${(props) => {
    if (props.terms) {
      return css`
        ${baseScrollbar}
        &:before {
          background: linear-gradient(
            180deg,
            rgba(19, 19, 43, 0) 0%,
            #13142c 100%
          );
        }
      `
    }
    if (props.buy) {
      return css`
        @media (max-width: 768px) {
          max-height: calc(80vh - 36px - 35px - 75px);
          overflow-y: scroll;
          overflow-x: hidden;
          ${baseScrollbar}
        }
      `
    }
  }}
`

export const ModalText = styled.p`
  margin: 0;
  width: 100%;
  color: ${({ color }) => {
    if (color) {
      return color
    }
    return 'white'
  }};

  font-size: ${({ type }) => (type === 'terms' ? '14px' : '16px')};
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: ${({ type }) => (type === 'terms' ? '22.4px' : '26px')};

  text-transform: ${({ color }) => {
    if (color) {
      return 'uppercase'
    }
    return 'none'
  }};

  margin-bottom: ${({ mb }) => mb || '16px'};

  text-align: ${({ type }) => {
    switch (type) {
      case 'metamask':
        return 'center'
      case 'success':
        return 'center'
      default:
        return 'unset'
    }
  }};

  &:last-of-type {
    margin-bottom: ${({ type }) => {
      switch (type) {
        case 'metamask':
          return '8px'
        case 'terms':
          return '0'
        default:
          return '32px'
      }
    }};
    padding-bottom: ${({ type }) => (type === 'terms' ? '20px' : '0')};
  }

  @media (max-width: 768px) {
    font-size: ${({ type }) => (type === 'terms' ? '13px' : '15px')};
    ${({ type }) =>
      type === 'account'
        ? css`
            margin-bottom: 20px;
          `
        : ''}
  }
`

export const ModalTextDiv = styled.div`
  color: white;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
  margin-right: auto;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 400;

  ${({ type }) => {
    if (type === 'buy-character') {
      return css`
        @media (max-width: 768px) {
          width: 95%;
        }
      `
    }
    if (
      type === 'buy-mrun' ||
      type === 'buy-mtrun' ||
      type === 'buy-opal' ||
      type === 'order'
    ) {
      return css`
        display: flex;
        gap: 16px;
        justify-content: start;

        font-weight: 700;
      `
    }
  }}
`

export const Border = styled.div`
  height: 1px;
  background: rgba(73, 158, 255, 0.35);
  background: linear-gradient(
    278.44deg,
    rgba(73, 158, 255, 0.35) 42.55%,
    rgba(95, 81, 255, 0.35) 84.23%
  );
`
