import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MetaMaskAuth from '../MetamaskAuth'
import Register from '../Register'
import LinkModal from '../LinkModal'
import SuccessModal from '../SuccessModal'
import AccountModal from '../AccountModal'
import TermsModal from '../TermsModal'
import InstallMetamaskModal from '../InstallMetamaskModal'
import { Button } from '../Button'
import { UserContext } from '../../contexts/UserContext'

import {
  AppHeader,
  StyledRouterLink,
  MoreIconButton,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  MoreButtonWrapper,
  TransparentHeader,
  ButtonWrapper,
} from './Header.styled'

function Header({ promoFormIsSend, handleOpenPromo, referral }) {
  const {
    userAddress,
    accessToken,
    resetUserInfo,
    email,
    resetAccessToken,
  } = useContext(UserContext)
  const [registerModalOpen, setRegisterModalOpen] = useState(false)
  const [registerType, setRegisterType] = useState('create')
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [accountModalOpen, setAccountModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [installMetamaskModalOpen, setInstallMetamaskModalOpen] = useState(
    false,
  )
  const [showMenu, setShowMenu] = useState(false)

  const menu = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()

  function handleCloseRegister() {
    setRegisterModalOpen(false)
  }
  function handleOpenRegister() {
    setRegisterModalOpen(true)
  }

  function handleCloseLink() {
    setLinkModalOpen(false)
  }
  function handleOpenLink() {
    setLinkModalOpen(true)
  }

  function handleCloseSuccess() {
    setSuccessModalOpen(false)
  }
  function handleOpenSuccess() {
    setSuccessModalOpen(true)
  }

  function handleCloseAccount() {
    setAccountModalOpen(false)
  }
  function handleOpenAccount() {
    setAccountModalOpen(true)
  }
  function handleCloseTerms() {
    setTermsModalOpen(false)
  }

  function handleMenu() {
    setShowMenu(!showMenu)
  }

  function closeMenu() {
    setShowMenu(false)
  }

  function handleCloseInstallMetamask() {
    setInstallMetamaskModalOpen(false)
  }
  function handleOpenInstallMetamask() {
    setInstallMetamaskModalOpen(true)
  }

  function logoutHandler() {
    window.localStorage.removeItem('CloseHomePrompt')
    resetAccessToken()
    resetUserInfo()
    closeMenu()
    if (location.pathname.includes('profile')) {
      navigate('/')
    }
  }

  const handleCloseMenu = useCallback((event) => {
    if (
      menu.current &&
      !Array.from(menu.current.getElementsByTagName('*')).includes(event.target)
    ) {
      closeMenu()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('click', handleCloseMenu)
    return () => {
      window.removeEventListener('click', handleCloseMenu)
    }
  }, [handleCloseMenu])

  return (
    <>
      <AppHeader>
        <TransparentHeader isSticky={true}>
          <StyledRouterLink href="https://metarun.game/" target="_blank">
            <picture>
              <source
                media="(max-width: 888px)"
                srcSet={require('../../images/SmallLogo.svg').default}
              ></source>
              <img
                src={require('../../images/Logo.svg').default}
                alt="logo"
              ></img>
            </picture>
          </StyledRouterLink>
          <MetaMaskAuth
            handleOpenAccount={handleOpenAccount}
            handleOpenInstallMetamask={handleOpenInstallMetamask}
          />
          {location.pathname === '/' &&
            !promoFormIsSend &&
            (!accessToken || !email) &&
            referral && (
              <ButtonWrapper>
                <Button size="rounded" onClick={handleOpenPromo}>
                  Sign-up
                </Button>
              </ButtonWrapper>
            )}

          <MoreButtonWrapper ref={menu}>
            <MoreIconButton
              onClick={handleMenu}
              disabled={!accessToken || !email || !userAddress}
            >
              <MoreHorizIcon />
            </MoreIconButton>
            <MenuList show={showMenu}>
              <MenuListItem to="/profile/settings" onClick={closeMenu}>
                Account settings
              </MenuListItem>
              {/* <MenuListItem to="/profile/runs/duel" onClick={closeMenu}>
                Runs
              </MenuListItem> */}
              {/* <MenuListItem to="/profile/characters" onClick={closeMenu}>
                Characters
              </MenuListItem>
              <MenuListItem to="/profile/rewards" onClick={closeMenu}>
                Rewards
              </MenuListItem> */}
              <MenuListItemButton onClick={logoutHandler}>
                Logout
              </MenuListItemButton>
            </MenuList>
          </MoreButtonWrapper>
        </TransparentHeader>
      </AppHeader>

      <Register
        isOpen={registerModalOpen}
        onClose={handleCloseRegister}
        openLink={handleOpenLink}
        registerType={registerType}
        setRegisterType={setRegisterType}
      />
      <LinkModal
        isOpen={linkModalOpen}
        onClose={handleCloseLink}
        handleOpenSuccess={handleOpenSuccess}
        registerType={registerType}
      />
      <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccess} />
      <AccountModal
        isOpen={accountModalOpen}
        onClose={handleCloseAccount}
        handleOpenRegister={handleOpenRegister}
        setRegisterType={setRegisterType}
      />
      <TermsModal isOpen={termsModalOpen} onClose={handleCloseTerms} />
      <InstallMetamaskModal
        isOpen={installMetamaskModalOpen}
        onClose={handleCloseInstallMetamask}
      />
    </>
  )
}

export default Header
